import React, { useEffect } from 'react'
import { useAccount } from '~/account'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'
import {
  ChartCard,
  ChartHeader,
  ChartStateLoading as Loading,
  ChartStateNoData as NoData,
} from '~/shared-components'
import AddReport from '~/add-report'
import { AudienceIcon, EngagementsIcon, ImpressionsIcon } from './icons'
import Title from '../Title'

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto 22px auto;
  list-style: none;
`

const GridItemContainer = styled.li`
  width: 33.33%;
`

const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  align-items: center;
`

const GridContainer = styled.div`
  position: relative;
  padding: 0.75rem 1.5rem 1rem;
`

const GridContent = styled.div`
  display: flex;
  flex-direction: column;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
const Metric = ({ name, total }) => (
  <GridItemContainer>
    {name === 'audience' && (
      <GridItem>
        <AudienceIcon />
        <GridContent>
          <Text weight="bold" size="large" color="black">
            {numeral(total).format('0,0')}
          </Text>
          <Text color="shuttleGray" size="mini">
            Audience
          </Text>
        </GridContent>
      </GridItem>
    )}
    {name === 'engagements' && (
      <GridItem>
        <EngagementsIcon />
        <GridContent>
          <Text weight="bold" size="large" color="black">
            {numeral(total).format('0,0')}
          </Text>
          <Text color="shuttleGray" size="mini">
            Engagements
          </Text>
        </GridContent>
      </GridItem>
    )}
    {name === 'impressions' && (
      <GridItem>
        <ImpressionsIcon />
        <GridContent>
          <Text weight="bold" size="large" color="black">
            {numeral(total).format('0,0')}
          </Text>
          <Text color="shuttleGray" size="mini">
            Impressions
          </Text>
        </GridContent>
      </GridItem>
    )}
  </GridItemContainer>
)

Metric.propTypes = {
  name: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'followers' implicitly has an 'any... Remove this comment to see the full error message
export const Table = ({ followers, engagement, impressions }) => (
  <Grid>
    <Metric name="audience" total={followers.total} />
    <Metric name="engagements" total={engagement.total} />
    <Metric name="impressions" total={impressions.total} />
  </Grid>
)

Table.propTypes = {
  followers: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
  engagement: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
  impressions: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const AggregateHeader = ({ metrics, loading, profiles, fetch }) => {
  const account = useAccount()

  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
  useEffect(fetch, [account?.currentOrganization.id])

  let content = null
  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else if (Object.keys(metrics).length === 0) {
    content = <NoData />
  } else {
    content = <Table {...metrics} />
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title />
        <AddReport chart="aggregate-header" state={{ profiles }} />
      </ChartHeader>
      <GridContainer id="js-dom-to-png-summary">{content}</GridContainer>
    </ChartCard>
  )
}

AggregateHeader.propTypes = {
  loading: PropTypes.bool,
  fetch: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  metrics: PropTypes.shape({
    followers: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.number,
    }),
    engagement: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.number,
    }),
    impressions: PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.number,
    }),
  }),
}

AggregateHeader.defaultProps = {
  loading: false,
  metrics: {},
}

export default AggregateHeader
