import { actionTypes as fetchActions } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

export const actionTypes = keyWrapper('PROFILE_SELECTOR', {
  SELECT_PROFILE: 'SELECT_PROFILE',
  FILTER_PROFILES: 'FILTER_PROFILES',
  OPEN_DROPDOWN: 'OPEN_DROPDOWN',
  CLOSE_DROPDOWN: 'CLOSE_DROPDOWN',
  REFRESH_PROFILES: 'REFRESH_PROFILES',
  DISREGARD_FRESH_DATA: 'DISREGARD_FRESH_DATA',
})

const initialState = {
  profiles: [],
  socialProfiles: [],
  nonSocialProfiles: [],
  lastUpdated: {},
  profilesWithFreshData: [],
  organizationId: null,
  selectedProfile: null,
  loading: true,
  hasError: false,
  hasInvalidProfiles: false,
  isOwner: false,
  accountChannels: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
function hasInvalidProfiles(profiles) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  return profiles.filter((profile) => profile.invalid).length > 0
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
export function getProfilesWithFreshData(profiles, lastUpdated) {
  // @ts-expect-error TS(7034) FIXME: Variable 'updatedProfiles' implicitly has type 'an... Remove this comment to see the full error message
  const updatedProfiles = []
  const navigationStart = dayjs(performance.timeOrigin)

  // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  profiles.forEach((profile) => {
    const updated = dayjs(profile.lastUpdated)
    if (navigationStart.isBefore(updated)) {
      updatedProfiles.push(profile.id)
    } else if (lastUpdated) {
      // This is needed so that we can refresh the data multiple times during a session
      const profileLastUpdated = dayjs(lastUpdated[profile.serviceId])
      if (profileLastUpdated.isBefore(updated)) {
        updatedProfiles.push(profile.id)
      }
    }
  })

  // @ts-expect-error TS(7005) FIXME: Variable 'updatedProfiles' implicitly has an 'any[... Remove this comment to see the full error message
  return updatedProfiles
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
function filterProfilesByIds(profiles, ids) {
  // @ts-expect-error TS(7034) FIXME: Variable 'filteredProfiles' implicitly has type 'a... Remove this comment to see the full error message
  const filteredProfiles = []
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  ids.forEach((id) => {
    if (id !== null) {
      // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
      filteredProfiles.push(profiles.find((profile) => profile.id === id))
    }
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'filteredProfiles' implicitly has an 'any... Remove this comment to see the full error message
  return filteredProfiles
}

function ensureTimezone(profiles = []) {
  return profiles.map((profile) => {
    // @ts-expect-error TS(2339) FIXME: Property 'timezone' does not exist on type 'never'... Remove this comment to see the full error message
    if (profile.timezone) {
      return profile
    }

    return {
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...profile,
      timezone: dayjs.tz.guess(),
    }
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
export function getSocialProfilesFromResult(result) {
  return result?.social || result
}

// @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
export const getAllProfilesFromResult = (result) => {
  const profiles =
    getSocialProfilesFromResult(result)?.concat(result.nonSocial) || []
  // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
  return profiles.filter((p) => p !== undefined)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  const lastUpdated = {}
  switch (action.type) {
    case `profiles_${fetchActions.FETCH_SUCCESS}`:
      return Object.assign({}, state, {
        profiles: ensureTimezone(getAllProfilesFromResult(action.result)),
        socialProfiles: ensureTimezone(
          getSocialProfilesFromResult(action.result.social),
        ),
        nonSocialProfiles: ensureTimezone(action.result.nonSocial),
        organizationId: getAllProfilesFromResult(action.result)[0]
          ? getAllProfilesFromResult(action.result)[0].organizationId
          : null,
        loading: false,
        hasInvalidProfiles: hasInvalidProfiles(
          getAllProfilesFromResult(action.result),
        ),
        accountChannels: action.result.accountChannels,
      })
    case `lastUpdated_${fetchActions.FETCH_SUCCESS}`:
      // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
      action.result.forEach((profile) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        lastUpdated[profile.serviceId] = profile.lastUpdated
      })
      return Object.assign({}, state, {
        lastUpdated,
        profilesWithFreshData: filterProfilesByIds(
          state.profiles,
          getProfilesWithFreshData(action.result, lastUpdated),
        ),
      })
    case actionTypes.DISREGARD_FRESH_DATA:
      return Object.assign({}, state, {
        profilesWithFreshData: [],
      })
    case actionTypes.SELECT_PROFILE:
      return Object.assign({}, state, {
        selectedProfile: {
          ...action.profile,
          timezone: action.profile.timezone
            ? action.profile.timezone
            : dayjs.tz.guess(),
        },
        selectedProfileId: action.profile.id,
        selectedProfileService: action.profile.service,
      })
    case `profiles_${fetchActions.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  selectProfile(profile, onLocationChange = false) {
    return {
      type: actionTypes.SELECT_PROFILE,
      profile,
      onLocationChange,
    }
  },
  refreshProfiles() {
    return {
      type: actionTypes.REFRESH_PROFILES,
    }
  },
  disregardFreshData() {
    return {
      type: actionTypes.DISREGARD_FRESH_DATA,
    }
  },
}
