import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'
import { useChannels } from '~/profile-loader'
import {
  useTrackRenderingFail,
  FAILURE_CAUSES,
} from '../hooks/useTrackRenderingFail'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  margin: 0 auto;
  text-align: center;
`

const Image = styled.div`
  width: 8rem;
  height: 8rem;
  background: url(https://buffer-analyze.s3.amazonaws.com/images/empty.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto 0.5rem;
`

const Header = styled.div`
  padding: 0;
  margin: 0;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const NoData = ({ children, chartName, channelType }) => {
  const { channels, selecteChannel } = useChannels()
  useTrackRenderingFail({
    chartName,
    failureCause: FAILURE_CAUSES.noData,
    channelType,
  })

  return (
    <Container>
      <Image />
      <Header>
        {children ||
          (channels.length > 0 ? (
            <Text>Aw, shucks! Seems to be no data for this date range</Text>
          ) : (
            <Text>
              Connect Facebook, Instagram, Twitter, LinkedIn Pages or (and!)
              Shopify to begin analyzing your marketing efforts.
            </Text>
          ))}
      </Header>
    </Container>
  )
}

NoData.defaultProps = {
  children: null,
  chartName: '',
  channelType: '',
}

NoData.propTypes = {
  children: PropTypes.node,
  chartName: PropTypes.string,
  channelType: PropTypes.string,
}

export default NoData
