import React from 'react'
import { ChartTitle } from '~/shared-components'
import { useSplitEnabled } from '@buffer-mono/features'

const Title = () => {
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')

  return (
    <ChartTitle>
      {isCampaignsToTagsEnabled
        ? 'Post insights by tag'
        : 'Campaign post insights'}
    </ChartTitle>
  )
}

export default Title
