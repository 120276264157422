import React from 'react'
import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { useSplitEnabled } from '@buffer-mono/features'
import Checkmark from '../Checkmark'

const List = styled.ul`
  margin: 0 0 7rem;
  padding: 0;
`

const Reason = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 0.75rem;
`

const Reasons = () => {
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')

  return (
    <List>
      <Reason>
        <Checkmark />
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
        <Text>
          Get an overview of your{' '}
          {isCampaignsToTagsEnabled ? 'tag' : 'campaign'} at a glance
        </Text>
      </Reason>
      <Reason>
        <Checkmark />
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
        <Text>Measure and report across multiple channels</Text>
      </Reason>
      <Reason>
        <Checkmark />
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
        <Text>Skip all the manual calculations and hassle</Text>
      </Reason>
    </List>
  )
}

export default Reasons
