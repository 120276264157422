import { env } from '../env'

export const HC_UTM_PARAMS =
  'utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more'

export const getAnalyzeUrl = (): string => {
  const environment = env.VITE_NODE_ENV
  if (environment === 'production') {
    return `https://analyze.buffer.com`
  } else if (environment === 'staging') {
    return `https://analyze.dev.buffer.com`
  } else {
    return `https://analyze.local.buffer.com:8081`
  }
}
