import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Metric from './Metric'

const MetricsByNetwork = styled.section`
  display: flex;
  justify-content: space-between;
  margin-right: 2.25rem;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'percentage' implicitly has an 'any' typ... Remove this comment to see the full error message
const getHeight = (percentage, max) => (percentage * 100) / max

// @ts-expect-error TS(7031) FIXME: Binding element 'totalByNetwork' implicitly has an... Remove this comment to see the full error message
const MetricBreakdown = ({ totalByNetwork }) => {
  const networks = Object.keys(totalByNetwork).sort()
  const percentages = Object.values(totalByNetwork).map(
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    (total) => total.percentage,
  )
  const max = Math.max(...percentages)
  return (
    <MetricsByNetwork>
      {networks.map((network) => (
        <Metric
          key={network}
          metric={totalByNetwork[network]}
          networkName={network}
          height={getHeight(totalByNetwork[network].percentage, max)}
        />
      ))}
    </MetricsByNetwork>
  )
}

MetricBreakdown.propTypes = {
  totalByNetwork: PropTypes.shape({
    percentage: PropTypes.number,
  }).isRequired,
}

export default MetricBreakdown
