import { reducer as i18nReducer } from '~/i18n'
import { reducer as appSidebarReducer } from '~/app-sidebar'
import { asyncDataFetchReducer } from '@buffer-mono/async-data-fetch'
import { reducer as navSidebarReducer } from '~/nav-sidebar'
import { reducer as summaryReducer } from '~/summary-table'
import { reducer as profileReducer } from '~/profile-selector'
import { reducer as datePickerReducer } from '~/date-picker'
import { reducer as profileLoaderReducer } from '~/profile-loader'
import { reducer as postsSummaryReducer } from '~/posts-summary-table'
import { reducer as averageReducer } from '~/average-table'
import { reducer as exportToPNGReducer } from '~/png-export'
import { reducer as exportToCSVReducer } from '~/csv-export'
import { reducer as postsReducer } from '~/posts-table'
import { reducer as compareChartReducer } from '~/compare-chart'
import { reducer as reportListReducer } from '~/report-list'
import { reducer as environmentReducer } from '~/environment'
import { reducer as accountReducer } from '~/account'
import { reducer as hashtagsReducer } from '~/hashtags-table'
import { reducer as answersSummaryReducer } from '~/answers-summary'
import { reducer as answersChartReducer } from '~/answers-chart'
import { reducer as demographicReducer } from '~/demographic-store'
import { reducer as demographicOverviewReducer } from '~/demographic-overview'
import { reducer as demographicGenderAgeReducer } from '~/demographic-gender-age'
import { reducer as demographicCitiesReducer } from '~/demographic-cities'
import { reducer as demographicCountriesReducer } from '~/demographic-countries'
import { reducer as storiesSummaryReducer } from '~/stories-summary-table'

export default {
  appSidebar: appSidebarReducer,
  asyncDataFetch: asyncDataFetchReducer,
  average: averageReducer,
  i18n: i18nReducer,
  navSidebar: navSidebarReducer,
  summary: summaryReducer,
  profiles: profileReducer,
  date: datePickerReducer,
  profileLoader: profileLoaderReducer,
  postsSummary: postsSummaryReducer,
  posts: postsReducer,
  exportToPNG: exportToPNGReducer,
  exportToCSV: exportToCSVReducer,
  compare: compareChartReducer,
  reportList: reportListReducer,
  environment: environmentReducer,
  account: accountReducer,
  hashtags: hashtagsReducer,
  answersSummary: answersSummaryReducer,
  answersChart: answersChartReducer,
  demographic: demographicReducer,
  demographicOverview: demographicOverviewReducer,
  demographicGenderAge: demographicGenderAgeReducer,
  demographicCities: demographicCitiesReducer,
  demographicCountries: demographicCountriesReducer,
  storiesSummary: storiesSummaryReducer,
}
