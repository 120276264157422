import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled, { css } from 'styled-components'

import { Button, Text } from '@buffer-mono/legacy-bufferapp-components'
import Calendar from './Calendar'
import Presets from './Presets'

import ApplyButton from '@bufferapp/ui/Button'

const Header = styled.div`
  margin: 0.75rem 0;
`

const Clear = styled.div`
  position: absolute;
  right: 8px;
  top: 6px;
`

const Inputs = styled.form`
  display: flex;
  align-content: center;
  justify-content: space-between;
`

const InputContainer = styled.div`
  width: 48%;
  position: relative;
`

const DateInput = styled.input`
  width: 100%;
  font-size: 0.75rem;
  padding: 0.5rem;
  margin: 0;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 3px;

  &:focus {
    outline: none;
  }
`

const CustomDateRangeContainer = styled.div`
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: all 500ms ease-in-out;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'calendarOpen' does not exist on type 'Th... Remove this comment to see the full error message
    props.calendarOpen &&
    css`
      max-height: 1000px;
      opacity: 1;
    `}
`

const Footer = styled.div`
  margin: 0.75rem 0 0.5rem;
  display: flex;
  justify-content: center;

  div {
    font-size: 12px;
    font-weight: 400;
  }

  button {
    margin: 0;
  }
`

class Form extends Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'nextProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentWillUpdate(nextProps) {
    // @ts-expect-error TS(2339) FIXME: Property '_startDate' does not exist on type 'Form... Remove this comment to see the full error message
    if (nextProps.startDate === null && this._startDate) {
      // @ts-expect-error TS(2339) FIXME: Property '_startDate' does not exist on type 'Form... Remove this comment to see the full error message
      this._startDate.focus()
    }
  }

  updateSelectedDateRange() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'Reado... Remove this comment to see the full error message
      startDate,
      // @ts-expect-error TS(2339) FIXME: Property 'endDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      endDate,
      // Actions
      // @ts-expect-error TS(2339) FIXME: Property 'setDateRange' does not exist on type 'Re... Remove this comment to see the full error message
      setDateRange,
      // @ts-expect-error TS(2339) FIXME: Property 'close' does not exist on type 'Readonly<... Remove this comment to see the full error message
      close,
    } = this.props

    if (startDate !== null && endDate !== null) {
      setDateRange(startDate, endDate)
      close()
    }
  }

  applyCustomDateRange() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'startDate' does not exist on type 'Reado... Remove this comment to see the full error message
      startDate,
      // @ts-expect-error TS(2339) FIXME: Property 'endDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      endDate,
      // Actions
      // @ts-expect-error TS(2339) FIXME: Property 'setDateRange' does not exist on type 'Re... Remove this comment to see the full error message
      setDateRange,
      // @ts-expect-error TS(2339) FIXME: Property 'close' does not exist on type 'Readonly<... Remove this comment to see the full error message
      close,
    } = this.props

    if (startDate !== null && endDate !== null) {
      setDateRange(startDate, endDate)
      close()
    }
  }

  render() {
    // State
    // @ts-expect-error TS(2339) FIXME: Property 'presets' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { presets, calendarOpen, startDate, endDate, month } = this.props

    // Actions
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'setStartDate' does not exist on type 'Re... Remove this comment to see the full error message
      setStartDate,
      // @ts-expect-error TS(2339) FIXME: Property 'setEndDate' does not exist on type 'Read... Remove this comment to see the full error message
      setEndDate,
      // @ts-expect-error TS(2339) FIXME: Property 'selectPreset' does not exist on type 'Re... Remove this comment to see the full error message
      selectPreset,
      // @ts-expect-error TS(2339) FIXME: Property 'setMonth' does not exist on type 'Readon... Remove this comment to see the full error message
      setMonth,
      // @ts-expect-error TS(2339) FIXME: Property 'clearStartDate' does not exist on type '... Remove this comment to see the full error message
      clearStartDate,
      // @ts-expect-error TS(2339) FIXME: Property 'clearEndDate' does not exist on type 'Re... Remove this comment to see the full error message
      clearEndDate,
      // @ts-expect-error TS(2339) FIXME: Property 'maxDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      maxDate,
      // @ts-expect-error TS(2339) FIXME: Property 'minDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      minDate,
    } = this.props

    const startDateFocus = startDate === null
    const endDateFocus = startDate !== null && endDate === null
    const dateFormat = 'MM/DD/YY'
    const startDateFormat = startDate
      ? `${moment.unix(startDate).format(dateFormat)}`
      : ''
    const endDateFormat = endDate
      ? `${moment.unix(endDate).format(dateFormat)}`
      : ''

    return (
      <div>
        <Presets
          presets={presets}
          selectPreset={selectPreset}
          // @ts-expect-error TS(2322) FIXME: Type '{ presets: any; selectPreset: any; minStartD... Remove this comment to see the full error message
          minStartDate={minDate}
          startDate={startDate}
          endDate={endDate}
        />
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <CustomDateRangeContainer calendarOpen={calendarOpen}>
          {calendarOpen && (
            <div>
              <Header>
                <Text size="small">Select a custom date range:</Text>
              </Header>
              <Inputs>
                <InputContainer>
                  <DateInput
                    ref={(node) => {
                      // @ts-expect-error TS(2339) FIXME: Property '_startDate' does not exist on type 'Form... Remove this comment to see the full error message
                      this._startDate = node
                    }}
                    type="text"
                    name="start"
                    value={startDateFormat}
                    placeholder="Date from"
                    readOnly
                  />
                  <Clear>
                    <Button
                      // @ts-expect-error
                      noStyle
                      // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
                      onClick={(e) => {
                        e.preventDefault()
                        clearStartDate()
                      }}
                    >
                      <Text>x</Text>
                    </Button>
                  </Clear>
                </InputContainer>
                <InputContainer>
                  <DateInput
                    type="text"
                    name="end"
                    value={endDateFormat}
                    placeholder="Date to"
                    readOnly
                  />
                  <Clear>
                    <Button
                      // @ts-expect-error
                      noStyle
                      // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
                      onClick={(e) => {
                        e.preventDefault()
                        clearEndDate()
                      }}
                    >
                      <Text>x</Text>
                    </Button>
                  </Clear>
                </InputContainer>
              </Inputs>
              <Calendar
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                isOpen
                startDate={startDate}
                endDate={endDate}
                focusStartDate={startDateFocus}
                focusEndDate={endDateFocus}
                currentMonth={month}
                maxStartDate={minDate}
                maxEndDate={maxDate}
                selectStartDate={setStartDate}
                selectEndDate={setEndDate}
                selectMonth={setMonth}
              />
              <Footer>
                {/* @ts-expect-error TS(2740) FIXME: Type '{ disabled: boolean; onClick... Remove this comment to see the full error message */}
                <ApplyButton
                  disabled={startDate === null || endDate === null}
                  onClick={() => this.applyCustomDateRange()}
                  type="primary"
                  size="medium"
                  label="Apply This Date Range"
                  fullWidth
                />
              </Footer>
            </div>
          )}
        </CustomDateRangeContainer>
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Form.defaultProps = {
  startDate: null,
  endDate: null,
  minDate: null,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Form.propTypes = {
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  month: PropTypes.number.isRequired,
  calendarOpen: PropTypes.bool.isRequired,
  minDate: PropTypes.number,
  maxDate: PropTypes.number.isRequired,

  // Actions
  selectPreset: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  clearStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  clearEndDate: PropTypes.func.isRequired,
  setDateRange: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  setMonth: PropTypes.func.isRequired,
}

export default Form
