import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  ChartCard,
  ChartHeader,
  ChartStateLoading as Loading,
  ChartStateNoData,
  ChartTitle,
  CHANNEL_TYPES,
} from '~/shared-components'
import AddReport from '~/add-report'

import Post, { Placeholder } from '../Post'

const Breakdown = styled.section`
  padding: 0 16px 24px;
  display: flex;
  justify-content: space-between;

  div:first-child {
    margin-left: 0;
  }

  div:last-child {
    margin-right: 0;
  }
`

const Content = styled.main`
  position: relative;
`

export const Title = () => <ChartTitle>Recent posts</ChartTitle>

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
export const RecentPostsTable = ({ metrics, forReport }) => {
  const POSTS_PER_PAGE = 5

  let numberOfFillerStories = POSTS_PER_PAGE - metrics.length
  const fillerStories = []
  while (numberOfFillerStories > 0) {
    fillerStories.push(<Placeholder key={numberOfFillerStories} />)
    --numberOfFillerStories
  }
  return (
    <div>
      <Content>
        <Breakdown
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          forReport={forReport}
          // @ts-expect-error TS(7006) FIXME: Parameter 'node' implicitly has an 'any' type.
          innerRef={(node) => {
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            this._breakdown = node
          }}
        >
          {/* @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type. */}
          {metrics.slice(0, 5).map((post, index) => (
            <Post post={post} key={index} />
          ))}
          {fillerStories}
        </Breakdown>
      </Content>
    </div>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const RecentPosts = ({ metrics, profiles, loading, fetch }) => {
  useEffect(fetch, [profiles])

  let content = null
  if (loading) {
    content = (
      <Content>
        {/* @ts-expect-error TS(2741) FIXME: Property 'text' is missing in type '{ noBorder: tr... Remove this comment to see the full error message */}
        <Loading noBorder />
      </Content>
    )
  } else if (metrics.length === 0) {
    content = (
      <Content>
        <ChartStateNoData
          chartName="recent-posts"
          channelType={CHANNEL_TYPES.aggregates}
        />
      </Content>
    )
  } else {
    content = (
      <div>
        <RecentPostsTable metrics={metrics} />
      </div>
    )
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title />
        {metrics.length > 0 && (
          <AddReport
            chart="recent-posts"
            state={{
              profiles,
            }}
          />
        )}
      </ChartHeader>
      {content}
    </ChartCard>
  )
}

RecentPosts.defaultProps = {
  metrics: [],
  profiles: [],
  loading: false,
  fetch: () => ({}),
}

RecentPosts.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      // @ts-expect-error TS(2322) FIXME: Type '{ thumbnail: PropTypes.Requireable<string>; ... Remove this comment to see the full error message
      media: { thumbnail: PropTypes.string },
    }),
  ),
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      service: PropTypes.string,
      username: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  fetch: PropTypes.func.isRequired,
}

RecentPostsTable.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      // @ts-expect-error TS(2322) FIXME: Type '{ thumbnail: PropTypes.Requireable<string>; ... Remove this comment to see the full error message
      media: { thumbnail: PropTypes.string },
    }),
  ),
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      servie: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  forReport: PropTypes.bool,
}

RecentPostsTable.defaultProps = {
  metrics: [],
  profiles: [],
  loading: false,
  forReport: false,
}

export default RecentPosts
