import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes as dateActionTypes } from '~/date-picker'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const fetch = (dispatch, profiles, { startDate, endDate }) =>
  dispatch(
    actions.fetch({
      name: 'aggregates',
      args: {
        // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
        profiles: profiles.map((profile) => profile.id),
        startDate,
        endDate,
      },
    }),
  )

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const profiles = getState().profiles.socialProfiles
  switch (action.type) {
    case dateActionTypes.SET_DATE_RANGE:
      if (profiles) {
        fetch(dispatch, profiles, action)
      }
      break
    case profileActionTypes.REFRESH_PROFILES:
    case actionTypes.FETCH:
      if (profiles) {
        fetch(dispatch, profiles, getState().date)
      }
      break
    default:
      break
  }
  return next(action)
}
