import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'
import Button from '~/shared-components/Button'
import Modal from '~/shared-components/Modal'

import { ReportList } from '~/report-list'

const Content = styled.section`
  padding: 0 0.75rem;
`

const Section = styled.section`
  margin-top: 1rem;
  max-height: 18rem;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #d5e3ef;
  min-height: 12rem;
  padding: 1rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05) inset;
  z-index: 9;
`

const InputWrapper = styled.section`
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
`

const Input = styled.input.attrs({
  placeholder: 'Enter report title here',
  type: 'text',
})`
  border: 1px solid #b8b8b8;
  border-radius: 3px;
  box-sizing: border-box;
  width: 466px;
  outline: none;
  font-size: 0.8rem !important;
  font-weight: 400;
  padding: 0.75rem 1rem;
  font-family: 'Roboto', sans-serif;
`

const AddReportModal = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'open' implicitly has an 'any' typ... Remove this comment to see the full error message
  open,
  // @ts-expect-error TS(7031) FIXME: Binding element 'addReport' implicitly has an 'any... Remove this comment to see the full error message
  addReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'toggle' implicitly has an 'any' t... Remove this comment to see the full error message
  toggle,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectReport' implicitly has an '... Remove this comment to see the full error message
  selectReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'translations' implicitly has an '... Remove this comment to see the full error message
  translations,
  // @ts-expect-error TS(7031) FIXME: Binding element 'reports' implicitly has an 'any' ... Remove this comment to see the full error message
  reports,
  // @ts-expect-error TS(7031) FIXME: Binding element 'fetchReportList' implicitly has a... Remove this comment to see the full error message
  fetchReportList,
}) => {
  // @ts-expect-error TS(7034) FIXME: Variable 'textInput' implicitly has type 'any' in ... Remove this comment to see the full error message
  let textInput
  const onClick = () => {
    // @ts-expect-error TS(7005) FIXME: Variable 'textInput' implicitly has an 'any' type.
    addReport(textInput.value === '' ? 'Untitled' : textInput.value)
  }

  return (
    <Modal open={open} toggle={toggle}>
      <Content>
        <Text color="sidebarBackgroundBlue" weight="medium">
          {translations.addReportTitle}
        </Text>
        <InputWrapper>
          <Input
            placeholder={translations.addReportPlaceholder}
            ref={(input) => {
              textInput = input
            }}
            autoFocus
          />
          <Button onClick={onClick}>
            <Text color="sidebarBackgroundBlue" size="small" weight="medium">
              {translations.createReport}
            </Text>
          </Button>
        </InputWrapper>
        <div>
          <Text color="sidebarBackgroundBlue" weight="medium">
            Or, add to an existing report
          </Text>
        </div>
        <Section>
          <ReportList
            reports={reports}
            selectReport={selectReport}
            small
            fetch={fetchReportList}
          />
        </Section>
      </Content>
    </Modal>
  )
}

AddReportModal.defaultProps = {
  open: false,
  addReport: () => {},
  selectReport: () => {},
  toggle: () => {},
  reports: [],
}

AddReportModal.propTypes = {
  open: PropTypes.bool,
  addReport: PropTypes.func,
  selectReport: PropTypes.func,
  translations: PropTypes.shape({
    addReportTitle: PropTypes.string,
    createReport: PropTypes.string,
    addReportPlaceholder: PropTypes.string,
  }).isRequired,
  toggle: PropTypes.func,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      updated_at: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  fetchReportList: PropTypes.func,
}

export default AddReportModal
