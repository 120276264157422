import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const ACTION_KEY_WRAPPER = 'recent-posts'

export const actionTypes = keyWrapper(ACTION_KEY_WRAPPER, {
  FETCH: 'FETCH',
})

const initialState = {
  loading: true,
  posts: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `${ACTION_KEY_WRAPPER}_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        loading: true,
      }
    case `${ACTION_KEY_WRAPPER}_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        posts: action.result,
      }
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
}
