import React from 'react'
import Text from '@bufferapp/ui/Text'
import Carousel from '@bufferapp/ui/Carousel'
import Link from '@bufferapp/ui/Link'
import styled from 'styled-components'
import { library } from '../library'
import { ChartCard, ChartTitle } from '~/shared-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
`

const Left = styled.div`
  position: relative;
  padding-right: 16px;

  &::after {
    content: '';
    height: 100%;
    border-left: 1px solid rgb(212, 219, 225);
    right: 0;
    top: 0;
    position: absolute;
  }

  p {
    width: 85%;
  }
`

const Right = styled.div`
  margin-left: 8px;

  //this makes the carousel arrow button align vertically and in style with the add to report buttons
  div:nth-child(3) {
    right: 16px;
    z-index: 0;

    div {
      width: 32px;

      button {
        padding: 0 8px;
        height: 32px;
      }
    }
  }
`

const LinkButton = styled.a`
  height: 32px;
  width: 81px;
  border-radius: 4px;
  background-color: #2c4bff;
  color: white;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 12px;

  ::visited {
    color: white;
  }
`

const CardContainer = styled.div`
  display: flex;
  width: 400px;
`

const StyledCarouselContainer = styled.div`
  position: relative;
  max-width: 450px;
  overflow: hidden;
`
const ImageContainer = styled.div`
  border-radius: 4px;
  min-width: 180px;
  overflow: hidden;
  height: 120px;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
  padding-right: 32px;

  label {
    white-space: normal;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'hasEnoughFollowers' implicitly ha... Remove this comment to see the full error message
const Resources = ({ hasEnoughFollowers, referral, channel }) => {
  if (referral === 'overview' && hasEnoughFollowers) {
    return null
  }

  const resourcesLibrary =
    referral === 'answers' || referral === 'overview'
      ? // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        library[referral].resources[channel]
      : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        library[referral].resources

  return (
    <ChartCard>
      <Container>
        <Left>
          {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <ChartTitle>{library[referral].headline}</ChartTitle>
          <Text type="p">
            {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {library[referral].textLeft[channel]}{' '}
            <Link
              newTab
              href={`https://buffer.com/library/?utm_source=analytics_${referral}`}
            >
              Buffer Library
            </Link>
            .
          </Text>
        </Left>
        <Right>
          <StyledCarouselContainer>
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <Carousel width="400px" rightNavigation withIndicators={false}>
              {/* @ts-expect-error TS(7006) FIXME: Parameter 'article' implicitly has an 'any' type. */}
              {resourcesLibrary.map((article) => (
                <CardContainer key={article.title}>
                  <ImageContainer>
                    <img
                      width="180px"
                      src={article.image}
                      alt={article.title}
                    />
                  </ImageContainer>
                  <Description>
                    <Text type="label">{article.title}</Text>
                    <LinkButton
                      href={`${article.url}?utm_source=analytics_${referral}`}
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                    </LinkButton>
                  </Description>
                </CardContainer>
              ))}
            </Carousel>
          </StyledCarouselContainer>
        </Right>
      </Container>
    </ChartCard>
  )
}

export default Resources
