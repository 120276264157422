import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  margin: 0 auto;
  text-align: center;
`

const Image = styled.div`
  width: 12rem;
  height: 12rem;
  background: url(https://buffer-media-uploads.s3.amazonaws.com/515592ae4dbf087230000006/5d10c370c1b01d37956e0b25/3e5705cbbb55915251aaf8b6a59643fd.original.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto 0.5rem;
`

const Header = styled.div`
  padding: 0;
  margin: 0 0 0.25rem;
`

const Description = styled.div`
  padding: 0 3rem;
  margin: 0;
  line-height: 1rem;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'header' implicitly has an 'any' t... Remove this comment to see the full error message
const EmptyState = ({ header, description, children }) => (
  <Container>
    <Image />
    <Header>
      <Text weight="medium" color="shuttleGray">
        {header}
      </Text>
    </Header>
    {description ? (
      <Description>
        <Text size="small">{description}</Text>
      </Description>
    ) : null}
    {children}
  </Container>
)

EmptyState.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
}

EmptyState.defaultProps = {
  header: 'There is currently nothing available or selected',
  description: null,
  children: null,
}

export default EmptyState
