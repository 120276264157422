import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  Container,
} from '~/shared-components'
import { geyser } from '@buffer-mono/legacy-bufferapp-components'
import AddReport from '~/add-report'
import Title from '../Title'
import { PeopleIcon, LocationIcon } from './icons'
import Select from '@bufferapp/ui/Select'
import { renderButton } from '~/shared-components/utils'

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function expandGenderAgeLabel(label) {
  return label
    .replace(/^M\./, 'Male, ')
    .replace(/^F\./, 'Female, ')
    .replace(/^U./, 'Undisclosed, ')
}

const HeadersActions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  div[role='button'] {
    width: 150px;
  }
`

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
`

const GridItemContainer = styled.li`
  width: 50%;
`

const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  align-items: center;
`

const GridContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 16px;
    margin-top: 1px;
  }
`

const HeaderSpacer = styled.div`
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  width: 1px;
  background: ${geyser};
  height: 30px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
const Metric = ({ metric }) => (
  <GridItemContainer>
    {metric.key === 'gender_age' && (
      <GridItem>
        <PeopleIcon />
        <GridContent>
          <Text type="label" color="grayDark">
            Top gender and age
          </Text>
          <Text as="span" type="h3">
            {expandGenderAgeLabel(metric.values[0]?.label)}
          </Text>
        </GridContent>
      </GridItem>
    )}
    {metric.key === 'city' && (
      <GridItem>
        <LocationIcon />
        <GridContent>
          <Text type="label" color="grayDark">
            Top place
          </Text>
          <Text as="span" type="h3">
            {expandGenderAgeLabel(metric.values[0]?.label)}
          </Text>
        </GridContent>
      </GridItem>
    )}
  </GridItemContainer>
)

Metric.propTypes = {
  metric: PropTypes.shape({
    key: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
  }).isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
function getSelectedMetricsGroup(metrics, selectedGroup) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  return metrics.find((group) => group.key === selectedGroup)
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
export const Table = ({ metrics, selectedGroup }) => (
  <Grid>
    {getSelectedMetricsGroup(metrics, selectedGroup).metrics.map(
      // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
      (metric, index) => (
        <Metric metric={metric} key={index} />
      ),
    )}
  </Grid>
)

Table.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          values: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
            }),
          ),
        }),
      ).isRequired,
    }),
  ).isRequired,
  selectedGroup: PropTypes.string.isRequired,
}

const AudienceOverviewTable = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
  metrics,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedGroup' implicitly has an ... Remove this comment to see the full error message
  selectedGroup,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectMetricsGroup' implicitly ha... Remove this comment to see the full error message
  selectMetricsGroup,
}) => {
  let content = null
  const selectedMetrics = getSelectedMetricsGroup(metrics, selectedGroup)

  console.log('metrics from audience overview chart', {
    metrics,
    selectedGroup,
    selectMetricsGroup,
    selectedMetrics,
  })

  if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else if (
    !selectedMetrics ||
    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    selectedMetrics.metrics.every((metric) => metric.values.length === 0)
  ) {
    content = <NoData chartName="audience-overview" />
  } else {
    content = <Table metrics={metrics} selectedGroup={selectedGroup} />
  }

  return (
    <ChartCard>
      <ChartHeader>
        <Title />
        <HeadersActions>
          {metrics.length > 1 && (
            <Select
              // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
              onSelectClick={(selectedItem) =>
                selectedItem.onItemClick(selectedItem.label)
              }
              // @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
              customButton={(onButtonClick) =>
                renderButton(
                  onButtonClick,
                  getSelectedMetricsGroup(metrics, selectedGroup).label,
                  'small',
                )
              }
              // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
              items={metrics.map((metric) => ({
                ...metric,
                onItemClick: selectMetricsGroup,
              }))}
              hideSearch
              keyMap={{
                id: 'key',
                title: 'label',
              }}
              size="small"
              xPosition="right"
              fullWidth
            />
          )}
          {metrics.length > 1 && <HeaderSpacer />}
          {metrics.length > 0 && (
            <AddReport
              chart="demographic-overview"
              state={{
                selectedGroup,
              }}
            />
          )}
        </HeadersActions>
      </ChartHeader>
      <Container id="js-dom-to-png-audience-summary">{content}</Container>
    </ChartCard>
  )
}

AudienceOverviewTable.defaultProps = {
  loading: false,
  selectMetricsGroup: null,
  selectedGroup: '',
}

AudienceOverviewTable.propTypes = {
  loading: PropTypes.bool,
  metrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectMetricsGroup: PropTypes.func,
  selectedGroup: PropTypes.string,
}

export default AudienceOverviewTable
