import React from 'react'
import Select from '@bufferapp/ui/Select'
import ChevronDown from '@bufferapp/ui/Icon/Icons/ChevronDown'
import {
  fontFamily,
  fontSize,
  lineHeight,
  fontWeight,
  fontWeightBold,
} from '@bufferapp/ui/style/fonts'
import {
  white,
  twitter,
  grayDark,
  grayDarker,
} from '@bufferapp/ui/style/colors'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { TruncatedNumber } from '~/shared-components'

const Container = styled.div`
  width: 49%;
`

const ButtonContainer = styled.div`
  height: 68px;
  background: ${white};
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  display: flex;
  align-items: center;
  width: 100%;

  & svg {
    position: absolute;
    right: 16px;
  }

  :hover {
    cursor: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
      props.forReport ? 'auto' : 'pointer'};
    border-color: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
      props.forReport ? '#b8b8b8' : grayDark};
  }
`

const Decorator = styled.span`
  border: 1px solid
    ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'primary' does not exist on type 'ThemedS... Remove this comment to see the full error message
      props.primary ? twitter : '#9B51E0'};
  height: 44px;
  margin: 0 16px;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-family: ${fontFamily};
  font-weight: ${fontWeight};
  font-size: ${fontSize};
  line-height: ${lineHeight};
  color: #596269;
  padding-bottom: 4px;

  ${ButtonContainer}:hover & {
    color: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
      props.forReport ? '#596269' : grayDarker};
  }
`

const Metric = styled.span`
  font-family: ${fontFamily};
  font-weight: ${fontWeightBold};
  font-size: 24px;
  line-height: 28px;
  color: #333b43;
  margin-left: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'leftMargin' does not exist on type 'Them... Remove this comment to see the full error message
    props.leftMargin ? '24px' : ''};
`

class InfoLabelSelect extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
  renderButton(onButtonClick) {
    // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Reado... Remove this comment to see the full error message
    const { forReport, primary, displayedMetric } = this.props
    const content =
      displayedMetric.label === 'None' ? (
        <LabelContainer>
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <Metric leftMargin>N/A</Metric>
        </LabelContainer>
      ) : (
        <React.Fragment>
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <Decorator primary={primary} />
          <LabelContainer>
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <Title forReport={forReport}>{displayedMetric.label}</Title>
            <Metric>
              <TruncatedNumber
                showPercentSign={displayedMetric.label === 'Engagement Rate'}
              >
                {displayedMetric.value}
              </TruncatedNumber>
            </Metric>
          </LabelContainer>
        </React.Fragment>
      )
    return (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <ButtonContainer onClick={onButtonClick} forReport={forReport}>
        {content}
        {!forReport && <ChevronDown />}
      </ButtonContainer>
    )
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'metrics' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { metrics, forReport } = this.props
    if (forReport) {
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
      return <Container>{this.renderButton()}</Container>
    }
    return (
      <Container>
        <Select
          // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
          onSelectClick={(selectedItem) => selectedItem.onItemClick()}
          // @ts-expect-error TS(7006) FIXME: Parameter 'onButtonClick' implicitly has an 'any' ... Remove this comment to see the full error message
          customButton={(onButtonClick) => this.renderButton(onButtonClick)}
          items={metrics}
          hideSearch
          fullWidth
        />
      </Container>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
InfoLabelSelect.defaultProps = {
  metrics: [],
  displayedMetric: {},
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
InfoLabelSelect.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      onItemClick: PropTypes.func,
    }),
  ),
  displayedMetric: PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
    // @ts-expect-error TS(2339) FIXME: Property 'num' does not exist on type 'typeof impo... Remove this comment to see the full error message
    value: PropTypes.num,
  }),
  primary: PropTypes.bool,
  forReport: PropTypes.bool,
}

export default InfoLabelSelect
