import dayjs from 'dayjs'
import { ALL_DAYS, sortableAttributes } from './reducer'

export default class Stories {
  // @ts-expect-error TS(7006) FIXME: Parameter 'stories' implicitly has an 'any' type.
  static filter(stories, day) {
    if (day !== ALL_DAYS) {
      return stories.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'story' implicitly has an 'any' type.
        (story) =>
          dayjs(story.date * 1000).format('D-M-Y') ===
          dayjs(day).format('D-M-Y'),
      )
    }
    return stories
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'stories' implicitly has an 'any' type.
  static sort(stories, sortBy) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'aStory' implicitly has an 'any' type.
    return stories.sort((aStory, anotherStory) => {
      if (sortBy === sortableAttributes.DATE) {
        return aStory.date >= anotherStory.date ? 1 : -1
      }
      return aStory[sortBy] <= anotherStory[sortBy] ? 1 : -1
    })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'stories' implicitly has an 'any' type.
  static filterAndSort(stories, day, sortBy) {
    let selectedStories = Stories.filter(stories, day)
    selectedStories = Stories.sort(selectedStories, sortBy)

    return selectedStories
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'stories' implicitly has an 'any' type.
  static summary(stories) {
    let impressions = 0
    let reach = 0
    let exits = 0
    let completionRate = 0

    // @ts-expect-error TS(7006) FIXME: Parameter 'story' implicitly has an 'any' type.
    stories.forEach((story) => {
      impressions += story.impressions
      reach += story.reach
      exits += story.exits
      completionRate += story.completionRate / stories.length
    })

    return {
      impressions,
      reach: Math.round((reach / stories.length) * 100) / 100,
      completionRate: Math.round(completionRate * 100 * 100) / 100,
      exits,
    }
  }
}
