import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes, ACTION_KEY_WRAPPER } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export function shouldFetch(state) {
  return state.profiles.selectedProfileId
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const state = getState()
  switch (action.type) {
    case profileActionTypes.REFRESH_PROFILES:
    case actionTypes.FETCH:
      dispatch(
        actions.fetch({
          name: ACTION_KEY_WRAPPER,
          args: {
            profiles: state.profiles.socialProfiles,
          },
        }),
      )
      break
    default:
      break
  }
  return next(action)
}
