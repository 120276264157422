import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled, { css } from 'styled-components'

import { Button, Text } from '@buffer-mono/legacy-bufferapp-components'

import { blue, white, grayLight } from '@bufferapp/ui/style/colors'

const Container = styled.div`
  display: inline-flex;
  width: 1.7rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'inactive' does not exist on type 'Themed... Remove this comment to see the full error message
    props.inactive &&
    css`
      opacity: 0.5;
      &:hover {
        cursor: disabled;
      }
    `}
`

const Marker = styled.span`
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  line-height: 1.7rem;
  border-radius: 1.7rem;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'today' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.today &&
    css`
      background: ${grayLight};
    `}

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'selected' does not exist on type 'Themed... Remove this comment to see the full error message
    props.selected &&
    css`
      background: ${blue};
      color: ${white};
    `}

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'start' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.start &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'end' does not exist on type 'ThemedStyle... Remove this comment to see the full error message
    props.end &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}
`

const StartMarkerConnector = styled.span`
  height: 1.5rem;
  flex-grow: 1;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.active &&
    css`
      background: ${blue};
    `}

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'round' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.round &&
    css`
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    `}
`

const EndMarkerConnector = styled.span`
  height: 1.5rem;
  flex-grow: 1;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'active' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.active &&
    css`
      background: ${blue};
    `}

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'round' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.round &&
    css`
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    `}
`

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Day = (props) => {
  const {
    timestamp,
    day,
    inMonth,
    today,
    selectedBetween,
    selectedStart,
    selectedEnd,
    isDisabled,
    startDate,
    endDate,
    disabledText,
    handleClick,
  } = props

  const m = moment.unix(timestamp)
  const isEndOfWeek = m.day() === 0
  const isStartOfWeek = m.day() === 1

  const isSelected = selectedStart || selectedEnd || selectedBetween

  const showStartConnector =
    (selectedStart || selectedBetween) &&
    endDate !== null &&
    startDate !== null &&
    startDate !== endDate

  const showEndConnector =
    (selectedEnd || selectedBetween) &&
    startDate !== null &&
    endDate !== startDate

  const showRoundEndConnector = isEndOfWeek && selectedBetween
  const showRoundStartConnector = isStartOfWeek && selectedBetween

  const markerTextColor =
    isSelected || showStartConnector || showEndConnector ? 'white' : null

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Container inactive={!inMonth || isDisabled} data-tip={disabledText}>
      <StartMarkerConnector
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        active={showEndConnector || showRoundStartConnector}
        round={showRoundStartConnector}
      />
      <Button
        // @ts-expect-error
        noStyle
        onClick={() => (isDisabled ? null : handleClick(timestamp))}
      >
        <Marker
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          hover={isDisabled}
          today={today}
          selected={isSelected}
          start={showStartConnector}
          end={showEndConnector}
        >
          <Text size="small" color={markerTextColor}>
            {day}
          </Text>
        </Marker>
      </Button>
      <EndMarkerConnector
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        active={showStartConnector || showRoundEndConnector}
        round={showRoundEndConnector}
      />
    </Container>
  )
}

Day.defaultProps = {
  startDate: null,
  endDate: null,
}

Day.propTypes = {
  day: PropTypes.number.isRequired,
  today: PropTypes.bool.isRequired,
  inMonth: PropTypes.bool.isRequired,
  selectedBetween: PropTypes.bool.isRequired,
  selectedEnd: PropTypes.bool.isRequired,
  selectedStart: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  disabledText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  timestamp: PropTypes.number.isRequired,
}

export default Day
