import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('shopify_sources', {
  FETCH: 'FETCH',
  SELECT_METRIC: 'SELECT_METRIC',
})

const initialState = {
  loading: true,
  hasError: false,
  metrics: [],
  selectedMetric: 'customers',
}

export const LABELS = {
  customers: 'Customers',
  sales: 'Total Sales',
  average_order_value: 'Avg. Order Value',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
function addLabelsToMetrics(metrics) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  return metrics.map((metric) => ({ ...metric, label: LABELS[metric.key] }))
}

// @ts-expect-error TS(7006) FIXME: Parameter 'sources' implicitly has an 'any' type.
function addLabelsToSourceMetrics(sources) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'source' implicitly has an 'any' type.
  return sources.map((source) => ({
    ...source,
    metrics: addLabelsToMetrics(source.metrics),
  }))
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `shopify_sources_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `shopify_sources_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case `shopify_sources_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        metrics: addLabelsToSourceMetrics(action.result),
      }
    case actionTypes.SELECT_METRIC:
      return Object.assign({}, state, {
        selectedMetric: action.metric,
      })
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  onSelectMetric(metric) {
    return {
      type: actionTypes.SELECT_METRIC,
      metric,
    }
  },
}
