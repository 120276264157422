import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'

export const actionTypes = {}

const initialState = {
  metrics: [],
  loading: true,
  hasError: false,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `stories_summary_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `stories_summary_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        metrics: action.result,
      }
    case `stories_summary_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export const actions = {}
