import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  ChartStateLoading,
  ChartCard,
  ChartHeader,
  ChartTitle,
} from '~/shared-components'

import AddReport from '~/add-report'

import Total from '../Total'

const AggregatedMetric = styled.section`
  padding: 0 1.5rem 1rem;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'metricName' implicitly has an 'an... Remove this comment to see the full error message
export const Title = ({ metricName, label }) => (
  <ChartTitle>Aggregate {label || metricName}</ChartTitle>
)

Title.defaultProps = {
  label: null,
}

Title.propTypes = {
  metricName: PropTypes.string.isRequired,
  label: PropTypes.string,
}

const AggregatedMetricChart = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'metricName' implicitly has an 'an... Remove this comment to see the full error message
  metricName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profiles' implicitly has an 'any'... Remove this comment to see the full error message
  profiles,
  ...otherProps
}) => {
  return (
    <ChartCard>
      <ChartHeader>
        <Title metricName={metricName} label={label} />
        <AddReport
          chart="aggregated-metric"
          state={{ metricName, profiles, label }}
        />
      </ChartHeader>
      <AggregatedMetric>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message */}
        {loading && <ChartStateLoading active noBorder />}
        {!loading && (
          <Total {...otherProps} metricName={metricName} label={label} />
        )}
      </AggregatedMetric>
    </ChartCard>
  )
}
AggregatedMetricChart.defaultProps = {
  label: null,
}

AggregatedMetricChart.propTypes = {
  loading: PropTypes.bool.isRequired,
  metricName: PropTypes.string.isRequired,
  label: PropTypes.string,
  metric: PropTypes.shape({
    total: PropTypes.number.isRequired,
    total_by_network: PropTypes.shape({
      percentage: PropTypes.number,
    }),
  }).isRequired,
}

export default AggregatedMetricChart
