import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import reducer, { actions } from './reducer'
import middleware from './middleware'
import AggregateHeader from './components/AggregateHeader'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'aggregates' does not exist on type 'Defa... Remove this comment to see the full error message
    loading: state.aggregates.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'aggregates' does not exist on type 'Defa... Remove this comment to see the full error message
    metrics: state.aggregates.metrics,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profiles: state.profiles.socialProfiles.map((profile) => profile.id),
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch),
  }),
)(AggregateHeader)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('aggregates', reducer)
addMiddleware(middleware)

export { default as reducer } from './reducer'
export { default as middleware } from './middleware'
export { Table } from './components/AggregateHeader'
export { default as Title } from './components/Title'
