import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import RecentPosts from './components/RecentPosts'
import reducer, { actions } from './reducer'
import middleware from './middleware'

// default export = container
export default connect(
  (state) => ({
    // add state here
    // @ts-expect-error TS(2339) FIXME: Property 'recentPosts' does not exist on type 'Def... Remove this comment to see the full error message
    loading: state.recentPosts.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'recentPosts' does not exist on type 'Def... Remove this comment to see the full error message
    metrics: state.recentPosts.posts,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profiles: state.profiles.socialProfiles,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
  }),
)(RecentPosts)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('recentPosts', reducer)
addMiddleware(middleware)

export {
  default as RecentPosts,
  Title,
  RecentPostsTable,
} from './components/RecentPosts'

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
