// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'

export const actionTypes = keyWrapper('CAMPAIGN_SELECTOR', {
  SELECT: 'SELECT',
})

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function getSelectedCampaign(state, campaignId) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
  return state.campaigns.find((campaign) => campaign.id === campaignId)
}

const initialState = {
  campaigns: [],
  selectedCampaign: null,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT:
      return {
        ...state,
        selectedCampaign: getSelectedCampaign(state, action.id),
      }

    case `campaigns_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        campaigns: action.result,
        selectedCampaign: state.selectedCampaign
          ? state.selectedCampaign
          : action.result[0],
      }

    case `getCampaign_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        selectedCampaign: {
          ...action.result,
          // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...state.selectedCampaign,
          channels: action.result.channels,
        },
      }

    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
  select(campaign) {
    return {
      type: actionTypes.SELECT,
      id: campaign.id,
    }
  },
}
