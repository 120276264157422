import React, { Component, Fragment } from 'react'
import dayjs from 'dayjs'
import { Select, Text } from '@bufferapp/ui'
import { ALL_DAYS } from '../../reducer'

class DayDropdown extends Component {
  render() {
    const allDaysCopy = 'All Stories'
    const dateFormat = 'dddd[,] MMMM D'
    const label =
      // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
      this.props.selectedDay === ALL_DAYS
        ? allDaysCopy
        : // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
          dayjs(this.props.selectedDay).format(dateFormat)
    const items = [
      {
        id: '0',
        title: allDaysCopy,
        value: ALL_DAYS,
        // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
        selected: this.props.selectedDay === ALL_DAYS,
      },
      // @ts-expect-error TS(2339) FIXME: Property 'dates' does not exist on type 'Readonly<... Remove this comment to see the full error message
      ...this.props.dates.map((day, index) => {
        return {
          id: index + 1,
          title: dayjs(day).format(dateFormat),
          value: day,
          // @ts-expect-error TS(2339) FIXME: Property 'selectedDay' does not exist on type 'Rea... Remove this comment to see the full error message
          selected: this.props.selectedDay === day,
        }
      }),
    ]

    return (
      <Fragment>
        <Text type="label">Show</Text>
        <Select
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          label={label}
          items={items}
          multiSelect={false}
          // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
          onSelectClick={(item) => this.props.filterBy(item.value)}
        />
      </Fragment>
    )
  }
}

export default DayDropdown
