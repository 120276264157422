import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  outerSpaceLight,
  curiousBlue,
} from '@buffer-mono/legacy-bufferapp-components'

const StyledButton = styled.button`
  transition: all 0.2s ease-out;
  background: white;
  border: 1px solid #b8b8b8;
  border-radius: 3px;
  padding: 0rem 1rem;
  outline: none;
  height: 42px;

  box-shadow: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'headerButton' does not exist on type 'Th... Remove this comment to see the full error message
    props.headerButton ? '0px 1px 2px rgba(0, 0, 0, 0.05)' : 'none'};

  &:hover {
    cursor: pointer;
    border: 1px solid ${curiousBlue};
    box-shadow: 0 1px 2px ${outerSpaceLight};
  }
  &:active {
    border: 1px solid #0e5c98;
    box-shadow: 0 1px 4px ${outerSpaceLight};
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Button = ({ children, ...restProps }) => (
  <StyledButton {...restProps}>{children}</StyledButton>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Button
