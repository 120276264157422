import PropTypes from 'prop-types'
import React from 'react'
import reactDOM from 'react-dom/server'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'
import Text from '@bufferapp/ui/Text'
import { geyser } from '@buffer-mono/legacy-bufferapp-components'
import DemographicTooltip from '~/shared-components/DemographicTooltip'

const SERIES_COLOR = {
  M: '107, 129, 255',
  F: '107, 129, 255',
  U: '107, 129, 255',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function expandGenderAgeLabel(label) {
  return label
    .replace(/^M\./, 'Male, ')
    .replace(/^F\./, 'Female, ')
    .replace(/^U./, 'Undisclosed, ')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'number' implicitly has an 'any' type.
function truncateNumber(number) {
  return numeral(number).format('0,0')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function formatGroupLabel(label) {
  return label.replace('Your ', '').toLowerCase()
}

// @ts-expect-error TS(7006) FIXME: Parameter 'series' implicitly has an 'any' type.
function applyColors(series, gender, valuesRange) {
  const minValue = valuesRange.min
  const maxValue = valuesRange.max
  const minOpacity = 0.2
  const maxOpacity = 0.9
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  return series.map((data) => {
    const opacity =
      ((data.z - minValue) / (maxValue - minValue)) *
        (maxOpacity - minOpacity) +
      minOpacity
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const color = `rgba(${SERIES_COLOR[gender]},${opacity}`
    return Object.assign(data, {
      color,
    })
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'genderData' implicitly has an 'any' typ... Remove this comment to see the full error message
function formatGenderIntoSeries(genderData, ageGroups, gender, valuesRange) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'age' implicitly has an 'any' type.
  const series = ageGroups.map((age, index) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'g' implicitly has an 'any' type.
    const data = genderData.find((g) => g.age === age)
    const value = data ? data.value : 0
    const label = data ? expandGenderAgeLabel(data.label) : ''
    const groupLabel = data ? formatGroupLabel(data.groupLabel) : ''
    return {
      x: index,
      y: Object.keys(SERIES_COLOR).indexOf(gender),
      z: value,
      label,
      groupLabel,
    }
  })

  return applyColors(series, gender, valuesRange)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'breakdown' implicitly has an 'any' type... Remove this comment to see the full error message
function formatSeries(breakdown, ageGroups) {
  // @ts-expect-error TS(7034) FIXME: Variable 'series' implicitly has type 'any[]' in s... Remove this comment to see the full error message
  const series = []
  const allValues = Object.keys(breakdown)
    // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    .map((key) => breakdown[key].map((e) => e.value))
    .join()
    .split(',')
    // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    .sort((a, b) => a - b)
  const valuesRange = {
    min: allValues[0],
    max: allValues.pop(),
  }
  Object.keys(SERIES_COLOR).forEach((gender) => {
    if (breakdown[gender]) {
      series.push({
        data: formatGenderIntoSeries(
          breakdown[gender],
          ageGroups,
          gender,
          valuesRange,
        ),
      })
    }
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'series' implicitly has an 'any[]' type.
  return series
}

// @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
function extractMetrics(group) {
  // @ts-expect-error TS(7034) FIXME: Variable 'ageGroups' implicitly has type 'any[]' i... Remove this comment to see the full error message
  const ageGroups = []
  const breakdown = {}
  group.metrics
    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    .find((metric) => metric.key === 'gender_age')
    // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
    .values.forEach(({ label, value }) => {
      const [gender, age] = label.split('.')
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!breakdown[gender]) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        breakdown[gender] = []
      }
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      breakdown[gender].push({
        age,
        label,
        value,
        groupLabel: group.label,
      })

      // @ts-expect-error TS(7005) FIXME: Variable 'ageGroups' implicitly has an 'any[]' typ... Remove this comment to see the full error message
      if (ageGroups.indexOf(age) === -1) {
        ageGroups.push(age)
      }
    })
  // @ts-expect-error TS(7005) FIXME: Variable 'ageGroups' implicitly has an 'any[]' typ... Remove this comment to see the full error message
  ageGroups.sort()
  return {
    breakdown,
    // @ts-expect-error TS(7005) FIXME: Variable 'ageGroups' implicitly has an 'any[]' typ... Remove this comment to see the full error message
    ageGroups,
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
export const getPlotline = (value) => ({
  value,
  width: 1,
  color: geyser,
  dashStyle: 'ShortDash',
})

// @ts-expect-error TS(7006) FIXME: Parameter 'ageGroups' implicitly has an 'any' type... Remove this comment to see the full error message
export const getXAxis = (ageGroups) => ({
  lineWidth: 0, // hide bottom line
  gridLineWidth: 0,
  tickInterval: 0,
  tickWidth: 0,
  startOnTick: false,
  endOnTick: false,
  showFirstLabel: true,
  showLastLabel: true,
  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  categories: ageGroups.map((group) => group),
  labels: {
    style: {
      color: '#636363',
      'font-size': '14px',
      'font-weight': '500',
      'font-family': 'Roboto, sans serif',
      'white-space': 'nowrap',
    },
  },
  // Plotlines are required to get the lines to bisect
  // our markers, instead of exist to the left and
  // right of them.
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  plotLines: ageGroups.map((value, index) => getPlotline(index)),
})

export const getYAxis = () => ({
  title: {
    text: '',
  },
  gridLineWidth: 0,
  startOnTick: false,
  endOnTick: false,
  tickInterval: 1,
  categories: ['Male', 'Female', 'Undisclosed'],
  plotLines: [getPlotline(0), getPlotline(1), getPlotline(2)],
  labels: {
    style: {
      color: '#636363',
      'font-size': '14px',
      'font-weight': '500',
      'font-family': 'Roboto, sans serif',
      'white-space': 'nowrap',
    },
  },
})

// @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
export default (group) => {
  const { breakdown, ageGroups } = extractMetrics(group)
  return {
    title: null,
    xAxis: getXAxis(ageGroups),
    yAxis: getYAxis(),
    chart: {
      type: 'bubble',
      plotBorderWidth: 0,
      animation: true,
      height: 350,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          lineWidth: 1,
          states: {
            hover: {
              radiusPlus: 2,
            },
          },
        },
      },
      bubble: {
        minSize: '0%',
        maxSize: '32%',
      },
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: 7,
      borderWidth: 0,
      formatter: function customTooltip() {
        // @ts-expect-error TS(2339) FIXME: Property 'point' does not exist on type '{ backgro... Remove this comment to see the full error message
        const value = truncateNumber(this.point.z)
        return reactDOM.renderToStaticMarkup(
          <ChartTooltip
            // @ts-expect-error TS(2339) FIXME: Property 'point' does not exist on type '{ backgro... Remove this comment to see the full error message
            groupLabel={this.point.groupLabel}
            // @ts-expect-error TS(2339) FIXME: Property 'point' does not exist on type '{ backgro... Remove this comment to see the full error message
            label={this.point.label}
            value={value}
          />,
        )
      },
      followPointer: false,
      shadow: false,
      useHTML: true,
    },
    series: formatSeries(breakdown, ageGroups),
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
const ChartTooltip = ({ value, label, groupLabel }) => (
  <DemographicTooltip title={label}>
    <Text type="span" color="white">
      {value}&nbsp;{groupLabel}
    </Text>
  </DemographicTooltip>
)

ChartTooltip.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  groupLabel: PropTypes.string.isRequired,
}
