import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import {
  actionTypes as profileActionTypes,
  getAllProfilesFromResult,
} from '~/profile-selector'

// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
const getFirstProfileForService = (service, profiles) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  profiles.find((profile) => profile.service === service)

const SUPPORTED_TABS_BY_SERVICE = {
  facebook: ['overview', 'posts', 'audience', 'answers'],
  instagram: ['overview', 'posts', 'stories', 'audience', 'answers'],
  twitter: ['overview', 'posts', 'answers'],
  shopify: ['overview'],
  linkedin: ['overview', 'posts', 'answers'],
}

const initialState = {
  activeChannel: null,
  channels: [],
  currentTab: 'overview',
  facebookProfile: null,
  instagramProfile: null,
  twitterProfile: null,
  linkedinProfile: null,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
function getChannelSupportedTabs(profile) {
  if (profile) {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return SUPPORTED_TABS_BY_SERVICE[profile.service]
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
function getChannelByProfile(profile) {
  return { ...profile, supportedTabs: getChannelSupportedTabs(profile) }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function getActiveChannelsFromState(state) {
  return [
    getChannelByProfile(state.facebookProfile),
    getChannelByProfile(state.twitterProfile),
    getChannelByProfile(state.instagramProfile),
    getChannelByProfile(state.shopifyProfile),
    getChannelByProfile(state.linkedinProfile),
  ].filter((e) => e.id)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
function setChannelsAndProfilesOnFetch(profiles) {
  const facebookProfile = getFirstProfileForService('facebook', profiles)
  const twitterProfile = getFirstProfileForService('twitter', profiles)
  const instagramProfile = getFirstProfileForService('instagram', profiles)
  const shopifyProfile = getFirstProfileForService('shopify', profiles)
  const linkedinProfile = getFirstProfileForService('linkedin', profiles)
  const newState = {
    facebookProfile,
    twitterProfile,
    instagramProfile,
    shopifyProfile,
    linkedinProfile,
  }

  return { ...newState, channels: getActiveChannelsFromState(newState) }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
function updateChannelsAndProfiles(profile, state) {
  const newState = {
    ...state,
  }
  newState[`${profile.service}Profile`] = profile
  newState.channels = getActiveChannelsFromState(newState)

  return newState
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `profiles_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        ...setChannelsAndProfilesOnFetch(
          getAllProfilesFromResult(action.result),
        ),
      }
    case `profiles_${dataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        hasError: true,
      }
    case profileActionTypes.SELECT_PROFILE:
      return {
        ...updateChannelsAndProfiles(action.profile, state),
        activeChannel: getChannelByProfile(action.profile),
      }
    default:
      return state
  }
}
