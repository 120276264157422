import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'

import MetricBreakdown from './MetricBreakdown'
import Capitalized from './Capitalized'

const AggregatedTotal = styled.main`
  margin-top: 1.2rem;
  display: flex;
  text-align: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'left' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.left ? 'left' : 'center'};
  flex-direction: column;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'metricName' implicitly has an 'an... Remove this comment to see the full error message
const Description = ({ metricName, label, networks }) => (
  <Text color="shuttleGray" size="mini">
    Total {label || metricName} of all{' '}
    <Capitalized>{networks.length > 1 ? '' : networks[0]}</Capitalized> social
    accounts
  </Text>
)
Description.propTypes = {
  metricName: PropTypes.string.isRequired,
  networks: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const Aggregated = styled.section`
  display: flex;
  align-items: flex-end;
  justify-content: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'multipleNetworks' does not exist on type... Remove this comment to see the full error message
    props.multipleNetworks ? 'space-between' : 'center'};
  padding-bottom: 1rem;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'metricName' implicitly has an 'an... Remove this comment to see the full error message
const Total = ({ metricName, label, ...otherProps }) => {
  const metric = otherProps[metricName]
  const multipleNetworks = Object.keys(metric.total_by_network).length > 1
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Aggregated multipleNetworks={multipleNetworks}>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <AggregatedTotal left={multipleNetworks}>
        <Text size="extra-large" color="black" weight="bold">
          {numeral(metric.total).format('0,0')}
        </Text>
        <Description
          metricName={metricName}
          label={label}
          networks={Object.keys(metric.total_by_network)}
        />
      </AggregatedTotal>
      {multipleNetworks && (
        <MetricBreakdown totalByNetwork={metric.total_by_network} />
      )}
    </Aggregated>
  )
}

Total.propTypes = {
  metricName: PropTypes.string.isRequired,
  metric: PropTypes.shape({
    total: PropTypes.number.isRequired,
    total_by_network: PropTypes.shape({
      percentage: PropTypes.number,
    }),
  }).isRequired,
  multipleNetworks: PropTypes.bool.isRequired,
}

export default Total
