import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button, Text } from '@buffer-mono/legacy-bufferapp-components'

import style from './style.module.less'

const ModeToggle = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'active' implicitly has an 'any' t... Remove this comment to see the full error message
  active,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleClick' implicitly has an 'a... Remove this comment to see the full error message
  handleClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'baseModeLabel' implicitly has an ... Remove this comment to see the full error message
  baseModeLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'secondaryModeLabel' implicitly ha... Remove this comment to see the full error message
  secondaryModeLabel,
}) => {
  const leftButtonClasses = classNames(style.leftButton, {
    [style.activeButton]: !active,
  })

  const rightButtonClasses = classNames(style.rightButton, {
    [style.activeButton]: active,
  })

  return (
    <span style={{ marginLeft: 'auto' }}>
      <Button
        // @ts-expect-error
        noStyle
        onClick={() => {
          handleClick(0)
        }}
      >
        <span className={leftButtonClasses}>
          <Text weight="medium" size="small" color="outerSpace">
            {baseModeLabel}
          </Text>
        </span>
      </Button>
      <Button
        // @ts-expect-error
        noStyle
        onClick={() => {
          handleClick(1)
        }}
      >
        <span className={rightButtonClasses}>
          <Text weight="medium" size="small" color="outerSpace">
            {secondaryModeLabel}
          </Text>
        </span>
      </Button>
    </span>
  )
}

ModeToggle.defaultProps = {
  active: false,
}

ModeToggle.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  baseModeLabel: PropTypes.string.isRequired,
  secondaryModeLabel: PropTypes.string.isRequired,
}

export default ModeToggle
