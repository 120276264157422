import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'

const TooltipHolder = styled.div`
  max-width: 200px;
  padding: 8px;
  color: #fff;
  cursor: default;
  font-family: Roboto, sans serif;
  pointer-events: none;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
const Tooltip = ({ title, children }) => (
  <TooltipHolder>
    <Text type="span" color="white">
      {title}
    </Text>
    <span style={{ marginBottom: '6px', display: 'block' }} />
    {children}
  </TooltipHolder>
)

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Tooltip
