import React, { useState } from 'react'
import styled from 'styled-components'
import { MediaIcon } from '~/shared-components'

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  height: 0px;
  padding-top: 177%; /**this is 9:16 aspect ratio**/

  img {
    position: absolute;
    top: 0px;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`

const Type = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'src' implicitly has an 'any' type... Remove this comment to see the full error message
export default ({ src, type, serviceId, timestamp }) => {
  const [fallbackUrl, setFallbackUrl] = useState(null)
  const tryFallback = () => {
    if (!fallbackUrl) {
      setFallbackUrl(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        `https://buffer-analyze-images.buffer.com/${serviceId}/${timestamp}`,
      )
    }
  }
  return (
    <Thumbnail>
      <Image src={fallbackUrl || src} onError={tryFallback} />
      <Type>
        <MediaIcon type={type} />
      </Type>
    </Thumbnail>
  )
}
