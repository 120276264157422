import React from 'react'
import { Button } from '@bufferapp/ui'
import { useSplitEnabled } from '@buffer-mono/features'
import Title from '../Title'
import Body from '../Body'
import Reasons from '../Reasons'
import {
  Wrapper,
  Content,
  CrossSellImage,
  TagsWrapper,
  TagsCrossSellImage,
  TextContent,
} from './style'

const TagsContent = () => (
  <TagsWrapper>
    <TagsCrossSellImage
      src={
        'https://buffer-analyze.s3.amazonaws.com/images/tags_analyze_empty_state.png'
      }
    />
    <TextContent>
      <Title>Analyze your tags</Title>
      <Body>
        Get an overview of your tags at a glance, measure and report across
        multiple channels and skip all manual calculations
      </Body>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ size: string; type... Remove this comment to see the full error message */}
      <Button
        size="large"
        type="primary"
        label="Create Tag"
        onClick={() => {
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
          window.location = 'https://publish.buffer.com/tags/new'
        }}
      />
    </TextContent>
  </TagsWrapper>
)

export default () => {
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')

  if (isCampaignsToTagsEnabled) {
    return <TagsContent />
  }

  return (
    <Wrapper>
      <Content>
        <Title>Create your campaigns in Publish to analyze them here</Title>
        <Body>
          A campaign is a group of posts that you have tagged in Publish. By
          setting up campaigns, you will get automated reports of their
          performance here.
        </Body>
        <Reasons />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ size: string; type... Remove this comment to see the full error message */}
        <Button
          size="large"
          type="primary"
          label="Create my first campaign"
          onClick={() => {
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            window.location = 'https://publish.buffer.com/campaigns/new'
          }}
        />
      </Content>
      <CrossSellImage
        src={
          'https://s3.amazonaws.com/static.buffer.com/images/analyze/campaigns.png'
        }
      />
    </Wrapper>
  )
}
