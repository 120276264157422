import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import StoriesBreakdown from './components/StoriesBreakdown'
import reducer, { actions } from './reducer'
import middleware from './middleware'

// default export = container
export default connect(
  (state) => ({
    // add state here
    // @ts-expect-error TS(2339) FIXME: Property 'storiesBreakdown' does not exist on type... Remove this comment to see the full error message
    loading: state.storiesBreakdown.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'storiesBreakdown' does not exist on type... Remove this comment to see the full error message
    stories: state.storiesBreakdown.stories,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    startDate: state.date.startDate,
    // @ts-expect-error TS(2339) FIXME: Property 'date' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    endDate: state.date.endDate,
    // @ts-expect-error TS(2339) FIXME: Property 'storiesBreakdown' does not exist on type... Remove this comment to see the full error message
    attributeToSortBy: state.storiesBreakdown.sortBy,
    // @ts-expect-error TS(2339) FIXME: Property 'storiesBreakdown' does not exist on type... Remove this comment to see the full error message
    selectedDay: state.storiesBreakdown.selectedDay,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    serviceId: state.profiles.selectedProfile.serviceId,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'attribute' implicitly has an 'any' type... Remove this comment to see the full error message
    sortBy: (attribute) => dispatch(actions.sortBy(attribute)),
    // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
    selectDay: (day) => dispatch(actions.selectDay(day)),
  }),
)(StoriesBreakdown)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('storiesBreakdown', reducer)
addMiddleware(middleware)

export { Title, BreakdownContent } from './components/StoriesBreakdown'

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
