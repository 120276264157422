import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
import moment from 'moment-timezone'

export const actionTypes = keyWrapper('BEST_TIME', {
  FETCH: 'FETCH',
  SELECT_NEW_DAY: 'SELECT_NEW_DAY',
})

const initialState = {
  loading: true,
  hasError: false,
  selectedDay: '',
  topDays: [],
  days: [],
  hours: [],
  averageReach: null,
  timezone: null,
  isDefault: false,
}

type BestTimePredictionResponse = {
  weekday:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday'
  data: Array<{
    hour: string
    prediction: number
  }>
  prediction: number
  isDefault: boolean
}

// @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
export function getAverageReach(response) {
  return (
    // @ts-expect-error TS(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
    response.reduce((acc, val) => acc + val.prediction, 0) / response.length
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'array' implicitly has an 'any' type.
function getHours(array, newDay) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
  const newlySelectedDay = array.filter((day) => day.weekday === newDay)
  return newlySelectedDay[0].data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'mappedData' implicitly has an 'any' typ... Remove this comment to see the full error message
export function separateIntoGroups(mappedData) {
  // @ts-expect-error TS(7034) FIXME: Variable 'firstGroup' implicitly has type 'any[]' ... Remove this comment to see the full error message
  const firstGroup = []
  // @ts-expect-error TS(7034) FIXME: Variable 'secondGroup' implicitly has type 'any[]'... Remove this comment to see the full error message
  const secondGroup = []
  // @ts-expect-error TS(7034) FIXME: Variable 'thirdGroup' implicitly has type 'any[]' ... Remove this comment to see the full error message
  const thirdGroup = []
  // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
  mappedData.map((x) => {
    if (x.weekday === 'Monday' || x.weekday === 'Tuesday') {
      firstGroup.push(x)
    } else if (x.weekday === 'Wednesday' || x.weekday === 'Thursday') {
      secondGroup.push(x)
    } else {
      thirdGroup.push(x)
    }
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'firstGroup' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  return [firstGroup, secondGroup, thirdGroup]
}

// @ts-expect-error TS(7006) FIXME: Parameter 'groupedData' implicitly has an 'any' ty... Remove this comment to see the full error message
export function getHighestDayOfGroup(groupedData) {
  // @ts-expect-error TS(7034) FIXME: Variable 'highestDays' implicitly has type 'any[]'... Remove this comment to see the full error message
  const highestDays = []
  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  groupedData.forEach((item) => {
    const highestValuePerGroup = Math.max(
      // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
      ...item.map((day) => Math.round(day.prediction)),
    )
    const highestPrediction = item.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
      (day) => Math.round(day.prediction) === highestValuePerGroup,
    )
    highestDays.push(highestPrediction)
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'highestDays' implicitly has an 'any[]' t... Remove this comment to see the full error message
  return highestDays
}

// @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
function prettyPrintValue(day) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'hour' implicitly has an 'any' type.
  const highestValueOfDay = Math.max(...day.data.map((hour) => hour.prediction))
  const rawValue = day.data.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'hour' implicitly has an 'any' type.
    (hour) => hour.prediction === highestValueOfDay,
  )[0].hour
  return moment(rawValue, 'h').format('h:mma')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'days' implicitly has an 'any' type.
export function groupIntoConsummableObject(days) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
  const sortedDays = days.sort((a, b) => b[0].prediction - a[0].prediction)
  // @ts-expect-error TS(7034) FIXME: Variable 'consummableObj' implicitly has type 'any... Remove this comment to see the full error message
  const consummableObj = []
  // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
  sortedDays.map((day) => {
    consummableObj.push({
      key: day[0].weekday,
      value: prettyPrintValue(day[0]),
    })
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'consummableObj' implicitly has an 'any[]... Remove this comment to see the full error message
  return consummableObj
}

export function isDefaultPrediction(
  days: BestTimePredictionResponse[],
): boolean {
  return days.some((day) => day?.isDefault === true)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'mappedData' implicitly has an 'any' typ... Remove this comment to see the full error message
export function getTopDays(mappedData) {
  // separate into groups
  const mapped = separateIntoGroups(mappedData)
  // get the highest day of the group
  const highestDays = getHighestDayOfGroup(mapped)
  // get the best hour of each day
  return groupIntoConsummableObject(highestDays)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'topDays' implicitly has an 'any' type.
function getSelectedDay(topDays) {
  return topDays[0].key
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `best_time_${asyncDataFetchActionTypes.FETCH_START}`:
      return Object.assign({}, state, {
        timezone: state.timezone ? state.timezone : null,
        loading: true,
      })
    case `best_time_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case `best_time_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      if (action.result.length === 0) {
        return Object.assign({}, state, {
          loading: false,
          days: action.result,
        })
      } else {
        return Object.assign({}, state, {
          loading: false,
          selectedDay: getSelectedDay(getTopDays(action.result)),
          topDays: getTopDays(action.result),
          days: action.result,
          hours: getHours(
            action.result,
            getSelectedDay(getTopDays(action.result)),
          ),
          averageReach: getAverageReach(action.result),
          isDefault: isDefaultPrediction(action.result),
        })
      }

    case profileActionTypes.SELECT_PROFILE:
      return Object.assign({}, state, {
        timezone: action.profile.timezone
          ? action.profile.timezone
          : moment.tz.guess(),
      })
    case actionTypes.SELECT_NEW_DAY:
      return Object.assign({}, state, {
        selectedDay: action.newDay,
        hours: getHours(state.days, action.newDay),
      })
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'newDay' implicitly has an 'any' type.
  selectNewDay: (newDay) => ({
    type: actionTypes.SELECT_NEW_DAY,
    newDay,
  }),
}
