import PropTypes from 'prop-types'
import React from 'react'
import Text from '@bufferapp/ui/Text'
import { MetricIcon, TruncatedNumber } from '~/shared-components'
import { color } from '~/shared-components/style'

const metricsColor = color

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function transformLabelForTooltip(label) {
  return `${label.toLowerCase()}`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'count' implicitly has an 'any' type.
function postsWording(count, wording = 'post') {
  if (count === 1) {
    return wording
  }
  if (wording.match(/y$/)) {
    return wording.replace(/y$/, 'ies')
  }
  return `${wording}s`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'count' implicitly has an 'any' type.
function wasOrWere(count) {
  return count === 1 ? 'was' : 'were'
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profileService' implicitly has an 'any'... Remove this comment to see the full error message
function rewardWording(profileService) {
  switch (profileService) {
    default:
      return ', and you earned'
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
const MetricEntry = ({ metric }) => (
  <span>
    <span>
      <br />
      <Text color="white" type="span">
        <MetricIcon
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          metric={{ color: metricsColor[metric.key] || metric.color }}
        />{' '}
        <TruncatedNumber showPercentSign={metric.label === 'Engagement Rate'}>
          {metric.value}
        </TruncatedNumber>
      </Text>
      <Text color="white" type="span">
        {' '}
        {transformLabelForTooltip(metric.label)}
      </Text>
    </span>
  </span>
)

MetricEntry.propTypes = {
  metric: PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const MetricsList = ({ metrics }) => {
  const metricsWithoutStories = metrics.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
    (m) => m && m.label !== 'Stories',
  )
  return (
    <span>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type. */}
      {metricsWithoutStories.map((m) => (
        <MetricEntry key={m.label} metric={m} />
      ))}
    </span>
  )
}

MetricsList.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
}

const CustomTooltip = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileService' implicitly has an... Remove this comment to see the full error message
  profileService,
  // @ts-expect-error TS(7031) FIXME: Binding element 'metricData' implicitly has an 'an... Remove this comment to see the full error message
  metricData,
  // @ts-expect-error TS(7031) FIXME: Binding element 'secondaryMetric' implicitly has a... Remove this comment to see the full error message
  secondaryMetric,
  // @ts-expect-error TS(7031) FIXME: Binding element 'wording' implicitly has an 'any' ... Remove this comment to see the full error message
  wording,
}) => (
  <span>
    <span>
      <Text color="white" type="span">
        There {wasOrWere(metricData.postsCount)} a total of{' '}
      </Text>
      <Text color="white" type="span" weight="bold">
        <TruncatedNumber>{metricData.postsCount}</TruncatedNumber>
      </Text>
      {secondaryMetric.label !== 'Previous Period' && (
        <Text color="white" type="span">
          {' '}
          {postsWording(metricData.postsCount, wording)} published
        </Text>
      )}
      {secondaryMetric.label == 'Previous Period' && (
        <Text color="white" type="span">
          {' '}
          {postsWording(metricData.postsCount, wording)} published, compared to{' '}
          <Text color="white" type="span" weight="bold">
            <TruncatedNumber>{metricData.previousPostsCount}</TruncatedNumber>
          </Text>{' '}
          {postsWording(metricData.previousPostsCount, wording)} published in
          the previous period
        </Text>
      )}
    </span>
    {metricData.label !== 'Stories' && (
      <Text color="white" type="span">
        {rewardWording(profileService)}:
      </Text>
    )}
    <br />
    <MetricsList metrics={[metricData, secondaryMetric]} />
  </span>
)

CustomTooltip.propTypes = {
  profileService: PropTypes.string.isRequired,
  metricData: PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
    postsCount: PropTypes.number,
    value: PropTypes.number,
  }).isRequired,
  secondaryMetric: PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  wording: PropTypes.string,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'profileService' implicitly has an... Remove this comment to see the full error message
const ChartTooltip = ({ profileService, ...props }) => (
  <div
    style={{
      padding: '6px',
      color: '#fff',
      cursor: 'default',
      fontSize: '9pt',
      fontFamily: 'Roboto, sans serif',
      pointerEvents: 'none',
      whiteSpace: 'normal',
      fontWeight: 'bold',
    }}
  >
    {/* @ts-expect-error TS(2739) FIXME: Type '{ profileService: any; }' is missing the fol... Remove this comment to see the full error message */}
    <CustomTooltip profileService={profileService} {...props} />
  </div>
)

ChartTooltip.propTypes = {
  profileService: PropTypes.string.isRequired,
}

export default ChartTooltip
