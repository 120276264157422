import React from 'react'
import styled from 'styled-components'
import { curiousBlue, Text } from '@buffer-mono/legacy-bufferapp-components'

const Tag = styled.span`
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  color: white;
  font-weight: bold;
  background-color: ${curiousBlue};
  margin-left: 0.25rem;
`

const Legend = styled.section`
  display: flex;
  align-items: center;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FilterLegend = styled.section`
  width: 40rem;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
`

const Fade = styled.span`
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  display: block;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 4rem;
`

const SortByLegend = styled.section`
  padding: 0 0 0 1rem;
  box-sizing: border-box;
`

interface BreakdownLegendProps {
  posts: number
  searchTerms: string[]
  selectedMetric: {
    key: string
    label: string
  }
}

const BreakdownLegend = ({
  posts,
  searchTerms,
  selectedMetric,
}: BreakdownLegendProps): JSX.Element => (
  <Legend>
    <FilterLegend>
      <Text color="outerSpace" size="small" weight="bold">
        Showing {posts} {posts > 1 ? 'posts' : 'post'}{' '}
        {searchTerms.length ? 'filtered by' : 'in total'}
      </Text>
      {searchTerms.map((term, index) => (
        <Tag key={index}>
          <Text color="white" size="small">
            {term}
          </Text>
        </Tag>
      ))}
      <Fade />
    </FilterLegend>
    {selectedMetric && (
      <SortByLegend>
        <Text color="outerSpace" size="small" weight="bold">
          Sorted by {selectedMetric.label}
        </Text>
      </SortByLegend>
    )}
  </Legend>
)

export default BreakdownLegend
