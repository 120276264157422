import React from 'react'
import { Can, Cant } from './index'
import { LockedContent } from '~/upgrade-to-pro'
import { STORIES } from './constants'

const COPIES = {
  [STORIES]: {
    title: 'Unlock Stories Analytics',
    description:
      'Get indepth Instagram Stories analytics which help you get the most out of your content. You can also add the charts to reports and export them as images or CSV.',
  },
}

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const LockIfNotAllowed = ({ children, exporting, ...requestedPermissions }) => {
  const permission = Object.values(requestedPermissions)[0]
  if (!permission) {
    return children
  }
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2745) FIXME: This JSX tag's 'children' prop expects type 'never... Remove this comment to see the full error message */}
      <Can {...requestedPermissions}>{children}</Can>
      {/* @ts-expect-error TS(2745) FIXME: This JSX tag's 'children' prop expects type 'never... Remove this comment to see the full error message */}
      <Cant {...requestedPermissions}>
        <LockedContent
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          title={COPIES[permission].title}
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          description={COPIES[permission].description}
        >
          {children}
        </LockedContent>
      </Cant>
    </React.Fragment>
  )
}

export default LockIfNotAllowed
