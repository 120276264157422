import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import reducer, { actions } from './reducer'
import middleware from './middleware'
import StoriesDaily from './components/StoriesDaily'

export default connect(
  (state) => ({
    // add state here
    // @ts-expect-error TS(2339) FIXME: Property 'storiesDaily' does not exist on type 'De... Remove this comment to see the full error message
    loading: state.storiesDaily.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'storiesDaily' does not exist on type 'De... Remove this comment to see the full error message
    hasError: state.storiesDaily.hasError,
    // @ts-expect-error TS(2339) FIXME: Property 'storiesDaily' does not exist on type 'De... Remove this comment to see the full error message
    daily: state.storiesDaily.daily,
    // @ts-expect-error TS(2339) FIXME: Property 'storiesDaily' does not exist on type 'De... Remove this comment to see the full error message
    totals: state.storiesDaily.totals,
    // @ts-expect-error TS(2339) FIXME: Property 'storiesDaily' does not exist on type 'De... Remove this comment to see the full error message
    selectedMetrics: state.storiesDaily.selectedMetrics,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    onSelectMetric: (metric, isPrimary) =>
      dispatch(actions.onSelectMetric(metric, isPrimary)),
  }),
)(StoriesDaily)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('storiesDaily', reducer)
addMiddleware(middleware)

export { default as Title } from './components/Title'
export { ChartWithDropdowns as Chart } from './components/StoriesDaily'

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
