import React from 'react'
import styled from 'styled-components'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'
import Metric from './Metric'
// @ts-expect-error TS(2305) FIXME: Module '"prop-types"' has no exported member 'Prop... Remove this comment to see the full error message
import { PropTypes } from 'prop-types'
import Stories from '../../Stories'

const Footer = styled.footer`
  display: flex;
  align-items: center;
  padding: 0
    ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
      props.forReport ? '0' : '16px'}
    24px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'stories' implicitly has an 'any' ... Remove this comment to see the full error message
const Summary = ({ stories, forReport }) => {
  const { impressions, reach, completionRate, exits } = Stories.summary(stories)
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Footer forReport={forReport}>
      <Metric
        // @ts-expect-error TS(2322) FIXME: Type '{ name: string; label: string; value: any; }... Remove this comment to see the full error message
        name="impressions"
        label="Impressions"
        value={numeral(impressions).format('0,0')}
      />
      <Metric
        // @ts-expect-error TS(2322) FIXME: Type '{ name: string; label: string; value: any; }... Remove this comment to see the full error message
        name="reach"
        label="Average Reach"
        value={numeral(reach).format('0,0')}
      />
      <Metric
        // @ts-expect-error TS(2322) FIXME: Type '{ name: string; label: string; value: string... Remove this comment to see the full error message
        name="completionRate"
        label="Completion Rate"
        value={`${numeral(completionRate).format('0.00')}%`}
      />
      {/* @ts-expect-error TS(2322) FIXME: Type '{ name: string; label: string; value: any; }... Remove this comment to see the full error message */}
      <Metric name="exits" label="Exits" value={numeral(exits).format('0,0')} />
    </Footer>
  )
}

Summary.propTypes = {
  impressions: PropTypes.number.isRequired,
  reach: PropTypes.number.isRequired,
  completionRate: PropTypes.number.isRequired,
  exits: PropTypes.number.isRequired,
}

export default Summary
