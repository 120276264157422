import { push, LOCATION_CHANGE } from 'connected-react-router'
import {
  actions as fetchActions,
  actionTypes,
} from '@buffer-mono/async-data-fetch'
import { actions as analyticsActions } from '~/analytics-middleware'
import {
  actions as profilesActions,
  actionTypes as profileActionTypes,
  getAllProfilesFromResult,
} from './reducer'
import { isBackfilling, isEmpty } from '~/shared-components/utils'

const PROFILE_PAGES_PATH_REGEX = /^(.*)\/(.*)\/(.+$)/

export const NO_DATA_LAST_UPDATED_RETRY_INTERVAL = 20 * 1000
export const LAST_UPDATED_RETRY_INTERVAL = 5 * 60 * 1000
export const BACKFILLING_RETRY_INTERVAL = 5 * 1000

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function getElementFromPath(path, element) {
  const match = path.match(PROFILE_PAGES_PATH_REGEX)
  if (match) {
    const [
      route, // eslint-disable-line no-unused-vars
      channel,
      tool,
      profileId,
    ] = match

    switch (element) {
      case 'channel':
        return channel
      case 'profileId':
        return profileId
      case 'tool':
        return tool
      default:
        return null
    }
  }

  return null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function getProfileIdFromPath(path) {
  return getElementFromPath(path, 'profileId')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function getCurrentToolFromRoute(path) {
  return getElementFromPath(path, 'tool')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
function getProfileById(profiles, profileId) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
  return profiles.find((p) => p.id === profileId) || null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function getProfileFromPath(path, profiles) {
  const profileId = getProfileIdFromPath(path)
  if (profileId) {
    return getProfileById(profiles, profileId)
  }

  return null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function pathContainsValidProfileId(path, profiles) {
  return getProfileFromPath(path, profiles) !== null
}

// @ts-expect-error TS(7031) FIXME: Binding element 'router' implicitly has an 'any' t... Remove this comment to see the full error message
function getNewProfilePath({ router }, profile) {
  const tool = getCurrentToolFromRoute(router.location.pathname)
  return `/${profile.service}/${tool}/${profile.id}`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function getPageNameFromPath(path) {
  const tool = getCurrentToolFromRoute(path)
  if (tool) {
    return tool
  }

  const page = path.match(/^\/(\w*)\/?$/)
  if (page && page[1].length) {
    return page[1]
  }

  return 'home'
}

// @ts-expect-error TS(7031) FIXME: Binding element 'profiles' implicitly has an 'any'... Remove this comment to see the full error message
function shouldChangeSelectedProfile({ profiles }, profile) {
  if (profiles.selectedProfile === null) {
    return true
  }

  if (profiles.selectedProfile.id !== profile.id) {
    return true
  }

  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
function fetchLastUpdated(profiles, dispatch) {
  if (profiles) {
    dispatch(
      fetchActions.fetch({
        name: 'lastUpdated',
        args: {
          // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
          serviceIds: profiles.map((p) => p.serviceId),
        },
      }),
    )
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
function profilesHasData(profiles) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  return !!profiles.filter((profile) => !!profile.lastUpdated).length
}

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    switch (action.type) {
      case `profiles_${actionTypes.FETCH_SUCCESS}`: {
        fetchLastUpdated(getAllProfilesFromResult(action.result), dispatch)
        const profile = getProfileFromPath(
          getState().router.location.pathname,
          getAllProfilesFromResult(action.result),
        )
        if (profile) {
          dispatch(profilesActions.selectProfile(profile))
        }
        break
      }

      case `lastUpdated_${actionTypes.FETCH_SUCCESS}`: {
        const profilesFromState = getState().profiles.profiles
        const lastUpdated = getState().profiles.lastUpdated
        const profiles = action.result
        if (profiles.length !== 0) {
          // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
          if (isBackfilling(lastUpdated) || isEmpty(lastUpdated)) {
            setTimeout(() => {
              fetchLastUpdated(profilesFromState, dispatch)
            }, BACKFILLING_RETRY_INTERVAL)
          } else if (profilesHasData(profiles)) {
            setTimeout(() => {
              fetchLastUpdated(profilesFromState, dispatch)
            }, LAST_UPDATED_RETRY_INTERVAL)
          } else {
            setTimeout(() => {
              fetchLastUpdated(profilesFromState, dispatch)
            }, NO_DATA_LAST_UPDATED_RETRY_INTERVAL)
          }
        }
        break
      }

      case profileActionTypes.SELECT_PROFILE: {
        const currentPath = getState().router.location.pathname
        const profiles = getState().profiles.profiles
        const profile = action.profile
        if (
          pathContainsValidProfileId(currentPath, profiles) &&
          !action.onLocationChange
        ) {
          dispatch(push(getNewProfilePath(getState(), profile)))
        }
        break
      }

      case LOCATION_CHANGE: {
        const profiles = getState().profiles.profiles
        const path = action.payload.location.pathname
        let channel = null
        const organizationId = getState().profiles.organizationId
        let channelId = null
        let channelServiceId = null
        if (pathContainsValidProfileId(path, profiles)) {
          const locationProfile = getProfileFromPath(path, profiles)
          if (locationProfile) {
            channel = locationProfile.service
            channelId = locationProfile.id
            channelServiceId = locationProfile.serviceId
            if (shouldChangeSelectedProfile(getState(), locationProfile)) {
              dispatch(profilesActions.selectProfile(locationProfile, true))
            }
          }
        }

        if (action.payload.state && action.payload.state.profile) {
          const profile = action.payload.state.profile
          dispatch(profilesActions.selectProfile(profile))
        }

        dispatch(
          analyticsActions.pageChange({
            organizationId,
            channel,
            channelId,
            channelServiceId,
            name: getPageNameFromPath(path),
            title: 'Buffer',
            url: path,
            path,
          }),
        )

        break
      }

      default:
        break
    }
    return next(action)
  }
