import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import store from '~/store'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { addMiddleware } from 'redux-dynamic-middlewares'
import reducer, { actions } from './reducer'
import middleware from './middleware'
import DailyTotalsTable from './components/DailyTotalsTable'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const AggregatesDaily = (props) => {
  const { fetch, profiles } = props
  useEffect(fetch, [profiles])

  return <DailyTotalsTable {...props} />
}

AggregatesDaily.defaultProps = {
  metrics: {},
  profiles: [],
}

AggregatesDaily.propTypes = {
  metrics: PropTypes.object.isRequired,
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'aggregatesDaily' does not exist on type ... Remove this comment to see the full error message
    loading: state.aggregatesDaily.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'aggregatesDaily' does not exist on type ... Remove this comment to see the full error message
    metrics: state.aggregatesDaily.metrics,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profiles: state.profiles.socialProfiles,
  }),
  (dispatch) => ({
    fetch: () => dispatch(actions.fetch),
  }),
)(AggregatesDaily)

// @ts-expect-error TS(2339) FIXME: Property 'injectReducer' does not exist on type 'S... Remove this comment to see the full error message
store.injectReducer('aggregatesDaily', reducer)
addMiddleware(middleware)

export { default as Title } from './components/Title'
export { GridContainer as Table } from './components/DailyTotalsTable'

export { default as reducer } from './reducer'
export { default as middleware } from './middleware'
