import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'

export const actionTypes = {}

const initialState = {
  user: {
    loading: true,
    id: '0',
    name: '...',
    email: '...',
    avatar: '',
  },
  environment: 'production',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `user_${dataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      }
    case `changeDateTimePreferences_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `user_${dataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        user: {
          ...action.result,
          loading: false,
        },
      }
    default:
      return state
  }
}

export const actions = {}
