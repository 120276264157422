import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes } from './reducer'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'
import {
  actions as exportActions,
  actionTypes as exportActionTypes,
} from '~/png-export'

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const formatDataForCSVExport = ({ metrics }) => {
  if (!metrics.length) {
    return null
  }
  const data = {
    source: [],
  }
  // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  metrics[0].metrics.forEach(({ label }) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    data[label] = []
  })

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  metrics.forEach((item) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data.source.push(item.source)
    // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
    item.metrics.map((m) => {
      if (Object.keys(data).includes(m.label)) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[m.label].push(m.value)
      }
    })
  })
  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const state = getState()
  switch (action.type) {
    case actionTypes.FETCH:
      dispatch(
        actions.fetch({
          name: 'shopify_sources',
          args: {
            profileId: state.profiles.selectedProfileId,
            startDate: state.date.startDate,
            endDate: state.date.endDate,
          },
        }),
      )
      break
    case actionTypes.SELECT_METRIC:
      dispatch(
        actions.fetch({
          name: 'shopify_sources',
          args: {
            profileId: state.profiles.selectedProfileId,
            startDate: state.date.startDate,
            endDate: state.date.endDate,
            sortBy: action.metric,
            descending: true,
          },
        }),
      )
      break
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      dispatch(
        exportCSVActions.exportChart(
          'shopify-sources',
          formatDataForCSVExport(getState().shopifySources),
        ),
      )
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-shopify-sources',
          'shopify-sources',
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
