import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Text,
  Button,
  geyser,
  outerSpace,
  shuttleGray,
} from '@buffer-mono/legacy-bufferapp-components'
import { ArrowDown, ArrowUp } from '@bufferapp/ui/Icon'

import { TruncatedNumber } from '~/shared-components'

const Row = styled.li`
  display: flex;
  align-content: stretch;
  margin: 0;
  padding: 0;
  border-color: ${geyser};
  border-bottom: 1px dotted ${geyser};
  vertical-align: center;

  &:first-child {
    border-top: 1px dotted ${geyser};
  }

  &:last-child {
    border-bottom: none;
  }
`

const HeaderRow = styled(Row)`
  border-bottom: 1px solid ${geyser};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.025);
`

const Cell = styled.div`
  width: 190px;
  position: relative;
  color: ${outerSpace};
  padding: 1rem;
  text-align: left;
  align-items: center;
  border-left: 1px dotted ${geyser};
  box-sizing: border-box;
  display: flex;
`

const NameCell = styled(Cell)`
  display: flex;
  flex: 1;
  border-left: 0px;
  flex-shrink: 0;
`

const HashtagCell = styled(NameCell)`
  display: flex;
  flex: 1;
`

const NumberCell = styled(Cell)`
  width: 46px;
  text-align: right;
  border-right: 1px dotted ${geyser};
  border-left: 0px;
  box-sizing: content-box;
  flex-shrink: 0;
  padding-left: 0;

  & > span {
    margin-left: auto;
  }
`

const HeaderNumberCell = styled(NumberCell)`
  padding-left: 0;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'hashtag' implicitly has an 'any' ... Remove this comment to see the full error message
const TableRow = ({ hashtag, index }) => (
  <Row>
    <NumberCell>
      <Text size="mini" weight="bold">
        {index + 1}
      </Text>
    </NumberCell>
    <HashtagCell>
      <Text size="mini">{hashtag.display_name}</Text>
    </HashtagCell>
    <Cell>
      <Text size="mini">{hashtag.posts_count}</Text>
    </Cell>
    <Cell>
      <Text size="mini">
        <TruncatedNumber>{hashtag.primary_metric}</TruncatedNumber>
      </Text>
    </Cell>
    <Cell>
      <Text size="mini">
        <TruncatedNumber showPercentSign>
          {hashtag.secondary_metric}
        </TruncatedNumber>
      </Text>
    </Cell>
  </Row>
)

TableRow.propTypes = {
  hashtag: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    posts_count: PropTypes.number.isRequired,
    primary_metric: PropTypes.number.isRequired,
    secondary_metric: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

const SortingHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  height: 16px;
  padding-top: 0px;

  & > div {
    top: 0px;
    margin-top: -2px;
  }

  svg {
    fill: ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'gray' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
      props.gray ? geyser : shuttleGray};
  }
`

const Sorting = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
  forReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'metricKey' implicitly has an 'any... Remove this comment to see the full error message
  metricKey,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDescending' implicitly has an '... Remove this comment to see the full error message
  isDescending,
  // @ts-expect-error TS(7031) FIXME: Binding element 'sortBy' implicitly has an 'any' t... Remove this comment to see the full error message
  sortBy,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleSortBy' implicitly has an '... Remove this comment to see the full error message
  handleSortBy,
}) => (
  <SortingHolder
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    sortBy={sortBy}
    metricKey={metricKey}
    gray={sortBy !== metricKey && !forReport}
  >
    {sortBy !== metricKey && !forReport && (
      <Button
        // @ts-expect-error
        noStyle
        onClick={() => handleSortBy({ isDescending: false, metricKey })}
      >
        <ArrowUp />
      </Button>
    )}
    {sortBy !== metricKey && !forReport && (
      <Button
        // @ts-expect-error
        noStyle
        onClick={() => handleSortBy({ isDescending: true, metricKey })}
      >
        <ArrowDown />
      </Button>
    )}
    {sortBy === metricKey && (
      <Button
        // @ts-expect-error
        noStyle
        disabled={forReport}
        onClick={() => handleSortBy({ isDescending: !isDescending, metricKey })}
      >
        {isDescending ? <ArrowDown /> : <ArrowUp />}
      </Button>
    )}
  </SortingHolder>
)

Sorting.propTypes = {
  forReport: PropTypes.bool,
  handleSortBy: PropTypes.func,
  isDescending: PropTypes.bool,
  metricKey: PropTypes.string,
  sortBy: PropTypes.string,
}

Sorting.defaultProps = {
  forReport: false,
  handleSortBy: null,
  isDescending: false,
  metricKey: null,
  sortBy: null,
}

export const TableHeader = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'forReport' implicitly has an 'any... Remove this comment to see the full error message
  forReport,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDescending' implicitly has an '... Remove this comment to see the full error message
  isDescending,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hideSortButtons' implicitly has a... Remove this comment to see the full error message
  hideSortButtons,
  // @ts-expect-error TS(7031) FIXME: Binding element 'sortBy' implicitly has an 'any' t... Remove this comment to see the full error message
  sortBy,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleSortBy' implicitly has an '... Remove this comment to see the full error message
  handleSortBy,
  // @ts-expect-error TS(7031) FIXME: Binding element 'labels' implicitly has an 'any' t... Remove this comment to see the full error message
  labels,
}) => (
  <HeaderRow>
    <HeaderNumberCell>
      <Text size="mini" color="outerSpace" weight="medium">
        Rank
      </Text>
    </HeaderNumberCell>
    <NameCell>
      <Text size="mini" color="outerSpace" weight="medium">
        Hashtag
      </Text>
    </NameCell>
    <Cell>
      <Text size="mini" color="outerSpace" weight="medium">
        Posts
      </Text>
      {!hideSortButtons && (
        <Sorting
          forReport={forReport}
          handleSortBy={handleSortBy}
          sortBy={sortBy}
          metricKey="posts_count"
          isDescending={isDescending}
        />
      )}
    </Cell>
    <Cell>
      <Text size="mini" color="outerSpace" weight="medium">
        Average {labels.primary_metric}
      </Text>
      {!hideSortButtons && (
        <Sorting
          forReport={forReport}
          handleSortBy={handleSortBy}
          sortBy={sortBy}
          metricKey="primary_metric"
          isDescending={isDescending}
        />
      )}
    </Cell>
    <Cell>
      <Text size="mini" color="outerSpace" weight="medium">
        Average Eng. Rate
      </Text>
      {!hideSortButtons && (
        <Sorting
          forReport={forReport}
          handleSortBy={handleSortBy}
          sortBy={sortBy}
          metricKey="secondary_metric"
          isDescending={isDescending}
        />
      )}
    </Cell>
  </HeaderRow>
)

TableHeader.propTypes = {
  forReport: PropTypes.bool,
  isDescending: PropTypes.bool,
  hideSortButtons: PropTypes.bool,
  sortBy: PropTypes.string,
  handleSortBy: PropTypes.func,
  labels: PropTypes.shape({
    primary_metric: PropTypes.string,
    secondary_metric: PropTypes.string,
  }),
}

TableHeader.defaultProps = {
  forReport: false,
  isDescending: false,
  hideSortButtons: false,
  sortBy: null,
  handleSortBy: null,
  labels: {
    primary_metric: '',
    secondary_metric: '',
  },
}

export default TableRow
