import {
  actions,
  actionTypes as fetchActions,
} from '@buffer-mono/async-data-fetch'
import { actions as analyticsActions } from '~/analytics-middleware'
import { getNumOfDaysForDateRange } from '~/shared-components/utils'
import { actionTypes } from './actions'

// @ts-expect-error TS(7006) FIXME: Parameter 'chartId' implicitly has an 'any' type.
const isChartWithNoProfilesAttached = (chartId) =>
  chartId === 'comparison' ||
  chartId.match(/aggregate/) ||
  chartId.match(/campaign/) ||
  chartId === 'recent-posts'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  switch (action.type) {
    case actionTypes.ADD_TO_REPORT:
      store.dispatch(
        analyticsActions.trackEvent('reportModuleAdded', {
          module: action.chart_id.replace('-', ' '),
          reportId: action.reportId,
          channel: isChartWithNoProfilesAttached(action.chart_id)
            ? null
            : store.getState().profiles.selectedProfile.service,
        }),
      )

      store.dispatch(
        actions.fetch({
          name: 'add_chart_to_report',
          args: {
            reportId: action.reportId,
            profileId: isChartWithNoProfilesAttached(action.chart_id)
              ? null
              : store.getState().profiles.selectedProfileId,
            profileService: isChartWithNoProfilesAttached(action.chart_id)
              ? null
              : store.getState().profiles.selectedProfile.service,
            serviceId: isChartWithNoProfilesAttached(action.chart_id)
              ? null
              : store.getState().profiles.selectedProfile.serviceId,
            chartId: action.chart_id,
            state: action.state,
            organizationId: store.getState().profiles.organizationId,
          },
        }),
      )
      break
    case actionTypes.CREATE_REPORT:
      store.dispatch(
        actions.fetch({
          name: 'create_report',
          args: {
            userId: store.getState().appSidebar.user._id,
            organizationId: store.getState().profiles.organizationId,
            profileId: isChartWithNoProfilesAttached(action.chart_id)
              ? null
              : store.getState().profiles.selectedProfileId,
            profileService: isChartWithNoProfilesAttached(action.chart_id)
              ? null
              : store.getState().profiles.selectedProfile.service,
            serviceId: isChartWithNoProfilesAttached(action.chart_id)
              ? null
              : store.getState().profiles.selectedProfile.serviceId,
            chartId: action.chart_id,
            name: action.name,
            state: action.state,
            dateRange: {
              range: store
                .getState()
                // @ts-expect-error TS(7006) FIXME: Parameter 'preset' implicitly has an 'any' type.
                .date.presets.find((preset) => preset.selected).range,
              start: store.getState().date.startDate,
              end: store.getState().date.endDate,
            },
          },
        }),
      )
      break
    case `create_report_${fetchActions.FETCH_SUCCESS}`:
      store.dispatch(
        analyticsActions.trackEvent('reportCreated', {
          title: action.result.name,
          days: getNumOfDaysForDateRange(store.getState().date),
          reportId: action.result._id,
        }),
      )
      break
    case `add_chart_to_report_${fetchActions.FETCH_FAIL}`:
      store.dispatch(
        analyticsActions.trackEvent('analyticsAddChartToReportFailed', {
          channel: store.getState().profiles.selectedProfile.service,
        }),
      )
      break
    default:
      break
  }
  return next(action)
}
