import React from 'react'
import styled from 'styled-components'
import { Link } from '@buffer-mono/legacy-bufferapp-components'
import SidebarListItem from '@bufferapp/ui/SidebarListItem'
import { green, white } from '@bufferapp/ui/style/colors'
import {
  Analyze,
  ChartLine,
  Facebook,
  Home,
  Instagram,
  Shopify,
  Twitter,
  LinkedIn,
  Tag,
  X,
} from '@bufferapp/ui/Icon'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledSidebarListItem = styled(SidebarListItem)`
  width: 100% !important;
`

const StyledNewTag = styled.span`
  color: ${white};
  font-size: 10px;
  background: ${green};
  border-radius: 12px;
  padding: 2px 5px;
  margin-left: 4px;
`

const iconMap: { [key: string]: JSX.Element } = {
  home: <Home />,
  facebook: <Facebook />,
  instagram: <Instagram />,
  twitter: <Twitter />,
  shopify: <Shopify />,
  campaigns: <ChartLine />,
  tags: <Tag />,
  reports: <Analyze />,
  linkedin: <LinkedIn />,
  x: <X />,
}

const NewTag = (): JSX.Element => <StyledNewTag>New</StyledNewTag>

interface ItemProps {
  href: string
  route: string
  children: string
  onClick: (href: string, selectedProfile: { id: string }) => void
  isNew: boolean
  selectedProfile: { id: string }
  service: string
}
const Item = ({
  href,
  route,
  children,
  onClick,
  selectedProfile,
  isNew = false,
  service,
}: ItemProps): JSX.Element => {
  const selected = href === '/' ? href === route : route.includes(href)

  return (
    <Link
      // @ts-expect-error
      href={href}
      unstyled
      onClick={(e: any): void => {
        e.preventDefault()
        const isCurrent = href === route
        if (!isCurrent) {
          onClick(href, selectedProfile)
        }
      }}
    >
      <Wrapper>
        <StyledSidebarListItem
          id={href}
          title={children}
          icon={iconMap[service]}
          onItemClick={(): void => undefined}
          selected={selected}
          badges={isNew && <NewTag />}
        />
      </Wrapper>
    </Link>
  )
}

export default Item
