import React from 'react'
import PropTypes from 'prop-types'
import Bugsnag from '@bugsnag/js'
import EmptyState from '../EmptyState'
import {
  useTrackRenderingFail,
  FAILURE_CAUSES,
} from '../hooks/useTrackRenderingFail'

// @ts-expect-error TS(7031) FIXME: Binding element 'chartName' implicitly has an 'any... Remove this comment to see the full error message
const ErrorState = ({ chartName, channelType, message }) => {
  useTrackRenderingFail({
    chartName,
    failureCause: FAILURE_CAUSES.error,
    channelType,
  })

  return (
    <EmptyState
      header={message || 'So sorry! Something has gone a little wrong here'}
      description="If the problem persists, please contact us"
    />
  )
}

ErrorState.defaultProps = {
  chartName: '',
  channelType: '',
  message: null,
}

ErrorState.propTypes = {
  chartName: PropTypes.string,
  channelType: PropTypes.string,
  message: PropTypes.string,
}

class ErrorBoundary extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
  componentDidCatch(error) {
    Bugsnag.notify(error)
    this.setState({ hasError: true })
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'hasError' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.state.hasError || this.props.hasError) {
      return <ErrorState {...this?.props} />
    }
    // @ts-expect-error
    return this.props.children
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ErrorBoundary.defaultProps = {
  children: null,
  hasError: false,
  message: null,
  chartName: '',
  channelType: '',
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ErrorBoundary.propTypes = {
  children: PropTypes.node,
  hasError: PropTypes.bool,
  message: PropTypes.string,
  chartName: PropTypes.string,
  channelType: PropTypes.string,
}

export default ErrorBoundary
