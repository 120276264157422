import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import reactDOM from 'react-dom/server'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { color as metricsColor } from '~/shared-components/style'
import { ChartStateNoData } from '~/shared-components'
import ChartTooltip from './components/ChartTooltip'
import getChartConfig, {
  getMinorTickInterval,
  setYAxisScale,
  getMarkerFillColor,
  fadeColor,
} from '~/shared-components/utils/chartUtils/chartConfig'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

// @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
function getTimeStamp(day) {
  return dayjs.utc(Number(day)).startOf('day').valueOf()
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetrics' implicitly has an 'any' t... Remove this comment to see the full error message
function getSeriesData(dailyMetrics, index, profileService) {
  // @ts-expect-error TS(7034) FIXME: Variable 'innerSeries' implicitly has type 'any[]'... Remove this comment to see the full error message
  const innerSeries = []
  // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
  dailyMetrics.map((x) => {
    innerSeries.push({
      y: x.metrics[index].value,
      x: getTimeStamp(x.day),
      profileService,
      metricData: Object.assign({}, x.metrics[index]),
      metrics: x.metrics,
      pointPlacement: getMinorTickInterval(dailyMetrics),
    })
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'innerSeries' implicitly has an 'any[]' t... Remove this comment to see the full error message
  return innerSeries
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetrics' implicitly has an 'any' t... Remove this comment to see the full error message
function prepareSeries(dailyMetrics, profileService) {
  const series = []

  for (let i = 0; i < dailyMetrics[0].metrics.length; i++) {
    const color = dailyMetrics[0].metrics[i].color
      ? dailyMetrics[0].metrics[i].color
      : // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        metricsColor[dailyMetrics[0].metrics[i].label]
    series.push({
      marker: {
        fillColor: getMarkerFillColor(color),
        lineColor: color,
      },
      fillColor: {
        linearGradient: [0, 0, 0, 300],
        stops: [
          [0, fadeColor(color, 0.7)],
          [1, fadeColor(color, 0.4)],
        ],
      },
      lineColor: color,
      color,
      data: getSeriesData(dailyMetrics, i, profileService),
      states: {},
      name: dailyMetrics[0].metrics[i].label,
      index: i,
    })
  }

  return series
}

function getTooltip() {
  return {
    shared: true,
    crosshairs: true,
    formatter() {
      // @ts-expect-error TS(2339) FIXME: Property 'points' does not exist on type '{ shared... Remove this comment to see the full error message
      const primaryMetric = this.points[0].point
      // @ts-expect-error TS(2339) FIXME: Property 'points' does not exist on type '{ shared... Remove this comment to see the full error message
      const secondaryMetric = this.points[1].point
      return reactDOM.renderToStaticMarkup(
        <ChartTooltip
          secondaryMetric={secondaryMetric.metricData}
          {...primaryMetric}
          // @ts-expect-error TS(2339) FIXME: Property 'points' does not exist on type '{ shared... Remove this comment to see the full error message
          wording={this.points[0].point.metricData.postWording}
        />,
      )
    },
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 7,
    borderWidth: 0,
    // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    pointFormatter: () => `${this.series.name}: <b>${this.y}</b><br/>`,
    shadow: false,
    useHTML: true,
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetrics' implicitly has an 'any' t... Remove this comment to see the full error message
function prepareData(dailyMetrics, profileService) {
  // @ts-expect-error TS(2554) FIXME: Expected 4-5 arguments, but got 1.
  const config = getChartConfig(getTooltip())

  // @ts-expect-error TS(2554) FIXME: Expected 4-5 arguments, but got 0.
  config.xAxis = Object.assign({}, getChartConfig().xAxis, {
    minorTickInterval: getMinorTickInterval(dailyMetrics),
  })

  // @ts-expect-error TS(2322) FIXME: Type '{ marker: { fillColor: any; lineColor: any; ... Remove this comment to see the full error message
  config.series = prepareSeries(dailyMetrics, profileService)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
  setYAxisScale(config.series, config.xAxis, config.yAxis)

  return config
}

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
function prepareChartOptions(data, profileService, selectedMetrics) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'd' implicitly has an 'any' type.
  const dailyMetrics = data.map((d) => ({
    day: d.day,
    metrics: d.metrics.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
      (m) =>
        m.label === selectedMetrics[0].label ||
        m.label === selectedMetrics[1].label,
    ),
  }))
  if (selectedMetrics[1].label === 'Previous Period') {
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    dailyMetrics.map((item) => {
      item.metrics.push({
        label: 'Previous Period',
        value: item.metrics[0].previousValue,
        postsCount: item.metrics[0].previousPostsCount,
        color: '#9B51E0',
        postWording: 'story',
      })
    })
  }

  return prepareData(dailyMetrics, profileService)
}

const CHART_AND_FOOTER_HEIGHT = '445px'
class Chart extends PureComponent {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { data, profileService, selectedMetrics } = this.props

    if (data.length === 0) {
      return <ChartStateNoData chartName="stories-daily" />
    }

    const charOptions = prepareChartOptions(
      data,
      profileService,
      selectedMetrics,
    )

    return (
      <div
        style={{ minHeight: CHART_AND_FOOTER_HEIGHT }}
        aria-label={`This chart compares metrics against each other. 
            To use, select metrics from the button groups above. 
            You can export the data as CSV or PDF at the top of this view`}
      >
        <HighchartsReact highcharts={Highcharts} options={charOptions} />
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Chart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      metric: PropTypes.shape({
        color: PropTypes.string,
        diff: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        postsCount: PropTypes.number.isRequired,
      }),
    }),
  ).isRequired,
  profileService: PropTypes.string.isRequired,
  selectedMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default Chart
