import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import chartOptions from './chartOptions'
import Answers from '../../Answers/index'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: -12px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'answers' implicitly has an 'any' ... Remove this comment to see the full error message
const Chart = ({ answers, metricType }) => {
  const formatter = function () {
    // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    const answer = Answers.forType(this.chart.series[0].userOptions.metricType)
    // @ts-expect-error TS(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
    return answer.getChartLabel(this.value)
  }
  const options = chartOptions(
    answers[metricType].metrics,
    metricType,
    formatter,
  )

  if (metricType === 'day') {
    options.chart.type = 'column'
    options.chart.spacingRight = 10
    options.yAxis.labels.y = 5
    options.xAxis.labels.y = 25
  }

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  )
}

export default Chart
