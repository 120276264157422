import React from 'react'
import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import Tooltip from '@bufferapp/ui/Tooltip'
import { ReferralIcon } from '~/shared-components'
import { grayDarker } from '@bufferapp/ui/style/colors'
import { channelsRedirectUrl } from '~/account'

import { capitalize, isSupportedChannel } from '../utils'
import { HC_UTM_PARAMS } from '../../../../utils/constants'
import type { Channel } from '~/utils/types'

function isChannelActive(channel: Channel, profiles: Channel[]): boolean {
  return !!profiles.find((p) => p.serviceId === channel.service_id)
}

export function getInactiveChannels(
  channels: Channel[] = [],
  profiles: Channel[] = [],
): Channel[] {
  return channels.filter(
    (channel) =>
      isSupportedChannel(channel) && !isChannelActive(channel, profiles),
  )
}

const getUnsupportedChannelMessage = (channel: Channel): string => {
  const serviceType = capitalize(channel.service_type)
  const type = capitalize(channel.channel_type)
  const getChannelLabel = (): string => {
    if (channel.service_type === 'linkedin') {
      return `${serviceType} ${type}`
    }

    if (channel.service_type === 'googlebusiness') {
      return 'Google Business Profile'
    }

    return serviceType
  }

  return ` ${getChannelLabel()} is not supported so analytics cannot be shown`
}

const Separator = styled.div`
  border-bottom: dashed 1px rgba(255, 255, 255, 0.5);
  margin: 16px 0;
`

const Wrapper = styled.ol`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${grayDarker};
  list-style: none;
  padding: 0;

  &:hover li {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
`

const Item = styled.li`
  transition: opacity 150ms ease-in;
`

const CtaCopy = styled.div`
  color: rgba(255, 255, 255, 0.7);
`

const TooltipContent = styled.section`
  text-align: center;
  padding: 4px;
  font-size: 14px;
`

const SocialButton = styled.a`
  cursor: pointer;
  transition: transform 150ms ease-in;
  &:hover > div {
    transform: scale(1.2);
  }
`

interface ChannelsProps {
  channels: Channel[]
  profiles: Channel[]
}

const Channels = ({ channels, profiles }: ChannelsProps): JSX.Element => (
  <Wrapper>
    {' '}
    {channels.map((channel) => (
      <Item key={channel.service_id}>
        {/* @ts-expect-error */}
        <Tooltip
          opacity={0.9}
          size="small"
          position="top"
          customLabel={
            <TooltipContent>
              <Text type="span">
                {isSupportedChannel(channel) && (
                  <b>{channel.service_username}</b>
                )}
                {isChannelActive(channel, profiles) &&
                  isSupportedChannel(channel) &&
                  ' is connected'}
                {!isSupportedChannel(channel) &&
                  getUnsupportedChannelMessage(channel)}
                {!isChannelActive(channel, profiles) &&
                  isSupportedChannel(channel) &&
                  ' is not connected so analytics cannot be shown'}
              </Text>
              {(!isSupportedChannel(channel) ||
                !isChannelActive(channel, profiles)) && (
                <CtaCopy>
                  <Separator />
                  <Text type="span">
                    {!isSupportedChannel(channel) && 'Click to learn more'}
                    {!isChannelActive(channel, profiles) &&
                      isSupportedChannel(channel) &&
                      'Click to connect'}
                  </Text>
                </CtaCopy>
              )}
            </TooltipContent>
          }
        >
          <SocialButton
            href={
              !isSupportedChannel(channel)
                ? `https://support.buffer.com/article/535-tracking-the-performance-of-your-campaigns?${HC_UTM_PARAMS}`
                : channelsRedirectUrl()
            }
            target={!isSupportedChannel(channel) ? '_blank' : '_self'}
          >
            <ReferralIcon
              item={{ source: channel.service_type }}
              size="medium"
              inactive={
                !isChannelActive(channel, profiles) ||
                !isSupportedChannel(channel)
              }
              circular
              inline
            />
          </SocialButton>
        </Tooltip>
      </Item>
    ))}
  </Wrapper>
)

export default Channels
