import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes as dateActionTypes } from '~/date-picker'
import {
  actionTypes as exportActionTypes,
  actions as exportActions,
} from '~/png-export'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'
import { actionTypes as hashtagsActionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'hashtags' implicitly has an 'any'... Remove this comment to see the full error message
const formatDataForCSVExport = ({ hashtags, labels }) => {
  if (!hashtags.length) {
    return null
  }
  const primaryMetricLabel = labels.primary_metric
  const secondaryMetricLabel = labels.secondary_metric
  const data = {
    hashtags: [],
    'Posts count': [],
    [primaryMetricLabel]: [],
    [secondaryMetricLabel]: [],
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'hashtag' implicitly has an 'any' type.
  hashtags.forEach((hashtag) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data.hashtags.push(hashtag.display_name)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data['Posts count'].push(hashtag.posts_count)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data[primaryMetricLabel].push(hashtag.primary_metric)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data[secondaryMetricLabel].push(hashtag.secondary_metric)
  })

  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  switch (action.type) {
    case profileActionTypes.SELECT_PROFILE:
      if (action.profile.service !== 'linkedin') {
        dispatch(
          actions.fetch({
            name: 'hashtags',
            args: {
              profileId: action.profile.id,
              profileService: action.profile.service,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
            },
          }),
        )
      }
      break
    case hashtagsActionTypes.FETCH:
    case profileActionTypes.REFRESH_PROFILES:
      if (getState().profiles.selectedProfileService !== 'linkedin') {
        if (getState().profiles.selectedProfileId) {
          dispatch(
            actions.fetch({
              name: 'hashtags',
              args: {
                profileId: getState().profiles.selectedProfileId,
                profileService: getState().profiles.selectedProfile.service,
                startDate: getState().date.startDate,
                endDate: getState().date.endDate,
              },
            }),
          )
        }
      }
      break
    case dateActionTypes.SET_DATE_RANGE:
      if (getState().profiles.selectedProfileId) {
        if (getState().profiles.selectedProfile.service !== 'linkedin') {
          dispatch(
            actions.fetch({
              name: 'hashtags',
              args: {
                profileId: getState().profiles.selectedProfileId,
                profileService: getState().profiles.selectedProfile.service,
                startDate: action.startDate,
                endDate: action.endDate,
              },
            }),
          )
        }
      }
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart('js-dom-to-png-hashtags', 'posts-hashtags'),
      )
      break
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      dispatch(
        exportCSVActions.exportChart(
          'posts-hashtags',
          formatDataForCSVExport(getState().hashtags),
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
