import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import styled from 'styled-components'
import getChartConfig, { highChartsSeriesPrimaryConfig } from './chartConfig'
import { blueLight, blueDarker } from '@bufferapp/ui/style/colors'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetric' implicitly has an 'any' ty... Remove this comment to see the full error message
function getMinorTickInterval(dailyMetric) {
  const oneDay = 24 * 3600 * 1000
  const moreThanAMonth = dailyMetric.length > 31

  return moreThanAMonth ? null : oneDay
}

function prepareSeries(
  // @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetric' implicitly has an 'any' ty... Remove this comment to see the full error message
  dailyMetric,
  // @ts-expect-error TS(7006) FIXME: Parameter 'timezone' implicitly has an 'any' type.
  timezone,
  // @ts-expect-error TS(7006) FIXME: Parameter 'visualizePreviousPeriod' implicitly has... Remove this comment to see the full error message
  visualizePreviousPeriod,
  // @ts-expect-error TS(7006) FIXME: Parameter 'profileService' implicitly has an 'any'... Remove this comment to see the full error message
  profileService,
  isPreviousPeriod = false,
) {
  const color = blueLight
  const previousPeriodColor = blueDarker
  const paidColor = '#00C8CF'
  const previousPeriodPaidColor = '#008387'
  // @ts-expect-error TS(7034) FIXME: Variable 'paidData' implicitly has type 'any[]' in... Remove this comment to see the full error message
  const paidData = []
  const organicData = Array.from(dailyMetric, (day) => {
    let value = 0
    let paidValue = null
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    if (day.metric) {
      if (isPreviousPeriod) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        value = day.metric.previousValue
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (day.metric.paid) {
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          paidValue = day.metric.paid.previousValue
        }
      } else {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        value = day.metric.value
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (day.metric.paid) {
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          paidValue = day.metric.paid.value
        }
      }
    }

    const dayStartTimestamp = dayjs
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      .utc(Number(day.day))
      .startOf('day')
      .valueOf()
    const previousPeriodDay = dayjs
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      .utc(Number(day.previousPeriodDay))
      .startOf('day')
      .valueOf()

    const organicDayData = {
      x: dayStartTimestamp,
      y: value,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      metricData: Object.assign({}, day.metric, {
        previousPeriodDay,
        profileService,
        timezone,
        visualizePreviousPeriod,
      }),
      pointPlacement: getMinorTickInterval(dailyMetric),
    }

    if (paidValue) {
      paidData.push({
        ...organicDayData,
        y: paidValue,
      })
    }
    return organicDayData
  })

  const organicSeries = Object.assign({}, highChartsSeriesPrimaryConfig, {
    marker: {
      fillColor: isPreviousPeriod ? previousPeriodColor : color,
      lineColor: 'white',
    },
    fillColor: isPreviousPeriod ? 'rgba(0,0,0,0)' : color,
    lineColor: isPreviousPeriod ? previousPeriodColor : 'white',
    lineWidth: isPreviousPeriod ? 5 : 2,
    data: organicData,
    stack: isPreviousPeriod ? 'previous period' : 'current period',
    cursor: 'pointer',
  })

  const paidSeries = Object.assign({}, highChartsSeriesPrimaryConfig, {
    marker: {
      fillColor: isPreviousPeriod ? previousPeriodPaidColor : paidColor,
      lineColor: 'white',
    },
    fillColor: isPreviousPeriod ? 'rgba(0,0,0,0)' : paidColor,
    lineColor: isPreviousPeriod ? previousPeriodColor : 'white',
    lineWidth: isPreviousPeriod ? 5 : 2,
    // @ts-expect-error TS(7005) FIXME: Variable 'paidData' implicitly has an 'any[]' type... Remove this comment to see the full error message
    data: paidData,
    stack: isPreviousPeriod ? 'previous period' : 'current period',
    cursor: 'pointer',
  })

  if (
    dailyMetric[0].metric &&
    dailyMetric[0].metric.label != 'Total Followers' &&
    dailyMetric[0].metric.label != 'New Followers' &&
    dailyMetric[0].metric.label != 'Total Fans'
  ) {
    // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'never'.
    organicSeries.type = 'column'
    // @ts-expect-error TS(2339) FIXME: Property 'colors' does not exist on type 'never'.
    organicSeries.colors = isPreviousPeriod ? [previousPeriodColor] : [color]
    // @ts-expect-error TS(2339) FIXME: Property 'borderColor' does not exist on type 'nev... Remove this comment to see the full error message
    organicSeries.borderColor = 'white'

    // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'never'.
    paidSeries.type = 'column'
    // @ts-expect-error TS(2339) FIXME: Property 'colors' does not exist on type 'never'.
    paidSeries.colors = isPreviousPeriod
      ? [previousPeriodPaidColor]
      : [paidColor]
    // @ts-expect-error TS(2339) FIXME: Property 'borderColor' does not exist on type 'nev... Remove this comment to see the full error message
    paidSeries.borderColor = 'white'
  }

  return [organicSeries, paidSeries]
}

// @ts-expect-error TS(7031) FIXME: Binding element 'series' implicitly has an 'any' t... Remove this comment to see the full error message
function setChartLimits({ series, yAxis }) {
  // @ts-expect-error TS(7034) FIXME: Variable 'values' implicitly has type 'any[]' in s... Remove this comment to see the full error message
  let values = []
  // @ts-expect-error TS(7006) FIXME: Parameter 's' implicitly has an 'any' type.
  const reducedSeries = series.map((s) =>
    // @ts-expect-error TS(7006) FIXME: Parameter 'point' implicitly has an 'any' type.
    s.data.map((point) => {
      // because the values are stacked, we will need to add up paid and organic
      if ('metricData' in point) {
        if ('paid' in point.metricData) {
          return point.y + point.metricData.paid.value
        }
      }

      return point.y
    }),
  )
  // @ts-expect-error TS(7006) FIXME: Parameter 's' implicitly has an 'any' type.
  reducedSeries.forEach((s) => {
    // @ts-expect-error TS(7005) FIXME: Variable 'values' implicitly has an 'any[]' type.
    values = values.concat(s)
  })
  // @ts-expect-error TS(7005) FIXME: Variable 'values' implicitly has an 'any[]' type.
  let min = Math.min.apply(null, values)
  // @ts-expect-error TS(7005) FIXME: Variable 'values' implicitly has an 'any[]' type.
  let max = Math.max.apply(null, values)

  if (min > 0 && max > 0) {
    const maxPaddingPercentage = 5.25
    const minPaddingPercentage = 0.1

    let topPaddingPercentage = maxPaddingPercentage - Math.log10(max)
    if (topPaddingPercentage < minPaddingPercentage) {
      topPaddingPercentage = minPaddingPercentage
    }

    let bottomPaddingPercentage = maxPaddingPercentage - Math.log10(min)
    if (bottomPaddingPercentage < minPaddingPercentage) {
      bottomPaddingPercentage = minPaddingPercentage
    }

    min -= (min / 100) * bottomPaddingPercentage
    max += (max / 100) * topPaddingPercentage

    yAxis[0].floor = min
    yAxis[0].ceiling = max
  } else {
    yAxis[0].floor = min
    yAxis[0].ceiling = max
    delete yAxis[0].min
  }
}

function prepareChartOptions(
  // @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetric' implicitly has an 'any' ty... Remove this comment to see the full error message
  dailyMetric,
  // @ts-expect-error TS(7006) FIXME: Parameter 'timezone' implicitly has an 'any' type.
  timezone,
  // @ts-expect-error TS(7006) FIXME: Parameter 'visualizePreviousPeriod' implicitly has... Remove this comment to see the full error message
  visualizePreviousPeriod,
  // @ts-expect-error TS(7006) FIXME: Parameter 'profileService' implicitly has an 'any'... Remove this comment to see the full error message
  profileService,
) {
  const config = getChartConfig()

  // @ts-expect-error TS(2339) FIXME: Property 'minorTickInterval' does not exist on typ... Remove this comment to see the full error message
  config.xAxis.minorTickInterval = getMinorTickInterval(dailyMetric)
  let series = [
    ...prepareSeries(
      dailyMetric,
      timezone,
      visualizePreviousPeriod,
      profileService,
    ),
  ]
  if (visualizePreviousPeriod) {
    series = series.concat(
      ...prepareSeries(
        dailyMetric,
        timezone,
        visualizePreviousPeriod,
        profileService,
        true,
      ),
    )
  }
  config.series = series
  setChartLimits(config)
  return config
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyData' implicitly has an 'any' type... Remove this comment to see the full error message
function filterDailyDataMetrics(dailyData, metricLabel) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
  return dailyData.map((day) => ({
    day: day.day,
    previousPeriodDay: day.previousPeriodDay,
    metric: day.metrics
      // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
      .filter((metric) => metric.label === metricLabel)
      .shift(),
  }))
}

const Container = styled.div``

class Chart extends PureComponent {
  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'daily' does not exist on type 'Readonly<... Remove this comment to see the full error message
      daily,
      // @ts-expect-error TS(2339) FIXME: Property 'selectedMetricLabel' does not exist on t... Remove this comment to see the full error message
      selectedMetricLabel,
      // @ts-expect-error TS(2339) FIXME: Property 'timezone' does not exist on type 'Readon... Remove this comment to see the full error message
      timezone,
      // @ts-expect-error TS(2339) FIXME: Property 'visualizePreviousPeriod' does not exist ... Remove this comment to see the full error message
      visualizePreviousPeriod,
      // @ts-expect-error TS(2339) FIXME: Property 'profileService' does not exist on type '... Remove this comment to see the full error message
      profileService,
    } = this.props
    const filteredDailyData = filterDailyDataMetrics(daily, selectedMetricLabel)
    const charOptions = prepareChartOptions(
      filteredDailyData,
      timezone,
      visualizePreviousPeriod,
      profileService,
    )
    return (
      <Container>
        <HighchartsReact highcharts={Highcharts} options={charOptions} />
      </Container>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Chart.propTypes = {
  daily: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      previousPeriodDay: PropTypes.string.isRequired,
      metric: PropTypes.shape({
        color: PropTypes.string.isRequired,
        diff: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        previousValue: PropTypes.number.isRequired,
        postsCount: PropTypes.number.isRequired,
      }),
    }),
  ).isRequired,
  profileService: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  visualizePreviousPeriod: PropTypes.bool,
  selectedMetricLabel: PropTypes.string,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Chart.defaultProps = {
  visualizePreviousPeriod: false,
  selectedMetricLabel: '',
}

export default Chart
