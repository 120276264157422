import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Image, geyser } from '@buffer-mono/legacy-bufferapp-components'

import SocialIcon from '../SocialIcon'

const avatarSize = 26
const AvatarWithAuraSize = 34
const socialIconSize = 14

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const IconAura = ({ children, className }) => (
  <span className={className}>{children}</span>
)

IconAura.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
}

const IconAuraStyled = styled(IconAura)`
  width: 30px;
  height: 30px;
  border: 2px solid
    ${(props) =>
      // @ts-expect-error TS(2339) FIXME: Property 'color' does not exist on type 'ThemedSty... Remove this comment to see the full error message
      props.color};
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const ProfileIcon = ({ className, profile, color }) => (
  <span className={className}>
    {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
    <IconAuraStyled color={color}>
      <Image
        border={'circle'}
        src={profile.avatarUrl}
        height={`${avatarSize}px`}
        width={`${avatarSize}px`}
      />
    </IconAuraStyled>
    <SocialIcon
      service={profile.service}
      socialIconSize={socialIconSize}
      avatarSize={AvatarWithAuraSize}
      withBorder
    />
  </span>
)

ProfileIcon.propTypes = {
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
  profile: PropTypes.shape({
    username: PropTypes.string,
    service: PropTypes.string,
  }).isRequired,
}

ProfileIcon.defaultProps = {
  color: geyser,
}

const ProfileIconStyled = styled(ProfileIcon)`
  position: relative;
`

export default ProfileIconStyled
