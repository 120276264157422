import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { actionTypes } from './reducer'

dayjs.extend(utc)
dayjs.extend(timezone)

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch } = store
  const startDate = dayjs()
    .startOf('day')
    .subtract(7, 'days')
    .format('MM/DD/YYYY')
  const endDate = dayjs()
    .startOf('day')
    .subtract(1, 'days')
    .format('MM/DD/YYYY')

  switch (action.type) {
    case profileActionTypes.REFRESH_PROFILES:
    case actionTypes.FETCH: {
      dispatch(
        actions.fetch({
          name: 'aggregates_daily',
          args: {
            profiles: store
              .getState()
              // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
              .profiles.socialProfiles.map(({ id }) => id),
            startDate,
            endDate,
          },
        }),
      )
      break
    }
    default:
      break
  }
  return next(action)
}
