import { connect } from 'react-redux'
import AggregatedMetric from './components/AggregatedMetric'

// default export = container
export default connect((state) => ({
  // @ts-expect-error TS(2339) FIXME: Property 'aggregates' does not exist on type 'Defa... Remove this comment to see the full error message
  loading: state.aggregates.loading,
  // @ts-expect-error TS(2339) FIXME: Property 'aggregates' does not exist on type 'Defa... Remove this comment to see the full error message
  ...state.aggregates.metrics,
  // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
  profiles: state.profiles.socialProfiles.map((profile) => profile.id),
}))(AggregatedMetric)

// export reducer, actions and action types
export { default as Table } from './components/Total'
export { Title } from './components/AggregatedMetric'
