import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
import {
  PRO_PLAN,
  PREMIUM_PLAN,
  PERMISSIONS_BY_PLAN,
  PERMISSIONS_BY_FEATURE_FLAG,
} from './constants'

export const actionTypes = keyWrapper('ACCOUNT', {
  OPEN_SETTINGS: 'OPEN_SETTINGS',
  GRANT_PREMIUM_FEATURES: 'GRANT_PREMIUM_FEATURES',
})

const initialState = {
  hasError: false,
  loading: true,
  permissions: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'planId' implicitly has an 'any' type.
const getPlan = (planId) =>
  planId.match(/\bpro\b/gi) ? PRO_PLAN : PREMIUM_PLAN

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `profiles_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        isOwner: action.result[0] ? action.result[0].isOwner : false,
      }
    case actionTypes.GRANT_PREMIUM_FEATURES:
      return {
        ...state,
        permissions: [
          ...state.permissions,
          ...PERMISSIONS_BY_PLAN[PREMIUM_PLAN],
        ],
      }
    case `user_${asyncDataFetchActionTypes.FETCH_SUCCESS}`: {
      const featureFlipPermissions = action.result.featureFlips.map(
        // @ts-expect-error TS(7006) FIXME: Parameter 'flag' implicitly has an 'any' type.
        (flag) => PERMISSIONS_BY_FEATURE_FLAG[flag],
      )
      if (state.permissions) {
        return {
          ...state,
          permissions: [...featureFlipPermissions.flat(), ...state.permissions],
        }
      } else {
        return {
          ...state,
          permissions: [...featureFlipPermissions.flat()],
        }
      }
    }
    default:
      return state
  }
}

export const actions = {
  openSettings: () => ({
    type: actionTypes.OPEN_SETTINGS,
  }),
  grantPremiumFeatures: () => ({
    type: actionTypes.GRANT_PREMIUM_FEATURES,
  }),
}
