import React, { useState } from 'react'
import styled from 'styled-components'
import ClockIcon from '@bufferapp/ui/Icon/Icons/Clock'
import {
  facebook,
  instagram,
  twitter,
  linkedin,
} from '@bufferapp/ui/style/colors'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'
import Button from '@bufferapp/ui/Button'
import { lightSlate, Text } from '@buffer-mono/legacy-bufferapp-components'
import { PostMedia, ContentModal } from '~/shared-components'

import XIcon from '@bufferapp/ui/Icon/Icons/X'
import FacebookIcon from '@bufferapp/ui/Icon/Icons/Facebook'
import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import LinkedInIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'

import Metric from './Metric'
dayjs.extend(relativeTime)

const Stat = styled.li`
  list-style: none;

  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    height: 12px;
    width: 12px;
  }
`

const Stats = styled.ul`
  padding: 1.25rem 1rem;
  margin: 0;

  > ${Stat}:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const BasePost = styled.div`
  border-radius: 3px;
  flex-grow: 1;
  margin: 0 4px;
  width: calc(20% - 8px);
`

export const Placeholder = styled(BasePost)`
  background: linear-gradient(180deg, #ededed 0%, rgba(0, 0, 0, 0) 100%);
  height: 295px;
`

const StyledPost = styled(BasePost)`
  border: 1px solid #cfd7df;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02); */
  position: relative;
`

const ViewPost = styled.span`
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    opacity: 1;
  }
`

const DetailsLink = styled.div`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  margin: 0 0.75rem;
`

const Header = styled.header`
  display: flex;
  padding: 1rem;
  align-items: center;

  svg {
    margin-right: 5px;
    height: 16px;
    width: 16px;
  }
`

const PostMediaContainer = styled.div`
  width: 100%;
  height: 160px;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
function getReach(post) {
  let reach = 0
  switch (post.profile.service) {
    case 'twitter':
    case 'linkedin':
      reach = post.impressions
      break
    case 'facebook':
      reach = post.reach
      break
    default:
      reach = post.reach
      break
  }

  return numeral(reach).format('0,0')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
function getEngagementRate(post) {
  if (post.profile.service === 'linkedin') {
    return `${numeral(post.engagementRate).format('0.00')}%`
  }

  return `${numeral(post.engagement_rate).format('0.00')}%`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
function formatPostForModal(post) {
  const formattedPost = {
    details: [
      {
        ...post,
      },
    ],
  }

  return [formattedPost]
}

interface PostProps {
  post: any
}

const Post = ({ post }: PostProps): JSX.Element => {
  const [postForModal, setPostForModal] = useState(null)
  return (
    <React.Fragment>
      {postForModal && (
        <ContentModal
          posts={formatPostForModal(postForModal)}
          closeModal={(): void => {
            setPostForModal(null)
          }}
        />
      )}
      <StyledPost>
        <Header>
          {post.profile.service === 'twitter' && <XIcon color={twitter} />}
          {post.profile.service === 'facebook' && (
            <FacebookIcon color={facebook} />
          )}
          {post.profile.service === 'instagram' && (
            <InstagramIcon color={instagram} />
          )}
          {post.profile.service === 'linkedin' && (
            <LinkedInIcon color={linkedin} />
          )}
          <Text weight="bold" size="small">
            {post.profile.username.length > 12
              ? `${post.profile.username.substring(0, 12)}...`
              : post.profile.username}
          </Text>
        </Header>
        <DetailsLink>
          <PostMediaContainer>
            <PostMedia post={post} />
          </PostMediaContainer>
          <ViewPost>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size... Remove this comment to see the full error message */}
            <Button
              type="secondary"
              size="small"
              onClick={() => {
                setPostForModal(post)
              }}
              label="View post"
            />
          </ViewPost>
        </DetailsLink>
        <Stats>
          <Stat>
            <Metric small label="Reach" name="reach" value={getReach(post)} />
          </Stat>
          <Stat>
            <Metric
              small
              label="Eng. Rate"
              name="engagement_rate"
              value={getEngagementRate(post)}
            />
          </Stat>
          <Stat>
            <ClockIcon color={lightSlate} />
            <Text color="lightSlate" weight="bold" size="small">
              {dayjs(post.date).fromNow()}
            </Text>
          </Stat>
        </Stats>
      </StyledPost>
    </React.Fragment>
  )
}
export default Post
