import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { actionTypes } from './actions'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export function getOrganizationIdFromStore(store) {
  let organizationId = null
  const state = store.getState()
  // This is used to get the organizationId from the user context exposed by the App-Shell
  if (
    // @ts-expect-error TS(2339) FIXME: Property '__userData' does not exist on type 'Wind... Remove this comment to see the full error message
    window.__userData &&
    // @ts-expect-error TS(2339) FIXME: Property '__userData' does not exist on type 'Wind... Remove this comment to see the full error message
    window.__userData.data &&
    // @ts-expect-error TS(2339) FIXME: Property '__userData' does not exist on type 'Wind... Remove this comment to see the full error message
    window.__userData.data.account &&
    // @ts-expect-error TS(2339) FIXME: Property '__userData' does not exist on type 'Wind... Remove this comment to see the full error message
    window.__userData.data.account.currentOrganization &&
    // @ts-expect-error TS(2339) FIXME: Property '__userData' does not exist on type 'Wind... Remove this comment to see the full error message
    window.__userData.data.account.currentOrganization.id
  ) {
    // @ts-expect-error TS(2339) FIXME: Property '__userData' does not exist on type 'Wind... Remove this comment to see the full error message
    organizationId = window.__userData.data.account.currentOrganization.id
  } else if (state.profiles && state.profiles.organizationId) {
    organizationId = store.getState().profiles.organizationId
  }
  return organizationId
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const organizationId = getOrganizationIdFromStore(store)

  switch (action.type) {
    case actionTypes.TRACK_EVENT:
      if (window.analytics) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        BufferTracker[action.eventName]({
          ...action.payload,
          product: window.PRODUCT_TRACKING_KEY,
          organizationId,
        })
      }
      break
    case actionTypes.PAGE_CHANGE:
      if (window.analytics) {
        BufferTracker.pageViewed({
          path: window.location.pathname || null,
          ...action.payload,
          product: window.PRODUCT_TRACKING_KEY,
          organizationId,
        })
      }
      break
    default:
      break
  }
  return next(action)
}
