import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes } from './reducer'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'
import {
  actions as exportActions,
  actionTypes as exportActionTypes,
} from '~/png-export'
import dayjs from 'dayjs'
import striptags from 'striptags'
import { actionTypes as campaignSelectorActionTypes } from '~/campaign-selector/reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'totals' implicitly has an 'any' type.
const formatDataForCSVExportTotals = (totals) => {
  if (!totals || totals.length === 0) return {}
  const data = {
    metric: [],
    value: [],
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  totals.map((metric) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data.metric.push(metric.label)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data.value.push(metric.value)
  })

  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'dailyMetrics' implicitly has an 'any' t... Remove this comment to see the full error message
const formatDataForCSVExportDailyMetrics = (dailyMetrics) => {
  if (!dailyMetrics || dailyMetrics.length === 0) return {}
  const data = {
    date: [],
    metric: [],
    channel: [],
    value: [],
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
  dailyMetrics.map((m) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'd' implicitly has an 'any' type.
    m.dailyValues.map((d) => {
      // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
      d.metrics.map((channel) => {
        const formattedDay = dayjs(d.day, 'x').format('MM/DD/YYYY')

        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        data.metric.push(m.metric)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        data.date.push(formattedDay)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        data.channel.push(channel.label)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        data.value.push(channel.value)
      })
    })
  })

  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
export const escapeText = (text) => {
  const escapedText = striptags(text, [], '\n').replace(/\n+/gm, ' ')
  return `"${escapedText}"`
}

const STATISTICS = [
  'engagement',
  'engagement_rate',
  'impressions',
  'likes',
  'reach',
  'saves',
  'clicks',
  'comments',
  'reactions',
  'shares',
  'replies',
  'favorites',
  'retweets',
]

// @ts-expect-error TS(7006) FIXME: Parameter 'posts' implicitly has an 'any' type.
export const formatDataForCSVExportPosts = (posts) => {
  if (!posts || posts.length === 0) return {}

  // @ts-expect-error TS(7034) FIXME: Variable 'listOfPosts' implicitly has type 'any[]'... Remove this comment to see the full error message
  const listOfPosts = []
  // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
  posts.map((day) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
    day.posts.map((channel) => {
      // @ts-expect-error TS(7006) FIXME: Parameter 'singlePost' implicitly has an 'any' typ... Remove this comment to see the full error message
      channel.details.map((singlePost) => {
        if (!singlePost) return
        listOfPosts.push(singlePost)
      })
    })
  })

  const data = {
    date: [],
    service: [],
    username: [],
    serviceLink: [],
    type: [],
    text: [],
  }

  // @ts-expect-error TS(7005) FIXME: Variable 'listOfPosts' implicitly has an 'any[]' t... Remove this comment to see the full error message
  listOfPosts.map((post) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    data.date.push(dayjs(post.date).format('MM/DD/YYYY HH:mm:ss'))
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data.type.push(post.type)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    data.text.push(escapeText(post.text))
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data.serviceLink.push(post.serviceLink)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data.service.push(post.profile.service)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    data.username.push(post.profile.username)

    STATISTICS.map((stat) => {
      let value = post.statistics[stat]
      // twitter uses engagements so we're adding it to engagement
      if (stat === 'engagement' && !value) {
        value = post.statistics.engagements
      }
      // twitter uses url_clicks so we're adding it to clicks
      if (stat === 'clicks' && !value) {
        value = post.statistics.url_clicks
      }
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!data[stat]) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[stat] = []
      }
      if (!value) value = 0
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[stat].push(value)
    })
  })

  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const state = getState()
  switch (action.type) {
    case actionTypes.FETCH:
      dispatch(
        actions.fetch({
          name: 'campaign_posts_metrics',
          args: {
            campaignId: state.campaignSelector.selectedCampaign.id,
          },
        }),
      )
      break
    case campaignSelectorActionTypes.SELECT:
      dispatch(
        actions.fetch({
          name: 'campaign_posts_metrics',
          args: {
            campaignId: action.id,
          },
        }),
      )
      break
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      dispatch(
        exportCSVActions.exportChart(
          'campaign-posts-metrics-totals',
          formatDataForCSVExportTotals(getState().campaignPostsMetrics.totals),
        ),
      )
      dispatch(
        exportCSVActions.exportChart(
          'campaign-posts-metrics-dailyMetrics',
          formatDataForCSVExportDailyMetrics(
            getState().campaignPostsMetrics.dailyMetrics,
          ),
        ),
      )
      dispatch(
        exportCSVActions.exportChart(
          'campaign-posts-metrics-dailyPosts',
          formatDataForCSVExportPosts(
            getState().campaignPostsMetrics.dailyPosts,
          ),
        ),
      )
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-campaign-posts-metrics',
          'campaign-posts-metrics',
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
