import { LOCATION_CHANGE } from 'connected-react-router'
import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as reportListActionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  switch (action.type) {
    case reportListActionTypes.FETCH: {
      store.dispatch(
        actions.fetch({
          name: 'list_reports',
          args: {
            organizationId: action.id,
          },
        }),
      )
      break
    }
    case LOCATION_CHANGE:
      if (action.payload.location.pathname === '/reports') {
        store.dispatch(
          actions.fetch({
            name: 'list_reports',
            args: {
              organizationId: store.getState().profiles.organizationId,
            },
          }),
        )
      }
      break
    case reportListActionTypes.REMOVE_REPORT:
      store.dispatch(
        actions.fetch({
          name: 'remove_report',
          args: {
            id: action.id,
            organizationId: store.getState().profiles.organizationId,
          },
        }),
      )
      break
    case 'ORGANIZATION_CHANGED':
      store.dispatch(
        actions.fetch({
          name: 'list_reports',
          args: {
            organizationId: action.organizationId,
          },
        }),
      )
      break
    default:
      break
  }
  return next(action)
}
