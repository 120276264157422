import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'
import MediaIcon from '../../MediaIcon'
import ReactPlayer from 'react-player'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const Media = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  background-image: url(${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'thumbnail' does not exist on type 'Theme... Remove this comment to see the full error message
    props.thumbnail});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const Empty = styled.div`
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
`

const TypeIcon = styled.div`
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  position: absolute;
  right: 10px;
  bottom: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MediaContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
const Attachment = ({ type, media }) => {
  function getThumbnail() {
    if (type === 'video') {
      return (
        <MediaContainer>
          {String(media.thumbnail).match(/\.mp4/) && (
            <ReactPlayer
              url={media.thumbnail}
              width="200%"
              height="200%"
              mute
              style={{
                display: 'flex',
                background: '#F5F5F5',
                width: '200%',
                height: '200%',
                position: 'relative',
                top: '-50%',
                left: '-50%',
              }}
            />
          )}
          {!String(media.resizedThumbnail).match(/\.mp4/) && (
            <Media
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              thumbnail={`${media.resizedThumbnail}`}
            />
          )}
          <TypeIcon>
            <MediaIcon type={type} />
          </TypeIcon>
        </MediaContainer>
      )
    }
    if (type === 'carousel_album') {
      return (
        <MediaContainer>
          <Media
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            thumbnail={`${media.resizedThumbnail}`}
          />
          <TypeIcon>
            <MediaIcon type={type} />
          </TypeIcon>
        </MediaContainer>
      )
    }

    return media.thumbnail ? (
      <Media
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        thumbnail={`${media.resizedThumbnail}`}
      />
    ) : (
      <Empty>
        <Text color="lightSlate">No media</Text>
      </Empty>
    )
  }

  return <Container>{getThumbnail()}</Container>
}

Attachment.defaultProps = {
  media: null,
}

Attachment.propTypes = {
  type: PropTypes.string.isRequired,
  media: PropTypes.shape({
    // @ts-expect-error TS(2551) FIXME: Property 'String' does not exist on type 'typeof i... Remove this comment to see the full error message
    thumbnail: PropTypes.String,
    // @ts-expect-error TS(2551) FIXME: Property 'String' does not exist on type 'typeof i... Remove this comment to see the full error message
    picture: PropTypes.String,
    // @ts-expect-error TS(2551) FIXME: Property 'String' does not exist on type 'typeof i... Remove this comment to see the full error message
    resizedThumbnail: PropTypes.String,
  }),
}

export default Attachment
