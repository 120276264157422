import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('STORIES_BREAKDOWN', {
  FETCH: 'FETCH',
  SORT_BY: 'SORT_BY',
  SELECT_DAY: 'SELECT_DAY',
})

export const sortableAttributes = {
  REACH: 'reach',
  ENGAGEMENT_RATE: 'engagementRate',
  DATE: 'date',
}

export const ALL_DAYS = 'ALL'

const initialState = {
  loading: true,
  stories: [],
  selectedStories: [],
  sortBy: sortableAttributes.DATE,
  selectedDay: ALL_DAYS,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `stories_breakdown_${asyncDataFetchActionTypes.FETCH_START}`:
      return {
        ...state,
        loading: true,
      }
    case `stories_breakdown_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        loading: false,
        stories: action.result,
      }
    case actionTypes.SORT_BY:
      return {
        ...state,
        sortBy: action.attribute,
      }
    case actionTypes.SELECT_DAY:
      return {
        ...state,
        selectedDay: action.day,
      }
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'attribute' implicitly has an 'any' type... Remove this comment to see the full error message
  sortBy: (attribute) => ({
    type: actionTypes.SORT_BY,
    attribute,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'day' implicitly has an 'any' type.
  selectDay: (day) => ({
    type: actionTypes.SELECT_DAY,
    day,
  }),
}
