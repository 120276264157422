import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'
import { ProfileBadge, ShopifyLogo } from '~/shared-components'

const Profile = styled.span`
  position: relative;
`

const Legend = styled.span`
  display: flex;
  align-items: center;
`

const ProfileText = styled.div`
  margin: -1px 0 0 -4px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
const ProfileLegend = ({ profile }) => (
  <Legend>
    {profile.service !== 'shopify' && (
      <ProfileBadge
        avatarUrl={profile.avatarUrl || profile.service_avatar}
        service={profile.service}
      />
    )}
    {profile.service === 'shopify' && <ShopifyLogo small />}
    <ProfileText>
      <Text color="outerSpace" weight="medium" size="mini">
        <Profile>{profile.username || profile.service_username}</Profile>
      </Text>
    </ProfileText>
  </Legend>
)

ProfileLegend.propTypes = {
  profile: PropTypes.shape({
    service: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
}

export default ProfileLegend
