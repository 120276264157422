import { actionTypes as fetchActions } from '@buffer-mono/async-data-fetch'
import { actionTypes } from './reducer'
import { env } from '~/env'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const state = store.getState()

  switch (action.type) {
    case `user_${fetchActions.FETCH_FAIL}`:
      if (action.error.match(/unauthorized/i)) {
        if (env.VITE_NODE_ENV === 'development') {
          console.error('unauthorized')
          return
        }
        window.location.href = 'https://login.buffer.com'
      } else {
        console.error(action.error)
      }
      break

    case actionTypes.OPEN_SETTINGS:
      if (state.appSidebar.user.productlinks) {
        window.open('https://account.buffer.com')
      } else window.open('https://buffer.com/manage/analyze')
      break

    default:
      break
  }
  return next(action)
}
