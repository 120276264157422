import React from 'react'
import { Loader } from '@buffer-mono/legacy-bufferapp-components'
import styled, { css } from 'styled-components'
import { useAccount } from '~/account'

import redirect from './redirect'

export const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'regularIcon' does not exist on type 'The... Remove this comment to see the full error message
    !props.regularIcon &&
    css`
      svg {
        width: 32px !important;
        height: 32px !important;
      }
    `}
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const CanAccess = ({ children }) => {
  const account = useAccount()

  // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type '{ curre... Remove this comment to see the full error message
  if (account.loading) {
    return (
      <Center>
        <Loader />
      </Center>
    )
    // @ts-expect-error TS(2339) FIXME: Property 'billing' does not exist on type '{}'.
  } else if (!account?.currentOrganization?.billing?.canAccessAnalytics) {
    // @ts-expect-error TS(2339) FIXME: Property 'isOneBufferOrganization' does not exist ... Remove this comment to see the full error message
    if (account.currentOrganization.isOneBufferOrganization) {
      return <>{children}</>
    }
    redirect.toMigrationHub()
  }

  return children
}

export default CanAccess
