import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '~/shared-components'
import { Text } from '@buffer-mono/legacy-bufferapp-components'
import { Can, Cant, EXPORT } from '~/account'
import UpgradeToPro from '~/upgrade-to-pro'

const Margin = styled.span`
  margin-left: 0.5rem;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'exportToPDF' implicitly has an 'a... Remove this comment to see the full error message
const PDFExportButton = ({ exportToPDF, ...restProps }) => {
  const [showUpgrade, setShowUpgrade] = useState(false)
  return (
    <React.Fragment>
      <Margin>
        {/* @ts-expect-error TS(2745) FIXME: This JSX tag's 'children' prop expects type 'never... Remove this comment to see the full error message */}
        <Can {...{ EXPORT }}>
          <Button {...restProps} onClick={() => exportToPDF()}>
            <Text size="small" weight="medium" color="outerSpace">
              Export as PDF
            </Text>
          </Button>
        </Can>
        {/* @ts-expect-error TS(2745) FIXME: This JSX tag's 'children' prop expects type 'never... Remove this comment to see the full error message */}
        <Cant {...{ EXPORT }}>
          <Button
            onClick={() => {
              setShowUpgrade(true)
            }}
          >
            <Text size="small" weight="medium" color="outerSpace">
              Export as PDF
            </Text>
          </Button>
        </Cant>
      </Margin>
      {showUpgrade && (
        <UpgradeToPro
          hide={() => {
            setShowUpgrade(false)
          }}
          title="Unlock Reports Export"
          description="Upgrade to get the most out of Buffers Analytics, and unlock PDF export for Reports!"
          cta="analyze-unlockReportsExport-pdfExport-upgrade-1"
        />
      )}
    </React.Fragment>
  )
}

PDFExportButton.propTypes = {
  exportToPDF: PropTypes.func.isRequired,
}

export default PDFExportButton
