import React, { useState } from 'react'
import styled from 'styled-components'
import Locked from '@bufferapp/ui/Icon/Icons/Locked'
import UpgradeToPro from '../UpgradeToPro'
import Button from '@bufferapp/ui/Button'

const Lock = styled.section`
  position: relative;

  &:before {
    content: '';
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`

const LockContent = styled.main`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  color: rgba(44, 75, 255, 0.5);
`

interface LockedContentProps {
  children: React.ReactNode
  title: string
  description: string
}

const LockedContent: React.FC<LockedContentProps> = ({
  children,
  title = '',
  description,
}) => {
  const [open, setOpen] = useState(false)

  const showModal = (): void => setOpen(true)
  const hideModal = (): void => setOpen(false)

  // Creates a new string from the title by removing whitespace and uses it in the CTA view
  // e.g., "Unlock Stories Analytics" becomes "UnlockStoriesAnalytics"
  const cta = `${title.split(' ').join('')}-lockedContent-upgrade-1`

  return (
    <>
      {open && (
        <UpgradeToPro
          hide={hideModal}
          title={title}
          description={description}
          cta={cta}
        />
      )}
      <Lock>
        <LockContent>
          <Locked size="extraLarge" />
          {/* @ts-expect-error */}
          <Button label="Upgrade to Unlock" onClick={showModal} />
        </LockContent>
        {children}
      </Lock>
    </>
  )
}

export default LockedContent
