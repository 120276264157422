import React from 'react'
import styled from 'styled-components'

const IconContainer = styled.div`
  display: flex;
  width: 52px;
  height: 52px;
  background: linear-gradient(
    360deg,
    #33b6ff 0%,
    rgba(77, 191, 255, 0.75) 100%
  );
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
`

export const AudienceIcon = () => (
  <IconContainer>
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="url(#paint0_linear)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.123 26C40.4662 26 39.123 27.3431 39.123 29V36C39.123 37.6156 40.4002 38.933 42 38.9975V45C42 46.6569 43.3431 48 45 48H46C47.6569 48 49 46.6569 49 45V39H49.123C50.7799 39 52.123 37.6569 52.123 36V29C52.123 27.3431 50.7799 26 49.123 26H42.123Z"
        fill="white"
      />
      <path
        d="M42 38.9975H43V38.0371L42.0403 37.9983L42 38.9975ZM49 39V38H48V39H49ZM40.123 29C40.123 27.8954 41.0185 27 42.123 27V25C39.9139 25 38.123 26.7909 38.123 29H40.123ZM40.123 36V29H38.123V36H40.123ZM42.0403 37.9983C40.9745 37.9553 40.123 37.0769 40.123 36H38.123C38.123 38.1544 39.8259 39.9106 41.9597 39.9967L42.0403 37.9983ZM43 45V38.9975H41V45H43ZM45 47C43.8954 47 43 46.1046 43 45H41C41 47.2091 42.7909 49 45 49V47ZM46 47H45V49H46V47ZM48 45C48 46.1046 47.1046 47 46 47V49C48.2091 49 50 47.2091 50 45H48ZM48 39V45H50V39H48ZM49.123 38H49V40H49.123V38ZM51.123 36C51.123 37.1046 50.2276 38 49.123 38V40C51.3322 40 53.123 38.2091 53.123 36H51.123ZM51.123 29V36H53.123V29H51.123ZM49.123 27C50.2276 27 51.123 27.8954 51.123 29H53.123C53.123 26.7909 51.3322 25 49.123 25V27ZM42.123 27H49.123V25H42.123V27Z"
        fill="url(#paint1_linear)"
      />
      <circle
        cx="46.125"
        cy="21.4998"
        r="4.5"
        fill="white"
        stroke="url(#paint2_linear)"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 26C15.3431 26 14 27.3431 14 29V36C14 37.6569 15.3431 39 17 39L17 45C17 46.6569 18.3431 48 20 48H21C22.6569 48 24 46.6569 24 45V39C25.6569 39 27 37.6569 27 36V29C27 27.3431 25.6569 26 24 26H17Z"
        fill="white"
      />
      <path
        d="M17 39H18V38H17V39ZM17 45H18H17ZM24 39V38H23V39H24ZM15 29C15 27.8954 15.8954 27 17 27V25C14.7909 25 13 26.7909 13 29H15ZM15 36V29H13V36H15ZM17 38C15.8954 38 15 37.1046 15 36H13C13 38.2091 14.7909 40 17 40V38ZM18 45L18 39H16L16 45H18ZM20 47C18.8954 47 18 46.1046 18 45H16C16 47.2091 17.7909 49 20 49V47ZM21 47H20V49H21V47ZM23 45C23 46.1046 22.1046 47 21 47V49C23.2091 49 25 47.2091 25 45H23ZM23 39V45H25V39H23ZM26 36C26 37.1046 25.1046 38 24 38V40C26.2091 40 28 38.2091 28 36H26ZM26 29V36H28V29H26ZM24 27C25.1046 27 26 27.8954 26 29H28C28 26.7909 26.2091 25 24 25V27ZM17 27H24V25H17V27Z"
        fill="url(#paint3_linear)"
      />
      <circle
        cx="21.002"
        cy="21.4998"
        r="4.5"
        fill="white"
        stroke="url(#paint4_linear)"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 25C27.3431 25 26 26.3431 26 28V37C26 38.6569 27.3431 40 29 40L29 48C29 49.6569 30.3431 51 32 51H34C35.6569 51 37 49.6569 37 48V40C38.6569 40 40 38.6569 40 37V28C40 26.3431 38.6569 25 37 25H29Z"
        fill="white"
      />
      <path
        d="M29 40H30L30 39H29V40ZM29 48H28H29ZM37 40V39H36V40H37ZM27 28C27 26.8954 27.8954 26 29 26V24C26.7909 24 25 25.7909 25 28H27ZM27 37V28H25V37H27ZM29 39C27.8954 39 27 38.1046 27 37H25C25 39.2091 26.7909 41 29 41V39ZM30 48V40H28L28 48H30ZM32 50C30.8954 50 30 49.1046 30 48H28C28 50.2091 29.7909 52 32 52V50ZM34 50H32V52H34V50ZM36 48C36 49.1046 35.1046 50 34 50V52C36.2091 52 38 50.2091 38 48H36ZM36 40V48H38V40H36ZM39 37C39 38.1046 38.1046 39 37 39V41C39.2091 41 41 39.2091 41 37H39ZM39 28V37H41V28H39ZM37 26C38.1046 26 39 26.8954 39 28H41C41 25.7909 39.2091 24 37 24V26ZM29 26H37V24H29V26Z"
        fill="url(#paint5_linear)"
      />
      <circle
        cx="33"
        cy="21"
        r="5"
        fill="white"
        stroke="url(#paint6_linear)"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="33.2872"
          y1="65.9999"
          x2="33.2871"
          y2="-3.77666e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33B6FF" />
          <stop offset="1" stopColor="#4DBFFF" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="45.623"
          y1="26"
          x2="45.623"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC1FB" />
          <stop offset="1" stopColor="#44BBFD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="46.125"
          y1="16.9998"
          x2="46.125"
          y2="25.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#63C4FA" />
          <stop offset="1" stopColor="#5BC1FB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="20.5"
          y1="26"
          x2="20.5"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC1FB" />
          <stop offset="1" stopColor="#44BBFD" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="21.002"
          y1="16.9998"
          x2="21.002"
          y2="25.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#63C4FA" />
          <stop offset="1" stopColor="#5BC1FB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="33"
          y1="25"
          x2="33"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#66C5FA" />
          <stop offset="1" stopColor="#40BAFE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="33"
          y1="16"
          x2="33"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64C4FA" />
          <stop offset="1" stopColor="#66C5FA" />
        </linearGradient>
      </defs>
    </svg>
  </IconContainer>
)

export const EngagementsIcon = () => (
  <IconContainer>
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="url(#paint0_linear)" />
      <path
        d="M28 23.8387C24.3273 17.4546 14 19.3183 14 27.318C14 32.8264 20.4995 38.4589 28 46C35.5017 38.4589 42 32.8264 42 27.318C42 19.2911 31.6458 17.5018 28 23.8387Z"
        fill="white"
      />
      <path
        d="M42.0018 29.3055C40.2407 27.702 37.8206 27.21 35.7039 27.6605C32.9481 28.2472 30.5 30.4835 30.5 34.0663C30.5 36.5602 32.0002 38.8528 33.8592 40.9899C35.2731 42.6154 37.0835 44.3521 39.0065 46.1969C39.6464 46.8108 40.2988 47.4366 40.9531 48.0743L41.9999 49.0943L43.0468 48.0744C43.7001 47.4378 44.3513 46.8131 44.9901 46.2003C46.9147 44.3542 48.7264 42.6164 50.1411 40.9898C52.0001 38.8527 53.5 36.5601 53.5 34.0663C53.5 30.471 51.0432 28.2433 48.2844 27.6648C46.1727 27.2221 43.7577 27.7146 42.0018 29.3055Z"
        fill="white"
        stroke="#55C0FC"
        strokeWidth="3"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="33.2872"
          y1="65.9999"
          x2="33.2871"
          y2="-3.77666e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33B6FF" />
          <stop offset="1" stopColor="#4DBFFF" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  </IconContainer>
)

export const ImpressionsIcon = () => (
  <IconContainer>
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="33" cy="33" r="33" fill="url(#paint0_linear)" />
      <circle cx="28" cy="27" r="5" fill="white" />
      <circle cx="38" cy="39" r="5" fill="white" />
      <circle cx="17" cy="39" r="5" fill="white" />
      <path
        d="M43 24.574L53.3291 21.9401C54.0886 21.7464 54.7655 22.4621 54.5299 23.2097L51.4399 33.0139L43 24.574Z"
        fill="white"
      />
      <path d="M17 38.5L28.5 27" stroke="white" strokeWidth="3" />
      <path d="M27.5 27.5L37.5 37.5" stroke="white" strokeWidth="3" />
      <path
        d="M38 38L48 28"
        stroke="white"
        strokeWidth="3"
        strokeDasharray="2 0"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="33.2872"
          y1="65.9999"
          x2="33.2871"
          y2="-3.77666e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33B6FF" />
          <stop offset="1" stopColor="#4DBFFF" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  </IconContainer>
)
