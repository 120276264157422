import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import DayDropdown from './Day'
import MetricDropdown from './Metric'

const Wrapper = styled.section`
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
`

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > *:nth-child(1) {
    margin-right: 0.5rem;
  }
`
class Dropdowns extends React.Component {
  static propTypes = {
    sortBy: PropTypes.func.isRequired,
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = {
      isMetricDropdownOpen: false,
    }
    this.openMetricsDropdown = this.openMetricsDropdown.bind(this)
    this.closeMetricsDropdown = this.closeMetricsDropdown.bind(this)
  }

  openMetricsDropdown() {
    this.setState({
      isMetricDropdownOpen: true,
    })
  }

  closeMetricsDropdown() {
    this.setState({
      isMetricDropdownOpen: false,
    })
  }

  render() {
    return (
      <Wrapper>
        <Container>
          <DayDropdown
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            selectedDay={this.props.selectedDay}
            // @ts-expect-error TS(2339) FIXME: Property 'filterBy' does not exist on type 'Readon... Remove this comment to see the full error message
            filterBy={this.props.filterBy}
            // @ts-expect-error TS(2339) FIXME: Property 'dates' does not exist on type 'Readonly<... Remove this comment to see the full error message
            dates={this.props.dates}
          />
        </Container>
        <Container>
          <MetricDropdown
            // @ts-expect-error TS(2322) FIXME: Type '{ sortBy: any; attributeToSortBy: any; }' is... Remove this comment to see the full error message
            sortBy={this.props.sortBy}
            // @ts-expect-error TS(2339) FIXME: Property 'attributeToSortBy' does not exist on typ... Remove this comment to see the full error message
            attributeToSortBy={this.props.attributeToSortBy}
          />
        </Container>
      </Wrapper>
    )
  }
}

export default Dropdowns
