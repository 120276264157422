import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@buffer-mono/legacy-bufferapp-components'
import classNames from 'classnames'

import style from './style.module.less'

// @ts-expect-error TS(7006) FIXME: Parameter 'postsCounts' implicitly has an 'any' ty... Remove this comment to see the full error message
function getButtons(postsCounts, activePostsCount, handlePostsCountClick) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'button' implicitly has an 'any' type.
  return postsCounts.map((button) => {
    const active = button.value === String(activePostsCount)
    const buttonClass = classNames(style.buttonItem, {
      [style.buttonActive]: active,
    })
    return (
      <li // eslint-disable-line
        key={button.value}
        className={buttonClass}
        onClick={() => handlePostsCountClick({ postsCount: button.value })}
      >
        {button.value}
      </li>
    )
  })
}

const PostsCountBar = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'postsCounts' implicitly has an 'a... Remove this comment to see the full error message
  postsCounts,
  // @ts-expect-error TS(7031) FIXME: Binding element 'activePostsCount' implicitly has ... Remove this comment to see the full error message
  activePostsCount,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handlePostsCountClick' implicitly... Remove this comment to see the full error message
  handlePostsCountClick,
}) => (
  <div>
    <Text size="small">Show</Text>
    <ul className={style.container}>
      {getButtons(postsCounts, activePostsCount, handlePostsCountClick)}
    </ul>
  </div>
)

PostsCountBar.propTypes = {
  postsCounts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
  activePostsCount: PropTypes.number.isRequired,
  handlePostsCountClick: PropTypes.func.isRequired,
}

PostsCountBar.defaultProps = {
  postsCounts: [
    {
      value: '5',
    },
    {
      value: '10',
    },
    {
      value: '25',
    },
    {
      value: '50',
    },
  ],
}

export default PostsCountBar
