import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('STORIES_DAILY', {
  FETCH: 'FETCH',
  SELECT_METRIC: 'SELECT_METRIC',
})

const initialState = {
  loading: true,
  hasError: false,
  daily: [],
  totals: [],
  selectedMetrics: {
    primary: {},
    secondary: {},
  },
}

// @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
function getSelectedMetricLabelsOnFetch(result) {
  const totals = result.totals
  if (totals.length > 0) {
    return {
      primary: totals[0],
      secondary: totals[1],
    }
  }
  return { primary: {}, secondary: {} }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function setSelectedMetricsAfterSelecting(state, metric, isPrimary) {
  if (isPrimary) {
    if (state.selectedMetrics.secondary.label === 'Previous Period') {
      return {
        primary: metric,
        secondary: {
          label: 'Previous Period',
          value: metric.previousValue,
          color: '#9B51E0',
        },
      }
    }
    return {
      primary: metric,
      secondary: state.selectedMetrics.secondary,
    }
  } else if (metric.label === 'Previous Period') {
    return {
      primary: state.selectedMetrics.primary,
      secondary: {
        label: metric.label,
        value: state.selectedMetrics.primary.previousValue,
        color: '#9B51E0',
      },
    }
  }
  return {
    primary: state.selectedMetrics.primary,
    secondary: metric,
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `stories_daily_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `stories_daily_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case `stories_daily_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        ...action.result,
        loading: false,
        selectedMetrics: getSelectedMetricLabelsOnFetch(action.result),
      }
    case `stories_daily_${actionTypes.SELECT_METRIC}`:
      return Object.assign({}, state, {
        selectedMetrics: setSelectedMetricsAfterSelecting(
          state,
          action.metric,
          action.isPrimary,
        ),
      })
    default:
      return state
  }
}

export const actions = {
  fetch: () => ({
    type: actionTypes.FETCH,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  onSelectMetric(metric, isPrimary) {
    return {
      type: `stories_daily_${actionTypes.SELECT_METRIC}`,
      metric,
      isPrimary,
    }
  },
}
