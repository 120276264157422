import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { white, geyser } from '@buffer-mono/legacy-bufferapp-components'
import { Text, Button } from '@bufferapp/ui'

import ErrorBoundary from '../ErrorBoundary'

const Container = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 9999;
`

const Modal = styled.section`
  background: ${white}
    url('https://s3.amazonaws.com/buffer-analyze/images/modal-header-background.png')
    no-repeat;
  background-size: 100% auto;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 0 1rem;
  padding: 16px 0;
  width: 576px;
`

const Header = styled.div`
  padding: 0 24px;
`

const Content = styled.div`
  border-radius: 4px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  height: 298px;
  margin-left: 24px;
  overflow: hidden;
  width: 527px;
`

const Action = styled.div`
  border-top: 1px solid ${geyser};
  display: flex;
  margin-top: 21px;
  padding-top: 16px;
  justify-content: center;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'cookie' implicitly has an 'any' type.
function setCookie(cookie, value) {
  const fifteenDays = 15 * 24 * 60 * 60
  document.cookie = `analyze_notice_modal=${value}; max-age=${fifteenDays}; ${cookie}`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'cookie' implicitly has an 'any' type.
function getCookieValue(cookie) {
  const match = cookie.match(/analyze_notice_modal=(\w+);*/)
  if (match) {
    return match[1]
  }
  return null
}

class NoticeModal extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    const cookie = getCookieValue(props.cookie)
    this.state = {
      dismissed: cookie === 'dismissed',
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'cookie' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { children, cookie, cta, description, title } = this.props

    // @ts-expect-error TS(2339) FIXME: Property 'dismissed' does not exist on type 'Reado... Remove this comment to see the full error message
    if (this.state.dismissed) {
      return null
    }
    return (
      <ErrorBoundary>
        <Container>
          <Modal>
            <Header>
              <Text type="h2">{title}</Text>
              <div style={{ width: '309px' }}>
                <Text type="p">{description}</Text>
              </div>
            </Header>
            <Content>{children}</Content>
            <Action>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: ()... Remove this comment to see the full error message */}
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ dismissed: true })
                  setCookie(cookie, 'dismissed')
                }}
                label={cta}
              />
            </Action>
          </Modal>
        </Container>
      </ErrorBoundary>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
NoticeModal.propTypes = {
  children: PropTypes.node.isRequired,
  cookie: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
}

export default NoticeModal
