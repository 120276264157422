import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('CAMPAIGN_PULSE', {
  FETCH: 'FETCH',
})

const initialState = {
  loading: true,
  hasError: false,
  metrics: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `campaign_pulse_${asyncDataFetchActionTypes.FETCH_START}`:
      return initialState
    case `campaign_pulse_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...initialState,
        loading: false,
        hasError: true,
      }
    case `campaign_pulse_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        loading: false,
        metrics: action.result,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
  fetch: (campaign) => ({
    type: actionTypes.FETCH,
    campaign,
  }),
}
