import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import styled from 'styled-components'
import { DemographicTable } from '~/shared-components'

import getChartConfig from './chartConfig'

// Initialize highcharts-more to enable bubble series
HighchartsMore(Highcharts)

// @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
function getSelectedMetricsGroup(metrics, selectedGroup) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  return metrics.find((group) => group.key === selectedGroup)
}

const ChartContainer = styled.div`
  padding: 0 16px 24px;
`

class Chart extends PureComponent {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'selectedGroup' does not exist on type 'R... Remove this comment to see the full error message
    const { selectedGroup, metrics } = this.props
    const group = getSelectedMetricsGroup(metrics, selectedGroup)
    const groupMetrics = group.metrics
      // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
      .find((metric) => metric.key === 'gender_age')
      .values.slice(0, 3)

    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2. */
    const options = getChartConfig(group, selectedGroup)
    return (
      <div>
        <ChartContainer>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </ChartContainer>
        <DemographicTable
          metrics={groupMetrics}
          labels={['Gender and Age', group.label]}
        />
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Chart.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedGroup: PropTypes.string.isRequired,
}

export default Chart
