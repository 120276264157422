import React from 'react'
import { Avatar } from '@bufferapp/ui'
import styled from 'styled-components'

interface ProfileBadgeProps {
  avatarUrl: string
  service: string
  avatarSize?: string
  marginRight?: string
}

const StyledAvatarWrapper = styled.div<{ marginRight?: string }>`
  margin-right: ${(props): string => props.marginRight || '16px'};
`

const ProfileBadge = ({
  avatarUrl,
  service,
  avatarSize = 'small',
  marginRight = '16px',
}: ProfileBadgeProps): JSX.Element => {
  return (
    <StyledAvatarWrapper marginRight={marginRight}>
      <Avatar
        src={avatarUrl}
        type="social"
        network={service}
        size={avatarSize}
        alt={service}
      />
    </StyledAvatarWrapper>
  )
}

export default ProfileBadge
