import striptags from 'striptags'
import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes as dateActionTypes } from '~/date-picker'
import {
  actionTypes as exportActionTypes,
  actions as exportActions,
} from '~/png-export'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'
import { actionTypes as postsActionTypes, defaultSortMetrics } from './reducer'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

const EXPORT_FILENAME = 'posts'

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
const formatMedia = (post) => {
  if (post.media) {
    return post.media.video
      ? post.media.video.details.transcoded_location
      : post.media.picture
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
const escapeText = (text) => {
  const escapedText = striptags(text, [], '\n').replace(/\n+/gm, ' ')
  return `"${escapedText}"`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
const formatDataForKey = (key, post, timezone) => {
  switch (key) {
    case 'date':
      return dayjs(post[key]).tz(timezone).format('MM/DD/YYYY HH:mm:ss')
    case 'media':
      return formatMedia(post)
    case 'text':
      return escapeText(post.text)
    default:
      return post[key]
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'profiles' implicitly has an 'any'... Remove this comment to see the full error message
const getSelectedProfileTimezone = ({ profiles, selectedProfileId }) => {
  const selectedProfile = profiles.find(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => profile.id === selectedProfileId,
  )
  return selectedProfile.timezone
}

// @ts-expect-error TS(7031) FIXME: Binding element 'posts' implicitly has an 'any' ty... Remove this comment to see the full error message
const formatDataForCSVExport = ({ posts }, profileState) => {
  const timezone = getSelectedProfileTimezone(profileState)
  const data = {}

  // Populate data
  // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
  posts.forEach((post) => {
    // Prepare Data structure
    const keys = Object.keys(post)
    keys.forEach((key) => {
      if (key === 'statistics' || key === 'boosted_statistics') {
        Object.keys(post[key]).forEach((statistic) => {
          if (key === 'boosted_statistics' && statistic !== 'spend') {
            statistic = `${statistic} paid`
          }
          if (!(statistic in data)) {
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            data[statistic] = []
          }
        })
      } else if (!(key in data)) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key] = []
      }
    })
  })

  // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
  posts.forEach((post) => {
    Object.keys(data).forEach((key) => {
      const boostedStatisticsKey = key.replace(' paid', '')
      if (key in post) {
        const value = formatDataForKey(key, post, timezone)
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key].push(value)
      } else if (key in post.statistics) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key].push(post.statistics[key])
      } else if (boostedStatisticsKey in post.boosted_statistics) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key].push(post.boosted_statistics[boostedStatisticsKey])
      } else {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key].push('')
      }
    })
  })

  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
function serviceHasPosts(profile) {
  return profile.service !== 'shopify'
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  switch (action.type) {
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      if (getState().posts.posts.length) {
        dispatch(
          exportCSVActions.exportChart(
            EXPORT_FILENAME,
            formatDataForCSVExport(getState().posts, getState().profiles),
          ),
        )
      }
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart('js-dom-to-png-posts', EXPORT_FILENAME),
      )
      break
    case postsActionTypes.SELECT_TOP_POSTS_METRIC:
      if (getState().profiles.selectedProfileService !== 'linkedin') {
        dispatch(
          actions.fetch({
            name: 'posts',
            args: {
              profileId: getState().profiles.selectedProfileId,
              profileService: getState().profiles.selectedProfileService,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
              sortBy: action.metric.apiKey,
              descending: getState().posts.isDescendingSelected,
              limit: getState().posts.activePostsCount,
              searchTerms: getState().posts.searchTerms,
            },
          }),
        )
      }
      break
    case postsActionTypes.SELECT_TOP_POSTS_COUNT:
      if (getState().profiles.selectedProfileService !== 'linkedin') {
        dispatch(
          actions.fetch({
            name: 'posts',
            args: {
              profileId: getState().profiles.selectedProfileId,
              profileService: getState().profiles.selectedProfileService,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
              sortBy: getState().posts.selectedMetric.apiKey,
              descending: getState().posts.isDescendingSelected,
              limit:
                action.postsCount !== 'All' ? action.postsCount : undefined,
              searchTerms: getState().posts.searchTerms,
            },
          }),
        )
      }
      break
    case postsActionTypes.SELECT_TOP_POSTS_ORDER:
      if (getState().profiles.selectedProfileService !== 'linkedin') {
        dispatch(
          actions.fetch({
            name: 'posts',
            args: {
              profileId: getState().profiles.selectedProfileId,
              profileService: getState().profiles.selectedProfileService,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
              sortBy: getState().posts.selectedMetric.apiKey,
              descending: action.isDescendingSelected,
              limit: getState().posts.activePostsCount,
              searchTerms: getState().posts.searchTerms,
            },
          }),
        )
      }

      break
    case profileActionTypes.SELECT_PROFILE:
      if (action.profile.service !== 'linkedin') {
        if (serviceHasPosts(action.profile)) {
          dispatch(
            actions.fetch({
              name: 'posts',
              args: {
                profileId: action.profile.id,
                profileService: action.profile.service,
                startDate: getState().date.startDate,
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                sortBy: defaultSortMetrics[action.profile.service].apiKey,
                endDate: getState().date.endDate,
                limit: getState().posts.activePostsCount,
              },
            }),
          )
        }
      }

      break
    case postsActionTypes.FETCH:
    case profileActionTypes.REFRESH_PROFILES:
      if (getState().profiles.selectedProfileService !== 'linkedin') {
        if (
          getState().profiles.selectedProfileId &&
          serviceHasPosts(getState().profiles.selectedProfile)
        ) {
          dispatch(
            actions.fetch({
              name: 'posts',
              args: {
                profileId: getState().profiles.selectedProfileId,
                profileService: getState().profiles.selectedProfileService,
                startDate: getState().date.startDate,
                endDate: getState().date.endDate,
                sortBy: getState().posts.selectedMetric.apiKey,
                descending: getState().posts.isDescendingSelected,
                limit: getState().posts.activePostsCount,
                searchTerms: getState().posts.searchTerms,
              },
            }),
          )
        }
      }

      break
    case dateActionTypes.SET_DATE_RANGE:
      if (
        getState().profiles.selectedProfileId &&
        serviceHasPosts(getState().profiles.selectedProfile) &&
        getState().profiles.selectedProfileService !== 'linkedin'
      ) {
        dispatch(
          actions.fetch({
            name: 'posts',
            args: {
              profileId: getState().profiles.selectedProfileId,
              profileService: getState().profiles.selectedProfileService,
              startDate: action.startDate,
              endDate: action.endDate,
              sortBy: getState().posts.selectedMetric.apiKey,
              descending: getState().posts.isDescendingSelected,
              limit: getState().posts.activePostsCount,
              searchTerms: getState().posts.searchTerms,
            },
          }),
        )
      }
      break
    case postsActionTypes.SEARCH:
      if (getState().profiles.selectedProfileService !== 'linkedin') {
        dispatch(
          actions.fetch({
            name: 'posts',
            args: {
              profileId: getState().profiles.selectedProfileId,
              profileService: getState().profiles.selectedProfileService,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
              sortBy: getState().posts.selectedMetric.apiKey,
              descending: getState().posts.isDescendingSelected,
              limit: getState().posts.activePostsCount,
              searchTerms: action.tags,
            },
          }),
        )
      }
      break
    default:
      break
  }
  return next(action)
}
