import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tooltip from '@bufferapp/ui/Tooltip'
import TruncatedNumber from '../TruncatedNumber'
import OrganicIcon from '@bufferapp/ui/Icon/Icons/Organic'
import PromotedIcon from '@bufferapp/ui/Icon/Icons/Promoted'
import CircleIcon from '../CircleIcon'

const COLORS = {
  organic: '#6B81FF',
  paid: '#4DD9DD',
}

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 3px;
  align-items: center;
  grid-template-columns: auto ${(props) =>
      `minmax(3%, ${
        // @ts-expect-error TS(2339) FIXME: Property 'paid' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
        props.paid
      }%) minmax(3%, ${
        // @ts-expect-error TS(2339) FIXME: Property 'organic' does not exist on type 'ThemedS... Remove this comment to see the full error message
        props.organic
      }%)`};
  padding-top: 2px;
  cursor: pointer;

  > div {
    margin-right: 1px;
  }
`

const OrganicBar = styled.span`
  height: 4px;
  background-color: #6b81ff;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'fill' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.fill ? '3px' : 0};
  border-top-left-radius: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'fill' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.fill ? '3px' : 0};
`

const PaidBar = styled.span`
  height: 4px;
  background-color: ${COLORS.paid};
  box-shadow: 3px 0px 0px #ffffff;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'fill' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.fill ? '3px' : 0};
  border-top-right-radius: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'fill' does not exist on type 'ThemedStyl... Remove this comment to see the full error message
    props.fill ? '3px' : 0};
`

const TootipLabelContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 12px;
  padding: 8px;
`

const TooltipLabelTotals = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
`

const TooltipLabelPaid = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
  font-size: 14px;
  display: flex;
`

const TooltipLabelOrganic = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
  font-size: 14px;
  display: flex;
`

const WhiteSpace = styled.span`
  margin-left: 3px;
`

const ToolTipText = styled.span`
  margin-top: 1px;
  margin-left: 4px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'organic' implicitly has an 'any' ... Remove this comment to see the full error message
const TootipLabel = ({ organic, paid, organicPercentage, paidPercentage }) => {
  return (
    <TootipLabelContainer>
      <TooltipLabelTotals>
        <TruncatedNumber>{organic + paid}</TruncatedNumber> total
      </TooltipLabelTotals>
      <TooltipLabelOrganic>
        <CircleIcon color={COLORS.organic}>
          <OrganicIcon />
        </CircleIcon>
        <ToolTipText>
          <TruncatedNumber>{organic}</TruncatedNumber>
          <WhiteSpace />
          {'('}
          <TruncatedNumber showPercentSign>{organicPercentage}</TruncatedNumber>
          {') organic'}
        </ToolTipText>
      </TooltipLabelOrganic>
      <TooltipLabelPaid>
        <CircleIcon color={COLORS.paid}>
          <PromotedIcon />
        </CircleIcon>
        <ToolTipText>
          <TruncatedNumber>{paid}</TruncatedNumber>
          <WhiteSpace />
          {'('}
          <TruncatedNumber showPercentSign>{paidPercentage}</TruncatedNumber>
          {') boosted'}
        </ToolTipText>
      </TooltipLabelPaid>
    </TootipLabelContainer>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'organic' implicitly has an 'any' ... Remove this comment to see the full error message
const PecentageBar = ({ organic, paid }) => {
  if (organic === 0 && paid === 0) {
    return null
  }

  const total = organic + paid
  const organicPercentage = (organic / total) * 100
  const paidPercentage = (paid / total) * 100

  return (
    // @ts-expect-error
    <Tooltip
      customLabel={
        <TootipLabel
          organic={organic}
          paid={paid}
          organicPercentage={organicPercentage}
          paidPercentage={paidPercentage}
        />
      }
      verticalAlign="top"
    >
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <Container organic={organicPercentage} paid={paidPercentage}>
        <CircleIcon
          color={COLORS.paid}
          width={'12px'}
          height={'12px'}
          // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'string'.
          margin={0}
          iconWidth={'10px'}
          iconHeight={'10px'}
        >
          <PromotedIcon />
        </CircleIcon>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <PaidBar fill={organic === 0} />
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <OrganicBar fill={paid === 0} />
      </Container>
    </Tooltip>
  )
}

PecentageBar.propTypes = {
  organic: PropTypes.number,
  paid: PropTypes.number,
}

PecentageBar.defaultProps = {
  organic: 0,
  paid: 0,
}

export default PecentageBar
