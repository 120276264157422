// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'
import { actionTypes as asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'

export const actionTypes = keyWrapper('DEMOGRAPHIC_COUNTRIES', {
  SELECT_COUNTRIES_GROUP: 'SELECT_COUNTRIES_GROUP',
})

const initialState = {
  metrics: [],
  selectedGroup: '',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function getGroupKeyFromLabel(label, metrics) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  return metrics.find((group) => group.label === label).key
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case `demographic_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        selectedGroup: action.result.selectedGroup,
        metrics: action.result.metrics,
      }
    case actionTypes.SELECT_COUNTRIES_GROUP:
      return {
        ...state,
        isDropdownOpen: false,
        selectedGroup: getGroupKeyFromLabel(action.label, state.metrics),
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
  selectMetricsGroup(label) {
    return {
      type: actionTypes.SELECT_COUNTRIES_GROUP,
      label,
    }
  },
}
