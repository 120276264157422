import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { actions as analyticsActions } from '~/analytics-middleware'
import {
  getNumOfDaysForDateRange,
  getRouteElements,
} from '~/shared-components/utils'
import { getOrganizationIdFromStore } from '~/analytics-middleware/middleware'
import { actionTypes, actions } from './reducer'
import downloadAsZip from './lib/downloadAsZip'
import generateCSVFromChart from '~/csv-export/lib/generateCSVFromChart'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { exportToCSV, date, router } = store.getState()
  const routeElements = getRouteElements(router)

  switch (action.type) {
    case actionTypes.EXPORT_TO_CSV_START:
      store.dispatch(
        analyticsActions.trackEvent('pageExported', {
          days: getNumOfDaysForDateRange(date),
          exportType: 'csv',
          name: routeElements.name,
          channel: routeElements.channel,
        }),
      )

      return generateCSVFromChart(exportToCSV.charts, date)
        .then((csvs) => {
          downloadAsZip(action.filename, csvs).then(() => {
            store.dispatch(actions.endExportToCSV())
          })
          next(action)
        })
        .catch((e) => {
          const { profiles } = store.getState()
          const organizationId = getOrganizationIdFromStore(store)

          BufferTracker.analyticsExportFailed({
            channel: profiles?.selectedProfile.channelId,
            clientName: 'analytics',
            exportType: 'csv',
            organizationId,
            product: 'analytics',
            reportId: routeElements.name,
          })
        })
    default:
      return next(action)
  }
}
