import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as dateActionTypes } from '~/date-picker'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const state = getState()
  switch (action.type) {
    case dateActionTypes.SET_DATE_RANGE:
      if (getState().profiles.selectedProfileId) {
        dispatch(
          actions.fetch({
            name: 'stories_breakdown',
            args: {
              profileId: getState().profiles.selectedProfileId,
              startDate: action.startDate,
              endDate: action.endDate,
            },
          }),
        )
      }
      break
    case profileActionTypes.SELECT_PROFILE:
      if (action.profile.service === 'instagram') {
        dispatch(
          actions.fetch({
            name: 'stories_breakdown',
            args: {
              profileId: state.profiles.selectedProfileId,
              startDate: state.date.startDate,
              endDate: state.date.endDate,
            },
          }),
        )
      }
      break
    case actionTypes.FETCH:
      dispatch(
        actions.fetch({
          name: 'stories_breakdown',
          args: {
            profileId: state.profiles.selectedProfileId,
            startDate: state.date.startDate,
            endDate: state.date.endDate,
          },
        }),
      )
      break
    default:
      break
  }
  return next(action)
}
