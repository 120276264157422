import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
  TotalsGrid,
  Container,
  CHANNEL_TYPES,
} from '~/shared-components'
import AddReport from '~/add-report'
import Title from '../Title'

export const GridContainer = memo(
  // @ts-expect-error TS(2339) FIXME: Property 'metrics' does not exist on type '{ child... Remove this comment to see the full error message
  ({ metrics }) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
    const prettyPrintKey = (key) => {
      switch (key) {
        case 'followers':
          return 'Total Audience'
        case 'impressions':
          return 'Total Impressions'
        case 'engagement':
          return 'Total Engagement'
        default:
          return key
      }
    }

    // @ts-expect-error TS(7006) FIXME: Parameter 'metrics' implicitly has an 'any' type.
    const mapMetrics = (metrics) => {
      // @ts-expect-error TS(7034) FIXME: Variable 'metricsArray' implicitly has type 'any[]... Remove this comment to see the full error message
      const metricsArray = []
      if (!metrics) {
        // @ts-expect-error TS(7005) FIXME: Variable 'metricsArray' implicitly has an 'any[]' ... Remove this comment to see the full error message
        return metricsArray
      }
      Object.keys(metrics).map((key) => {
        const metricExpectedFormat = {
          label: prettyPrintKey(key),
          value: metrics[key].total,
          diff: metrics[key].total_diff,
          daily_totals: metrics[key].daily_totals,
          controlMinMax: key === 'followers',
        }
        metricsArray.push(metricExpectedFormat)
      })
      // @ts-expect-error TS(7005) FIXME: Variable 'metricsArray' implicitly has an 'any[]' ... Remove this comment to see the full error message
      return metricsArray
    }

    return <TotalsGrid metrics={mapMetrics(metrics)} />
  },
  (prevProps, nextProps) =>
    // @ts-expect-error TS(2339) FIXME: Property 'metrics' does not exist on type 'Readonl... Remove this comment to see the full error message
    JSON.stringify(prevProps.metrics) === JSON.stringify(nextProps.metrics),
)

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const DailyTotalsTable = ({ metrics, loading, hasError, profiles }) => {
  let content = null

  if (hasError) {
    content = null
  } else if (loading) {
    // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; }' is not as... Remove this comment to see the full error message
    content = <Loading active noBorder />
  } else if (!metrics || Object.keys(metrics).length === 0) {
    content = (
      <NoData chartName="daily_totals" channelType={CHANNEL_TYPES.aggregates} />
    )
  } else {
    // @ts-expect-error TS(2322) FIXME: Type '{ metrics: any; }' is not assignable to type... Remove this comment to see the full error message
    content = <GridContainer metrics={metrics} />
  }

  return (
    <ChartCard hasError={hasError}>
      <ChartHeader>
        <Title />
        {!loading && (
          <AddReport
            chart="aggregates_daily"
            state={{
              // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
              profiles: profiles.map((p) => p.id),
            }}
          />
        )}
      </ChartHeader>
      <Container>{content}</Container>
    </ChartCard>
  )
}

DailyTotalsTable.defaultProps = {
  loading: false,
  hasError: false,
  metrics: {},
  profiles: [],
}

DailyTotalsTable.propTypes = {
  loading: PropTypes.bool,
  metrics: PropTypes.shape({}).isRequired,
  profiles: PropTypes.array.isRequired,
}

export default DailyTotalsTable
