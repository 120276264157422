import React from 'react'
import PropTypes from 'prop-types'

import { geyser } from '@buffer-mono/legacy-bufferapp-components'

import { color as metricsColor } from '../style'

const iconSize = 7
const base = 10
const style = {
  display: 'inline-block',
  verticalAlign: 'baseline',
  width: `${iconSize}px`,
  height: `${iconSize}px`,
  marginRight: `${0.5 * base}px`,

  borderWidth: '2px',
  borderStyle: 'solid',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
function lightenDarkenColor(color, amt) {
  let usePound = false
  if (color[0] === '#') {
    color = color.slice(1)
    usePound = true
  }

  /* eslint-disable no-bitwise */
  const num = parseInt(color, 16)
  let r = (num >> 16) + amt
  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00ff) + amt
  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000ff) + amt
  if (g > 255) g = 255
  else if (g < 0) g = 0

  return `${usePound ? '#' : ''}${(g | (b << 8) | (r << 16)).toString(16)}`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
function getIconStyle(metric) {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const color = metric.color ? metric.color : metricsColor[metric.key] || geyser
  return Object.assign({}, style, {
    background: lightenDarkenColor(color, 90),
    borderColor: color,
    borderRadius: `${metric.squaredIcon ? 0 : base}px`,
  })
}

// @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
const MetricIcon = ({ metric }) => <span style={getIconStyle(metric)} />

MetricIcon.propTypes = {
  metric: PropTypes.shape({
    color: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
}

export default MetricIcon
