import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { geyser, Loader } from '@buffer-mono/legacy-bufferapp-components'

const Container = styled.div`
  background: none;
  border-radius: 2px;
  flex-direction: row;
  flex: 1;

  svg {
    width: 24px !important;
    height: 24px !important;
    margin: 0 0 1rem;
  }
`

const Wrapper = styled.div`
  z-index: 2;
  text-align: center;
  border: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'noBorder' does not exist on type 'Themed... Remove this comment to see the full error message
    props.noBorder ? 'none' : `solid 1px ${geyser}`};
  background: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'transparent' does not exist on type 'The... Remove this comment to see the full error message
    props.transparent ? 'transparent' : 'rgba(255,255,255,.95)'};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'large' does not exist on type 'ThemedSty... Remove this comment to see the full error message
    props.large ? '20rem' : '8rem'};
  max-height: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'maxHeight' does not exist on type 'Theme... Remove this comment to see the full error message
    props.maxHeight
      ? // @ts-expect-error TS(2339) FIXME: Property 'maxHeight' does not exist on type 'Theme... Remove this comment to see the full error message
        props.maxHeight
      : 'none'};
  transition: opacity 350ms ease-in-out;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const Loading = ({ text, maxHeight, noBorder, transparent, large }) => (
  <Wrapper
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    noBorder={noBorder}
    transparent={transparent}
    large={large}
    maxHeight={maxHeight}
  >
    <Container>
      <Loader />
    </Container>
  </Wrapper>
)

Loading.propTypes = {
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  noBorder: PropTypes.bool,
  transparent: PropTypes.bool,
  large: PropTypes.bool,
}

Loading.defaultProps = {
  maxHeight: false,
  noBorder: false,
  transparent: false,
  large: false,
}

export default Loading
