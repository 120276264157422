import { actions as asyncDataFetchActions } from '@buffer-mono/async-data-fetch'

import {
  actions as exportActions,
  actionTypes as exportActionTypes,
} from '~/png-export'

import { actionTypes as campaignSelectorActionTypes } from '~/campaign-selector/reducer'

import { actionTypes } from './reducer'

const EXPORT_FILENAME = 'campaign_posts'

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
function isFetchAction(action) {
  return [
    actionTypes.SELECT_ORDER,
    actionTypes.SELECT_COUNT,
    actionTypes.SELECT_METRIC,
    actionTypes.SEARCH,
    actionTypes.FETCH,
    campaignSelectorActionTypes.SELECT,
  ].includes(action.type)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function getFetchPayload(state) {
  return {
    campaignId: state.campaignSelector.selectedCampaign.id,
    limit: state.campaignPostsTable.limit,
    sortBy: state.campaignPostsTable.selectedMetric.key,
    searchTerms: state.campaignPostsTable.searchTerms,
    descending: state.campaignPostsTable.isDescendingSelected,
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
function handleFetchActions(store, action) {
  const { dispatch, getState } = store
  const fetchActionPayload = getFetchPayload(getState())

  switch (action.type) {
    case actionTypes.SELECT_ORDER:
      fetchActionPayload.descending = action.isDescendingSelected
      break

    case actionTypes.SELECT_COUNT:
      fetchActionPayload.limit = action.postsCount
      break

    case actionTypes.SELECT_METRIC:
      fetchActionPayload.sortBy = action.metric.key
      break

    case actionTypes.SEARCH:
      fetchActionPayload.searchTerms = action.tags
      break

    case campaignSelectorActionTypes.SELECT:
      fetchActionPayload.campaignId = action.id
      break

    case actionTypes.FETCH:
      break

    default:
      break
  }

  dispatch(
    asyncDataFetchActions.fetch({
      name: 'campaign_posts_table',
      args: { ...fetchActionPayload },
    }),
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  if (isFetchAction(action)) {
    handleFetchActions(store, action)
  }
  const { dispatch, getState } = store

  switch (action.type) {
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-campaign-posts-table',
          EXPORT_FILENAME,
        ),
      )
      break
    default:
      break
  }

  return next(action)
}
