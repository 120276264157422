import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@buffer-mono/legacy-bufferapp-components'
import getSymbolFromCurrency from 'currency-symbol-map'

import TruncatedNumber from '../../TruncatedNumber'
import ReferralIcon from '../../ReferralIcon'
import * as styles from './styles'
import Metrics from './Metrics'

const knownSources = [
  'Buffer',
  'Facebook',
  'Google',
  'Instagram',
  'LinkedIn',
  'Pinterest',
  'Shopify',
  'Twitter',
  'Youtube',
]

// @ts-expect-error TS(7006) FIXME: Parameter 'source' implicitly has an 'any' type.
function getSource(source) {
  let match = null
  knownSources.forEach((key) => {
    if (source.toLowerCase().match(key.toLowerCase())) {
      match = key
    }
  })
  return match || source
}

// @ts-expect-error TS(7031) FIXME: Binding element 'max_price' implicitly has an 'any... Remove this comment to see the full error message
function formatPrice({ max_price, min_price, currency }) {
  if (max_price === min_price) {
    return `${getSymbolFromCurrency(currency || 'usd')}${min_price}`
  }

  return `${getSymbolFromCurrency(
    currency || 'usd',
  )}${min_price} - ${getSymbolFromCurrency(currency)}${max_price}`
}

class ProductReferrals extends React.Component {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'item' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const item = this.props.item
    const dummies = []
    const referrals = item.referrals.slice(0, 3)

    for (let i = 0; i < 3 - referrals.length; i++) {
      dummies.push(
        <li>
          <styles.ProductReferralNameHolder>
            <Text size="mini" color="shuttleGray">
              &nbsp;
            </Text>
          </styles.ProductReferralNameHolder>
          <Text size="mini" color="shuttleGray">
            <TruncatedNumber>-</TruncatedNumber>
          </Text>
        </li>,
      )
    }

    return (
      <styles.ProductReferralsStyled>
        <Text size="mini" color="shuttleGray">
          Top Referrals
        </Text>
        <styles.ProductReferralsContainer>
          {/* @ts-expect-error TS(7006) FIXME: Parameter 'referral' implicitly has an 'any' type. */}
          {referrals.map((referral) => (
            <li>
              <styles.ProductReferralNameHolder>
                <ReferralIcon item={referral} size="small" />
                <Text size="mini" color="shuttleGray">
                  {getSource(referral.source)}
                </Text>
              </styles.ProductReferralNameHolder>
              <Text size="mini" color="shuttleGray">
                {`${getSymbolFromCurrency(item.currency || 'usd')}`}
                <TruncatedNumber>{referral.sales}</TruncatedNumber>
              </Text>
            </li>
          ))}
          {dummies}
        </styles.ProductReferralsContainer>
      </styles.ProductReferralsStyled>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ProductReferrals.defaultProps = {
  item: {},
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ProductReferrals.propTypes = {
  item: PropTypes.shape({}),
}

// @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
const Product = ({ item, number, forReport }) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <styles.ItemContainer forReport={forReport} big>
    <styles.HorizontalContainer>
      <Text size="large" weight="bold" color="outerSpace">
        {number}
      </Text>
      <styles.ProductImage {...item} />
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <styles.VerticalContainer height="178px">
        <styles.ProductName>
          <Text size="mini" color="shuttleGray">
            Product
          </Text>
          <br />
          <Text size="large" weight="bold" color="outerSpace">
            <styles.ProductTruncate title={item.title}>
              {item.title}
            </styles.ProductTruncate>
          </Text>
        </styles.ProductName>
        <styles.ProductMeta>
          <styles.ProductMetaLabel>Price</styles.ProductMetaLabel>
          <styles.ProductMetaValue>{formatPrice(item)}</styles.ProductMetaValue>
        </styles.ProductMeta>
        <styles.ProductMeta>
          <styles.ProductMetaLabel>Variants</styles.ProductMetaLabel>
          <styles.ProductMetaValue>{item.variants}</styles.ProductMetaValue>
        </styles.ProductMeta>
      </styles.VerticalContainer>
    </styles.HorizontalContainer>

    <styles.HorizontalContainer>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <styles.VerticalContainer height="178px">
        <Metrics metrics={item.metrics} />
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <ProductReferrals item={item} />
      </styles.VerticalContainer>
    </styles.HorizontalContainer>
  </styles.ItemContainer>
)

Product.defaultProps = {
  item: {},
  number: null,
}

Product.propTypes = {
  item: PropTypes.shape({}),
  number: PropTypes.number,
}

export default Product
