import { env } from '../env'

function environmentPath(): string {
  return env.VITE_NODE_ENV === 'development' ? 'local.' : ''
}

function accountPort(): string {
  return env.VITE_NODE_ENV === 'development' ? ':8081' : ''
}

export default {
  toAccount(): void {
    window.location.href = `https://account.${environmentPath()}buffer.com${accountPort()}/analyze`
  },
  toOnboarding(): void {
    window.location.href = `https://account.${environmentPath()}buffer.com${accountPort()}/onboarding/analyze`
  },
  toLanding(): void {
    window.location.href = `https://${environmentPath()}buffer.com/analyze`
  },
  toMigrationHub(): void {
    window.location.href = `https://account.${environmentPath()}buffer.com${accountPort()}/new-plans?cta=analyze-migration-cta`
  },
}

export const channelsRedirectUrl = (): string =>
  `https://account.${environmentPath()}buffer.com${accountPort()}/channels`
