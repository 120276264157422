import React from 'react'
import styled from 'styled-components'
import { Text, Link } from '@buffer-mono/legacy-bufferapp-components'
import { blue } from '@bufferapp/ui/style/colors'

const BLOG_POST_TITLE =
  'How to Use Hashtags: How Many, Best Ones, and Where to Use Them'
const BLOG_POST_URL =
  'https://buffer.com/library/a-scientific-guide-to-hashtags-which-ones-work-when-and-how-many/?utm_source=buffer&utm_medium=analyze&utm_campaign=hashtags'
const BLOG_POST_LINK = (
  <Link
    // @ts-expect-error
    href={BLOG_POST_URL}
    // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    onClick={(e) => {
      e.preventDefault()
      window.open(BLOG_POST_URL)
    }}
    unstyled
  >
    {BLOG_POST_TITLE}
  </Link>
)

const Container = styled.div`
  padding: 1.5rem 6rem;
  background: #f8f8f8;
  text-align: center;
  line-height: 1.4em;

  & a {
    color: ${blue} !important;
  }
`

const NoData = () => (
  <Container>
    <Text size="mini">
      We didn’t find any hashtags in the posts sent during the chosen date
      period. If you’d like to learn more about hashtags, see this post on the
      Buffer Blog: {BLOG_POST_LINK}.
    </Text>
  </Container>
)

export default NoData
