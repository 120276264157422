import React from 'react'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'
import { Helper } from '~/shared-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 4px;
`

const Bar = styled.span`
  display: inline-block;
  width: 2px;
  height: 50px;
  margin-right: 0.625rem;
`

const Metric = styled.div`
  display: flex;
  flex: 1;
`

const MetricLabel = styled.div`
  margin: 0 0 4px;
`

const MetricValue = styled.div`
  span {
    font-weight: 600;
    color: ${grayDarker};
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'small' implicitly has an 'any' ty... Remove this comment to see the full error message
export default ({ small, label, name, value }) => (
  <Metric>
    {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
    {!small && <Bar metric={name} />}
    <MetricContainer>
      <MetricLabel>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: any; }' is not a... Remove this comment to see the full error message */}
        <Helper label={label}>
          <Text type="label" color="grayDark">
            {label}
          </Text>
        </Helper>
      </MetricLabel>
      <MetricValue>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: ... Remove this comment to see the full error message */}
        <Text>{value}</Text>
      </MetricValue>
    </MetricContainer>
  </Metric>
)
