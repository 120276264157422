import React from 'react'
import PropTypes from 'prop-types'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import TagsInput from 'react-tagsinput'
import styled from 'styled-components'
import {
  CloseIcon,
  Text,
  Button,
} from '@buffer-mono/legacy-bufferapp-components'
import {
  gray,
  grayDark,
  grayDarker,
  blue,
  blueLighter,
} from '@bufferapp/ui/style/colors'
import {
  fontFamily,
  lineHeight,
  fontSize,
  fontWeight,
} from '@bufferapp/ui/style/fonts'

const Tag = styled.span`
  border-radius: 4px;
  padding: 4px;
  margin: 4px;
  color: white;
  font-weight: bold;
  background-color: ${blue};
`

const Input = styled.input`
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  color: ${grayDarker};
  max-width: 100%;
  width: 100%;
  padding: 9px 8px 8px 8px;
  border: none;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${gray};
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
  }
`

const TagContent = styled.span`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  & > *:first-child {
    margin-right: 0.5rem;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'key' implicitly has an 'any' type... Remove this comment to see the full error message
const searchTag = ({ key, onRemove, getTagDisplayValue, tag, ...other }) => (
  <Tag key={key} {...other}>
    {/* @ts-expect-error */}
    <Button noStyle onClick={() => onRemove(key)}>
      <TagContent>
        <Text color="white" size="mini">
          {getTagDisplayValue(tag)}
        </Text>
        <CloseIcon size="small" color="white" />
      </TagContent>
    </Button>
  </Tag>
)

searchTag.propTypes = {
  key: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  getTagDisplayValue: PropTypes.func.isRequired,
  tag: PropTypes.string.isRequired,
}

const SearchWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${gray};
  width: 100%;
  display: inline-flex;
  height: 38px;

  &:focus-within {
    border: 1px solid ${blue};
    box-shadow: 0px 0px 0px 3px ${blueLighter};
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  &:hover {
    border-color: ${grayDark};
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'tags' implicitly has an 'any' type.
const layout = (tags, input) => (
  <SearchWrapper>
    {tags} {input}
  </SearchWrapper>
)

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const searchInput = (props) => (
  <Input
    {...props}
    value={props.value}
    type="text"
    placeholder={props.hasTags ? null : 'Filter posts by keywords or #hashtags'}
  />
)

searchInput.propTypes = {
  value: PropTypes.string.isRequired,
  hasTags: PropTypes.bool.isRequired,
}

class Searchbox extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = {
      tags: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeInput = this.handleChangeInput.bind(this)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'tags' implicitly has an 'any' type.
  handleChange(tags) {
    // @ts-expect-error TS(2339) FIXME: Property 'search' does not exist on type 'Readonly... Remove this comment to see the full error message
    this.props.search(tags)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'tags' implicitly has an 'any' type.
  handleChangeInput(tags) {
    this.setState({ tags })
  }

  render() {
    return (
      <TagsInput
        // @ts-expect-error TS(2339) FIXME: Property 'searchTerms' does not exist on type 'Rea... Remove this comment to see the full error message
        value={this.props.searchTerms}
        // @ts-expect-error TS(2339) FIXME: Property 'tags' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        inputValue={this.state.tags}
        onChange={this.handleChange}
        onChangeInput={this.handleChangeInput}
        inputProps={{
          // @ts-expect-error TS(2339) FIXME: Property 'searchTerms' does not exist on type 'Rea... Remove this comment to see the full error message
          hasTags: this.props.searchTerms.length > 0,
        }}
        renderInput={searchInput}
        renderTag={searchTag}
        renderLayout={layout}
      />
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Searchbox.defaultProps = {
  searchTerms: [],
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Searchbox.propTypes = {
  search: PropTypes.func.isRequired,
  searchTerms: PropTypes.arrayOf(PropTypes.string),
}

export default Searchbox
