import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Text,
  geyser,
  outerSpace,
} from '@buffer-mono/legacy-bufferapp-components'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'nume... Remove this comment to see the full error message
import numeral from 'numeral'

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function expandGenderAgeLabel(label) {
  return `${label
    .replace(/^M\./, 'Male, ')
    .replace(/^F\./, 'Female, ')
    .replace(/^U./, 'Undisclosed, ')
    .replace(/-/, ' to ')} years old`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
function expandMetricLabel(label) {
  // Gender and Age
  if (label.match(/^(F|M|U)\./)) {
    return expandGenderAgeLabel(label)
  }

  return label
}

const Row = styled.li`
  display: flex;
  align-content: stretch;
  margin: 0;
  padding: 0;
  border-color: ${geyser};
  border-bottom: 1px dotted ${geyser};
  vertical-align: center;

  &:first-child {
    border-bottom: 1px solid rgb(206, 215, 223);
    box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 2px;
  }

  &:last-child {
    border-bottom: none;
  }
`

const Cell = styled.div`
  position: relative;
  color: ${outerSpace};
  padding: 1rem;
  text-align: left;
  align-items: center;
  box-sizing: border-box;
  display: flex;
`

const LabelCell = styled(Cell)`
  width: 600px;
`

const NumberCell = styled(Cell)`
  width: 46px;
  padding-left: 0;
  text-align: right;
  border-right: 0px;
  box-sizing: content-box;
  flex-shrink: 0;
  & > span {
    margin-left: auto;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'metric' implicitly has an 'any' t... Remove this comment to see the full error message
export const TableRow = ({ metric, index }) => (
  <Row>
    <NumberCell>
      <Text size="mini" color="shuttleGray" weight="bold">
        {index + 1}
      </Text>
    </NumberCell>
    <LabelCell>
      <Text size="mini" color="shuttleGray" weight="medium">
        {expandMetricLabel(metric.label)}
      </Text>
    </LabelCell>
    <Cell>
      <Text size="mini" color="shuttleGray" weight="medium">
        {numeral(metric.value).format('0,0')}
      </Text>
    </Cell>
  </Row>
)

TableRow.propTypes = {
  metric: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  index: PropTypes.number,
}

TableRow.defaultProps = {
  metric: { label: '', value: 0 },
  index: 0,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'labels' implicitly has an 'any' t... Remove this comment to see the full error message
export const TableHeader = ({ labels }) => (
  <Row>
    <NumberCell>
      <Text size="mini" color="outerSpace" weight="medium">
        Rank
      </Text>
    </NumberCell>
    <LabelCell>
      <Text size="mini" color="outerSpace" weight="medium">
        {labels[0]}
      </Text>
    </LabelCell>
    <Cell>
      <Text size="mini" color="outerSpace" weight="medium">
        {labels[1]}
      </Text>
    </Cell>
  </Row>
)

TableHeader.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
}

TableHeader.defaultProps = {
  labels: [],
}

const HashtagsTableWrapper = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
`

const ChartContainer = styled.div`
  position: relative;
  padding: 0 0 4px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'labels' implicitly has an 'any' t... Remove this comment to see the full error message
const Table = ({ labels, metrics }) => (
  <ChartContainer>
    <HashtagsTableWrapper>
      <TableHeader labels={labels} />
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type. */}
      {metrics.map((metric, index) => (
        <TableRow
          index={index}
          metric={metric}
          key={`${index}${metric.label}_demographic`}
        />
      ))}
    </HashtagsTableWrapper>
  </ChartContainer>
)

Table.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
}

Table.defaultProps = {
  labels: [],
  metrics: [],
}

export default Table
