import React, { Fragment } from 'react'
import { Select, Text } from '@bufferapp/ui'
import StatefulDropdown from './Dropdown'

export default class MetricDropdown extends StatefulDropdown {
  render() {
    const metrics = [
      {
        id: 0,
        title: 'Date',
        key: 'date',
        // @ts-expect-error TS(2339) FIXME: Property 'attributeToSortBy' does not exist on typ... Remove this comment to see the full error message
        selected: this.props.attributeToSortBy === 'date',
      },
      {
        title: 'Completion Rate',
        key: 'completionRate',
        // @ts-expect-error TS(2339) FIXME: Property 'attributeToSortBy' does not exist on typ... Remove this comment to see the full error message
        selected: this.props.attributeToSortBy === 'completionRate',
      },
      {
        title: 'Reach',
        key: 'reach',
        // @ts-expect-error TS(2339) FIXME: Property 'attributeToSortBy' does not exist on typ... Remove this comment to see the full error message
        selected: this.props.attributeToSortBy === 'reach',
      },
    ]

    const selectedMetric = metrics.find(
      // @ts-expect-error TS(2339) FIXME: Property 'attributeToSortBy' does not exist on typ... Remove this comment to see the full error message
      (metric) => metric.key === this.props.attributeToSortBy,
    )
    return (
      <Fragment>
        <Text htmlFor="days_select" type="label">
          Sort by
        </Text>
        <Select
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          label={selectedMetric.title}
          items={metrics}
          xPosition="right"
          multiSelect={false}
          // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
          onSelectClick={(item) => this.props.sortBy(item.key)}
        />
      </Fragment>
    )
  }
}
