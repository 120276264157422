import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  ChartStateNoData as NoData,
  ChartStateLoading as Loading,
  ChartCard,
  ChartHeader,
} from '~/shared-components'
import { twitter as twitterBlue } from '@bufferapp/ui/style/colors'
import InfoLabelSelect from '../InfoLabelSelect'
import AddReport from '~/add-report'
import Title from '../Title'
import Chart from '../Chart'
import { LockIfNotAllowed, STORIES } from '~/account'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
`

const ChartContainer = styled.div`
  padding: 0 0 24px;
  margin: 0 auto;
  position: relative;
`

const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'forReport' does not exist on type 'Theme... Remove this comment to see the full error message
    props.forReport ? '0' : '0 16px'};
`

export class ChartWithDropdowns extends React.Component {
  mapSelectedMetricsToTotals() {
    // @ts-expect-error TS(2339) FIXME: Property 'selectedMetrics' does not exist on type ... Remove this comment to see the full error message
    const { selectedMetrics, totals } = this.props
    const primaryIndex = totals.findIndex(
      // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
      (x) => x.label === selectedMetrics.primary.label,
    )
    const secondaryIndex = totals.findIndex(
      // @ts-expect-error TS(7006) FIXME: Parameter 'x' implicitly has an 'any' type.
      (x) => x.label === selectedMetrics.secondary.label,
    )
    let otherOption
    if (selectedMetrics.secondary.label === 'Previous Period') {
      otherOption = {
        label: 'Previous Period',
        value: totals[primaryIndex].previousValue,
        color: '#9B51E0',
      }
    }
    if (selectedMetrics.secondary.label === 'None') {
      otherOption = {
        label: 'None',
        value: null,
        color: 'transparent',
      }
    }
    return {
      primary: totals[primaryIndex],
      secondary: totals[secondaryIndex] ? totals[secondaryIndex] : otherOption,
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'isPrimary' implicitly has an 'any' type... Remove this comment to see the full error message
  getMetricsWithClickHandlers(isPrimary) {
    // @ts-expect-error TS(2339) FIXME: Property 'totals' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { totals, onSelectMetric, selectedMetrics } = this.props

    const previousPeriodOption = {
      label: 'Previous Period',
    }

    const noneOption = {
      label: 'None',
    }

    const copyOfTotals = [...totals]

    if (
      !isPrimary &&
      !totals.includes(previousPeriodOption) &&
      !totals.includes(noneOption)
    ) {
      copyOfTotals.unshift(noneOption, previousPeriodOption)
    }
    return copyOfTotals.map((metric, index) => {
      return {
        id: index.toString(),
        title: metric.label,
        onItemClick: () => onSelectMetric(metric, isPrimary),
        disabled: isPrimary
          ? metric.label === selectedMetrics.secondary.label
          : metric.label === selectedMetrics.primary.label,
        selected: isPrimary
          ? metric.label === selectedMetrics.primary.label
          : metric.label === selectedMetrics.secondary.label,
      }
    })
  }

  dailyWithColor() {
    // @ts-expect-error TS(2339) FIXME: Property 'daily' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { daily, selectedMetrics } = this.props
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    return daily.map((item) => ({
      day: item.day.toString(),
      // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
      metrics: item.metrics.map((metric) => ({
        ...metric,
        color:
          metric.label === selectedMetrics.primary.label
            ? twitterBlue
            : metric.label === selectedMetrics.secondary.label
            ? '#9B51E0'
            : 'black',
        postWording: 'story',
      })),
      previousPeriodDay: item.previousPeriodDay.toString(),
    }))
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'totals' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { totals, forReport } = this.props

    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    const emptyTotals = totals.filter((metric) => metric.value !== 0)

    if (emptyTotals.length === 0) {
      return (
        <Container>
          <NoData chartName="stories-daily" />
        </Container>
      )
    } else {
      const actualizedSelectedMetrics = this.mapSelectedMetricsToTotals()
      return (
        <React.Fragment>
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <DropdownContainer forReport={forReport}>
            <InfoLabelSelect
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              metrics={this.getMetricsWithClickHandlers(true)}
              displayedMetric={actualizedSelectedMetrics.primary}
              primary
              forReport={forReport}
            />
            <InfoLabelSelect
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              metrics={this.getMetricsWithClickHandlers()}
              displayedMetric={actualizedSelectedMetrics.secondary}
              forReport={forReport}
            />
          </DropdownContainer>
          <Chart
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            selectedMetrics={[
              actualizedSelectedMetrics.primary,
              actualizedSelectedMetrics.secondary,
            ]}
            data={this.dailyWithColor()}
            profileService="instagram"
          />
        </React.Fragment>
      )
    }
  }
}

class StoriesDaily extends React.Component {
  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'fetch' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.fetch()
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'totals' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { totals, daily, loading, onSelectMetric, selectedMetrics } =
      this.props
    let content = null
    if (loading) {
      // @ts-expect-error TS(2322) FIXME: Type '{ active: true; noBorder: true; large: true;... Remove this comment to see the full error message
      content = <Loading active noBorder large />
    } else {
      content = (
        <ChartWithDropdowns
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          totals={totals}
          daily={daily}
          onSelectMetric={onSelectMetric}
          selectedMetrics={selectedMetrics}
        />
      )
    }
    return (
      <ChartCard>
        {/* @ts-expect-error TS(2741) FIXME: Property 'exporting' is missing in type '{ childre... Remove this comment to see the full error message */}
        <LockIfNotAllowed {...{ STORIES }}>
          <ChartHeader>
            <Title />
            <AddReport
              chart="stories-daily"
              state={{
                // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
                selectedMetrics: this.props.selectedMetrics,
              }}
            />
          </ChartHeader>
          <ChartContainer id="js-dom-to-png-stories-daily">
            {content}
          </ChartContainer>
        </LockIfNotAllowed>
      </ChartCard>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
StoriesDaily.defaultProps = {
  loading: false,
  totals: [],
  daily: [],
  selectedMetrics: PropTypes.shape({
    primary: {
      // @ts-expect-error TS(2322) FIXME: Type '{ label: null; color: null; id: null; }' is ... Remove this comment to see the full error message
      label: null,
      color: null,
      id: null,
    },
    secondary: {
      // @ts-expect-error TS(2322) FIXME: Type '{ label: null; color: null; id: null; }' is ... Remove this comment to see the full error message
      label: null,
      color: null,
      id: null,
    },
  }),
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
StoriesDaily.propTypes = {
  loading: PropTypes.bool,
  totals: PropTypes.array,
  daily: PropTypes.array,
  selectedMetrics: PropTypes.shape({}),
  forReport: PropTypes.bool,
}

export default StoriesDaily
