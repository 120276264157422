import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes as dateActionTypes } from '~/date-picker'
import {
  actionTypes as exportActionTypes,
  actions as exportActions,
} from '~/png-export'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'

// @ts-expect-error TS(7031) FIXME: Binding element 'metrics' implicitly has an 'any' ... Remove this comment to see the full error message
const formatDataForCSVExport = ({ metrics }) => {
  const data = {}
  // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
  metrics.totals.forEach((metric) => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    data[metric.label] = metric.value
    if ('paid' in metric && metric.paid > 0) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[`${metric.label} paid`] = metric.paid
    }
  })
  return data
}

const EXPORT_FILENAME = 'average-performance-statistics'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  switch (action.type) {
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      dispatch(
        exportCSVActions.exportChart(
          EXPORT_FILENAME,
          formatDataForCSVExport(getState().average),
        ),
      )
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart('js-dom-to-png-average', EXPORT_FILENAME),
      )
      break
    case profileActionTypes.REFRESH_PROFILES:
      if (getState().profiles.selectedProfile) {
        if (getState().profiles.selectedProfile.service !== 'linkedin') {
          dispatch(
            actions.fetch({
              name: 'average',
              args: {
                profileId: getState().profiles.selectedProfile.id,
                profileService: getState().profiles.selectedProfile.service,
                startDate: getState().date.startDate,
                endDate: getState().date.endDate,
              },
            }),
          )
        }
      }
      break
    case profileActionTypes.SELECT_PROFILE:
      if (action.profile.service !== 'linkedin') {
        dispatch(
          actions.fetch({
            name: 'average',
            args: {
              profileId: action.profile.id,
              profileService: action.profile.service,
              startDate: getState().date.startDate,
              endDate: getState().date.endDate,
            },
          }),
        )
      }
      break
    case dateActionTypes.SET_DATE_RANGE:
      if (getState().profiles.selectedProfileId) {
        if (getState().profiles.selectedProfile.service !== 'linkedin') {
          dispatch(
            actions.fetch({
              name: 'average',
              args: {
                profileId: getState().profiles.selectedProfileId,
                profileService: getState().profiles.selectedProfileService,
                startDate: action.startDate,
                endDate: action.endDate,
              },
            }),
          )
        }
      }
      break
    default:
      break
  }
  return next(action)
}
