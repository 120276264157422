import styled from 'styled-components'
import EmptyState from '~/shared-components/EmptyState'
import { Button, Text } from '@bufferapp/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'

const Stack = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > * + * {
    margin-top: 1.5rem;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'selectedCampaign' implicitly has ... Remove this comment to see the full error message
export const EmptyCampaign = ({ selectedCampaign }) => {
  const { isEnabled: isCampaignsToTagsEnabled } =
    useSplitEnabled('campaigns-to-tags')

  return (
    <EmptyState header="">
      <Stack>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; icon... Remove this comment to see the full error message */}
        <Text fontWeight={'medium'}>
          There are currently no live posts{' '}
          {isCampaignsToTagsEnabled ? 'with this tag' : 'in your campaign'}
        </Text>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ size: string; type... Remove this comment to see the full error message */}
        <Button
          size="large"
          type="primary"
          label={`See your scheduled ${
            isCampaignsToTagsEnabled ? 'tagged' : 'campaign'
          } posts`}
          onClick={() => {
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            window.location = `https://publish.buffer.com/campaigns/${selectedCampaign.id}/scheduled`
          }}
        />
      </Stack>
    </EmptyState>
  )
}

EmptyCampaign.propTypes = {
  selectedCampaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}
